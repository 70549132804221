import {
  ERROR,
  DISMISS_ERROR,
  AUTH_ERROR
} from '../constants';
import { combineReducers } from 'redux';

export function getErrorMessage(data, authData) {
  let str = null;
  if (data) {
    if (typeof data === 'string') str = data;
    else if (data.response && data.response.data) 
      str = data.response.data;
    else if (data.message) str = data.message;
  } else if (authData) str = authData;
  return str;
}

const error = (state = '', {type, data, authData}) => {
  switch(type){
    case ERROR:
    case AUTH_ERROR:
      return getErrorMessage(data, authData);
    case DISMISS_ERROR:
      return '';
    default:
      return state;
  }
}

export default combineReducers({error});
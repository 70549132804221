import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import t from '../../../i18';
import Amplify from '@aws-amplify/core';
import { connect } from 'react-redux';
import { List } from 'antd';
import PropTypes from 'prop-types';
import { setCurrentChat } from '../../../actions/liveAgent';
import { connectUserMeta } from '../UserMeta';
import { connectReqDescription } from '../ReqDescription';
import connectUserAvatar from '../Avatar';
import { findDOMNode } from 'react-dom';

const log = new Amplify.Logger(':::RequestItem:::');

function RequestItem({id, setCurrentChat, current, agent}) {
    log.debug('render memo');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const openChat = useCallback(() => setCurrentChat(id),[id]);
    const ref = useRef(null);
    const AssignedStatus = useMemo(() => {
        return !agent
            ? <div className="status">{t('statuses.notAssigned')}</div>
            : null
    }, [agent]);

    const UserMeta = useMemo(() => connectUserMeta(id), [id]);
    const Description = useMemo(() => connectReqDescription(id), [id]);
    const Avatar = useMemo(() => connectUserAvatar(id), [id]);

    useEffect(() => {
        const el = findDOMNode(ref.current);
        if (current) el.classList.add('active');
        else el.classList.remove('active');
    }, [current]);

    return useMemo(() => {
        log.debug('render');
        return (
            <List.Item ref={ref} onClick={openChat}>
                <UserMeta 
                    description={<Description/>} 
                    avatar={<Avatar/>}
                />
                { AssignedStatus }
            </List.Item>
        )
    }, [AssignedStatus, openChat]);
}

RequestItem.propTypes = {
    id: PropTypes.string.isRequired,
    setCurrentChat: PropTypes.func.isRequired
}

export default connect(
    null,
    { setCurrentChat }
)(RequestItem);
import { 
    LA_requestStatus,
    LA_requestTimeout,
    LA_cancelRequest,
    LA_closeRequest,
    LA_requestAssigned,
    userConnected,
    userDisconnected,
} from '../../constants';
import moment from 'moment';

const stKey = 'reqStatus';
export const waitMinutes = 5;
const _waitTime = waitMinutes*60*1000;
const def = {
    timer: null,
    channel: null,
    status: null,
    blockInput: false,
    description: '',
    agent: {}
};

let init = localStorage.getItem(stKey);
init = init ? JSON.parse(init) : def;
if (!isValid(init)) {
    init = def;
    save(init);
} 

function isValid(data) {
    if (data.status === 'pending' && data.timer && moment().diff(moment(data.timer)) > _waitTime) {
        return false;
    }
    return true;
}

function charAt(str) {
    return str.charAt(0).toUpperCase();
}

function save(d) {
    localStorage.setItem(stKey, JSON.stringify(d));
}

function cancelRequest() {
    save(def);
    return def;
}

function requestStatus(payload) {
    if (payload.agent) {
        payload.agent.logo = `${charAt(payload.agent.firstName)}${charAt(payload.agent.lastName)}`;
    }
    let d = {
        timer: payload.createdAt,
        channel: payload.id,
        status: payload.status,
        blockInput: payload.status === 'pending',
        description: payload.description || '',
        agent: payload.agent || {}
    };
    if (!isValid(d)) {
        d = def;
    }
    save(d);
    return d;
}

function updateOnline(state, payload) {
    if (state.agent.id) {
        const d = {
            ...state,
            agent: {
                ...state.agent,
                ...payload
            }
        };
        save(d);
        return d;
    }
    return state;
}

export default (state = init, { type, payload }) => {
    switch(type) {
        case userConnected:
        case userDisconnected:
            return updateOnline(state, payload);
        case LA_requestAssigned:
        case LA_requestStatus:
            return requestStatus(payload);
        case LA_closeRequest: {
            const agent = state.agent;
            let d = {
                ...cancelRequest(),
                __agent: agent
            };
            return d;
        }
        case LA_cancelRequest:
        case LA_requestTimeout:
            return cancelRequest();
        default:
            return state;
    }
};
import React, { memo, useCallback, useMemo } from 'react';
import Amplify from '@aws-amplify/core';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { savePurchase } from '../../../actions/purchaseRequisition';
import { Button } from 'antd';
import moment from 'moment';
import t from '../../../i18';
import SubmitButton from '../../../components/SubmitButton';
import './index.sass';

const log = new Amplify.Logger(':::PurchaseTotal:::');

export default memo(() => {
    log.debug('render');

    const mapState = useCallback(
        state => ({
            count: state.cartInfo.count,
            total: state.cartInfo.total,
            isSended: state.cartInfo.status === 'sended',
            submitDate: state.cartInfo.submitDate,
            currency: state.cart.items.length ? state.cart.items[0].currency : 'EUR',
            loading: state.cartInfo.loading,
            reqId: state.cartInfo.reqId,
            userData: state.cartInfo.userData
        }),
        []
    );
    const dispatch = useDispatch();
    const { 
        count, total, isSended, 
        submitDate, currency, loading, reqId, userData 
    } = useMappedState(mapState);
    const onSavePurchase = useCallback(
        isSend => () => dispatch(savePurchase(isSend)), 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    const _total = total.toString().replace('.',',');

    const content = useMemo(() => {
        if (isSended) {
            return (
                <div className="submit-date">
                    {t('cart.submittedOn')} <br/>
                    {moment(submitDate).format('MMMM Do YYYY, H:mm:ss')} <br/>
                    {t('cart.requesterName')}: <strong>{
                        userData
                        ? `${userData.firstName} ${userData.lastName}`
                        : null
                    }</strong><br/>
                    {t('cart.requisitionId')}: <strong>{ reqId || '-'}</strong>
                </div>
            )
        }
        return (
            <React.Fragment>
                <SubmitButton
                    value={t('cart.sendPr')}
                    loader={loading}
                    onClick={loading?null:onSavePurchase(true)}
                />
                <span>{t('cart.or')}</span>
                <Button className="btn" onClick={onSavePurchase()}>{t('cart.saveDraft')}</Button>
            </React.Fragment>
        )
    },[isSended, onSavePurchase, loading, submitDate, reqId, userData]);

    return (
        <div className="purchase-total">
            <div className="total-amount">{t('cart.totalAmount')} ({count} {count === 1 ? t('cart.item') : t('cart.items')})</div>
            <div className="total-price">{_total} {currency}</div>
            { content }
        </div>
    )
});
import React, { memo, useCallback, useState } from 'react';
import Amplify from '@aws-amplify/core';
import { useMappedState } from 'redux-react-hook';
import { Row, Col, Select, Button } from 'antd';
import { useSearchOrders, useCreateOrder } from '../../../actions/purchaseRequisition';
import CartMenu from '../CartMenu';
import t from '../../../i18';
import './index.sass';

const Option = Select.Option;
const log = new Amplify.Logger(':::OrdersTopBar:::');

export default memo(() => {
    log.debug('render');
    const [value, setValue] = useState('');

    const mapState = useCallback(state => ({
        search: state.ordersFilter.search,
        isLiveAgent: state.cartInfo.isLiveAgent
    }), []);
    const { search, isLiveAgent } = useMappedState(mapState);
    const onChange = useSearchOrders();
    const addNewOrder = useCreateOrder();
    const searchVal = search.split(',').filter(el => el ? true : false);

    return (
        <Row className="top-bar" type="flex">
            <Col className="title" span={10}>
                <CartMenu current="orders"/>
                <h3>{t('cart.ordersTitle')}</h3>
            </Col>
            <Col span={10}>
                <Select
                    mode="tags"
                    placeholder={t('searchPlaceholder')}
                    onChange={val => {
                        onChange(val)
                        setValue('');
                    }}
                    defaultValue={searchVal}
                    onSearch={setValue}
                >
                    {
                        isLiveAgent && value 
                        ? [
                            <Option key={`@user:${value}`}>
                                <b>{t('cart.filterUserTag')} </b>{value}
                            </Option>
                        ] : null
                    }
                    {[
                        <Option key={`@status:sended`}>
                            <b>{t('cart.filterSendedTag')} </b>{t('cart.filterSended')}
                        </Option>,
                        <Option key={`@status:draft`}>
                            <b>{t('cart.filterSendedTag')} </b>{t('cart.filterDraft')}
                        </Option>
                    ]}
                </Select>
            </Col>
            <Col className="bar-actions" span={4} >
                <Button className="btn btn-blue" onClick={addNewOrder} type="primary" icon="plus">{t('createNew')}</Button>
            </Col>
        </Row>
    )
});
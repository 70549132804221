import dotProp from 'dot-prop-immutable';
import { 
    LA_setCurrentChat,
    LA_requestUpdated,
    DISCONNECTED,
    SIGNED_OUT,
    LA_closeRequest
} from '../constants';

export default (state, { type }) => {
    switch(type) {
        case LA_setCurrentChat:
        case LA_requestUpdated:
        case SIGNED_OUT:
        case DISCONNECTED: {
            let currentMsgs = state.chat.messages[state.chat.current];
            if (state.chat.current === 'hala' && !currentMsgs) {
                return state;
            }
            if (!currentMsgs) currentMsgs = [];
            if (state.chat.prev) state = dotProp.set(
                state,
                `chat.messages.${state.chat.prev}`,
                state.messages
            );
            state = dotProp.set(
                state,
                `messages`,
                currentMsgs
            );
            return state; 
        }
        case LA_closeRequest: {
            const agent = state.liveAgent.request.__agent;
            state = dotProp.set(
                state,
                `liveAgent.request.__agent`,
                null
            );
            state.messages = state.messages.map(el => {
                if (el.user === agent.id) {
                    return {
                        ...el,
                        agent: agent
                    };
                }
                return el;
            });
            return state; 
        } 
        default:
            return state;
    }
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Amplify from '@aws-amplify/core';

const log = new Amplify.Logger(':::ContactUs Container:::');

const html = {
  __html:`<div class="ph2">
  <div class="lh-copy">
    <h2 class="mt0 mb2">Technical support</h2>
    <p class="mt2">Technical support is provided for the Service during the Agreements term and is not available as a separate offering. There are two channels to contact Customer Support:</p>
  </div>
</div>
<div class="flex lh-copy">
  <div class="h-100 w-50 ph2">
    <div class="mt2 pa3 bg-navy-near-white br1">
      <h4 class="flex mt0 mb2"><img class="v-btm mr2" src="/assets/img/16icn-email.svg" alt="">Email access</h4>
      <p class="mt0 mb2"><a class="link" href="mailto:support@hala.ai">support@hala.ai</a></p>
      <p class="mt0 mb2"><a class="link" href="mailto:rudchuk@hala.ai">rudchuk@hala.ai</a></p>
      <p class="mt0 mb2"><a class="link" href="mailto:mykyta.bazhenov@hala.ai">mykyta.bazhenov@hala.ai</a></p>
    </div>
  </div>
  <div class="h-100 w-50 mh2">
    <div class="mt2 pa3 bg-navy-near-white br1">
      <h4 class="flex mt0 mb2"><img class="v-btm mr2" src="/assets/img/16icn-phone.svg" alt="">Voice access</h4>
      <p class="mt0 mb2"><a class="link" href="tel:+37259823235">+372 59823235</a></p>
      <p class="mt0 mb2"><a class="link" href="tel:+37258529042">+372 58529042</a></p>
    </div>
  </div>
</div>
<div class="ph2">
  <div class="lh-copy">
    <p>Please ensure the service representative understands the business impact (Severity) of your issue.</p>
    <p>* We provide only English language Support.</p>
    <hr class="ba b--black-05 mv4">
    <h3 class="mb2">Hours</h3>
    <p class="mt2"><strong>Support Hours:</strong> Hala Support Center hours cover the prevailing business hours in the country where your product is licensed and/or your contract is registered.</p>
    <p><strong>Off shift support:</strong> off shift hours are defined as all other hours outside of normal country business hours. During Off shift hours, we will use commercially reasonable efforts to respond by telephone within two hours to customer critical problems (severity 1). Off shift support will be provided in English.</p>
    <hr class="ba b--black-05 mv4">
    <h3 class="mb2">Language</h3>
    <p class="mt2">Support language is English.</p>
    <hr class="ba b--black-05 mv4">
    <h3 class="mb2">Severity</h3>
    <p class="mt2">The following table defines severity levels and provides some examples:</p>
  </div>
  <table class="table w-100 tl">
    <tr class="bg-navy-near-white">
      <th class="w-5">Severity</th>
      <th class="w-55">Severity Definition</th>
      <th class="w-20">Response Time Objectives</th>
      <th class="w-20">Response Time Coverage</th>
    </tr>
    <tr>
      <td class="v-top"><p class="mv0">1</p></td>
      <td class="v-top"><p class="mv0"><strong>Critical business impact/service down:</strong> Business critical functionality is inoperable or critical interface has failed. This usually applies to a production environment and indicates an inability to access services resulting in a critical impact on operations. This condition requires an immediate solution.</p></td>
      <td class="v-top"><p class="mv0">Within 1 hour</p></td>
      <td class="v-top"><p class="mv0">24/7</p></td>
    </tr>
    <tr>
      <td class="v-top"><p class="mv0">2</p></td>
      <td class="v-top"><p class="mv0"><strong>Significant business impact:</strong> A service business feature or function of the service is severely restricted in its use or you are in jeopardy of missing business deadlines.</p></td>
      <td class="v-top"><p class="mv0">Within 2 business hours</p></td>
      <td class="v-top"><p class="mv0">M-F business hours</p></td>
    </tr>
    <tr>
      <td class="v-top"><p class="mv0">3</p></td>
      <td class="v-top"><p class="mv0"><strong>Minor business impact::</strong> A service business feature or function of the service is severely restricted in its use or you are in jeopardy of missing business deadlines.</p></td>
      <td class="v-top"><p class="mv0">Within 4 business hours</p></td>
      <td class="v-top"><p class="mv0">M-F business hours</p></td>
    </tr>
    <tr>
      <td class="v-top"><p class="mv0">4</p></td>
      <td class="v-top"><p class="mv0"><strong>Minimal business impact:</strong> An inquiry or non-technical request</p></td>
      <td class="v-top"><p class="mv0">Within 1 business day</p></td>
      <td class="v-top"><p class="mv0">M-F business hours</p></td>
    </tr>
  </table>
</div>`
}

class ContactUs extends Component {
  render() {
    log.debug('render');
    return (
      <div className="main-content">
        <div className="pa4 scroll-y">
          <div className="ph2">
            <div className="lh-copy">
              <p className="f6">
                <Link to="/" className="link f6">← Back to Chat</Link>
              </p>
            </div>
          </div>
          <div dangerouslySetInnerHTML={html}>
          </div>
        </div>
        <div className="pa2 bt black-10 bg-white">
          <div className="mh4 mv2">
            <Link to="/" className="dib btn btn-ghost pv1 ph2 f5">Ok, back to Chat</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({}),
  {}
)(ContactUs);

import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Message from './Message/Message';
import { connect } from 'react-redux';
import Amplify from '@aws-amplify/core';

const log = new Amplify.Logger(':::MessagesList:::');

function MessagesList({messages = []}) {
    log.debug('render');
    const ref = useRef(null);
    const scrollToBottom = useCallback(() => {
        if (ref.current) {
            const tId = setTimeout(() => {
                ref.current.scrollIntoView({
                    behavior: 'smooth' 
                });
            }, 100);
            return () => clearTimeout(tId);
        }
    }, [ref]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => scrollToBottom(),[messages]);
    
    if (!messages.length) return null;
    const n = messages.length - 1;

    return (
        <React.Fragment>
            {
                messages.map((message, i) => {
                    return (
                        <Message 
                            key={ message.id }
                            msgRef={ n===i ? ref : null }
                            message={ message } 
                        />
                    )
                })
            }
        </React.Fragment>
    )
}

MessagesList.propTypes = {
    messages: PropTypes.array.isRequired
}

export default connect(
    state => ({
        messages: state.messages
    }), 
    {}
)(MessagesList);
import React, { useMemo } from 'react';
import Amplify from '@aws-amplify/core';
import { connect } from 'react-redux';
import RequestItem from '../RequestItem';
import PropTypes from 'prop-types';
import { List, Empty } from 'antd';
import t from '../../../i18';
import { emptyImg } from '../../../constants';
import { getRequestsByFilter } from '../../../reducers/liveAgent/selectors';

const log = new Amplify.Logger(':::RequestsList:::');

function RequestsList({items, filter, current}) {
    log.debug('render');

    const dataSource = useMemo(() => {
        return getRequestsByFilter(items, filter);
    }, [items, filter]);

    return useMemo(() => (
        <List
            itemLayout="horizontal"
            dataSource={dataSource}
            locale={{
                emptyText: <Empty image={emptyImg} description={t('liveAgent.emptyList')}/>
            }}
            renderItem={ item => (
                <RequestItem 
                    key={item.id}
                    id={item.id}
                    current={current === item.id}
                    agent={item.agent}
                />
            )}
        />
    ), [dataSource, current]);
}

RequestsList.propTypes = {
    items: PropTypes.array.isRequired,
    filter: PropTypes.string.isRequired,
    current: PropTypes.string.isRequired
}

export default connect(
    state => ({
        items: state.liveAgent.requests.items,
        filter: state.liveAgent.filter,
        current: state.chat.current
    }),
    {}
)(RequestsList);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    toggleSpeechToText,
    toggleTextToSpeech,
} from '../../actions/configs';
import { signOut } from '../../actions/auth';
import { clearHistory } from '../../actions/socket';
import './index.sass';
import { Link } from 'react-router-dom';
import Amplify from '@aws-amplify/core';
import { isTelia } from '../../config';
import { ReactComponent as RefreshLogo } from './24icn-refresh-white.svg';
import { ReactComponent as MicOnLogo } from './24icn-mic-on-white.svg';
import { ReactComponent as MicOffLogo } from './24icn-mic-off-white.svg';
import { ReactComponent as VolOnLogo } from './24icn-vol-on-white.svg';
import { ReactComponent as VolOffLogo } from './24icn-vol-off-white.svg';
import { ReactComponent as HamLogo } from './24icn-hamburger-white.svg';

const log = new Amplify.Logger(':::Head Container:::');

class Head extends Component {

    constructor() {
        super();
        this.toggleMenu = this.toggleMenu.bind(this);
        this.onLogoClick = this.onLogoClick.bind(this);
    }

    state = {
        showMenu: false
    }

    clickBody = (e) => {
        if (e.target.tagName !== 'A' && e.target.closest("#chat-menu-dd")) return;
        this.removeBodyListener();
        this.setState({
            showMenu: false
        });  
    }

    clearHistory = () => {
        this.props.clearHistory();
    } 

    toggleMenu() {
        if (!this.state.showMenu) {
            document.body.addEventListener("click", this.clickBody);
        } else this.removeBodyListener();
        this.setState({ showMenu: !this.state.showMenu }); 
    }

    removeBodyListener() {
        document.body.removeEventListener("click", this.clickBody);
    }

    componentWillUnmount() {
        this.removeBodyListener();
    }

    onLogoClick(e) {
        if (window.location.pathname === '/') {
            e.preventDefault();
            this.clearHistory();
        }
    }

    render(){
        log.debug('render');
        const { showMenu } = this.state;
        const { 
            speechToText, 
            textToSpeech, 
            clearingHistory,
            messageInProgress,
            signOut,
            toggleSpeechToText,
            toggleTextToSpeech,
            firstName,
            lastName
        } = this.props;

        return(
            <div className="flex items-center justify-between pv2" style={{minHeight:'48px'}}>
                <div className="flex items-center ml2 rotate" style={{width:'128px', height:'32px'}}>
                    <Link onClick={this.onLogoClick} to="/">
                        {
                            isTelia
                            ? (<img style={{width: '70%'}} src="assets/img/telia-logo-2.png" alt="" />)
                            : (<img style={{width: '70%'}} src="assets/img/hala-full-logo.svg" alt="" />) 
                        }
                    </Link>
                </div>
                <div className="topMenu">
                    <div
                        id="clear-history" 
                        onClick={this.clearHistory}
                        className={`${clearingHistory ? 'clearing ' : ''}mr3 o-60 hover-o-100 pointer`}
                        title="Refresh conversation"
                    >
                        <RefreshLogo/>
                    </div>
                    <div
                        onClick={() => messageInProgress ? null : toggleSpeechToText(!speechToText)}
                        className="mr3 o-60 hover-o-100 pointer"
                        title="Turn microphone ON"
                    >
                        {
                            speechToText
                            ? <MicOnLogo/>
                            : <MicOffLogo/>
                        }
                    </div>
                    <div
                        className="mr3 o-60 hover-o-100 pointer"
                        onClick={() => toggleTextToSpeech(!textToSpeech)}
                        title="Turn sound ON"
                    >
                        {
                            textToSpeech
                            ? <VolOnLogo/>
                            : <VolOffLogo/>
                        }
                    </div>
                    <div
                        onClick={this.toggleMenu} 
                        className="mr2 o-60 hover-o-100 pointer show-chat-menu-dd"
                    >
                        <HamLogo/>
                    </div>
                    <div id="chat-menu-dd" className="chat-menu-dd bg-blue-purple br1 shadow-4 white chat-menu-dd-toggle" style={{width:'188px', zIndex: 1000, display: showMenu ? 'block' : 'none'}} >
                        <div className="pa2 flex justify-between items-center">
                            <p className="dib ph1 ma0">{firstName} {lastName}</p>
                            <img 
                                className="o-60 hover-o-100 pointer v-btm"
                                onClick={this.toggleMenu}
                                src="assets/img/24icn-close-white.svg" alt="" 
                            />
                        </div>
                        <div className="bg-black-05 bt b--black-10">
                            <ul className="list pa1 ma0">
                                <li>
                                    <Link 
                                        to="/contact-us"
                                        className="db link-menu white o-60 hover-o-100 pa2 br2">
                                        Contact Us
                                    </Link>
                                    <Link 
                                        to="/legal-information"
                                        className="db link-menu white o-60 hover-o-100 pa2 br2">
                                        Legal Information
                                    </Link>
                                </li>
                            </ul>
                            <ul className="list pa1 ma0 bt b--black-10">
                                <li>
                                    <span
                                        onClick={signOut}
                                        className="db link-menu white o-60 hover-o-100 pa2 br2" 
                                    >Log out</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Head.propTypes = {
    textToSpeech: PropTypes.bool.isRequired,
    speechToText: PropTypes.bool.isRequired,
    clearingHistory: PropTypes.bool.isRequired,
    messageInProgress: PropTypes.bool.isRequired,
    signOut: PropTypes.func.isRequired,
    toggleSpeechToText: PropTypes.func.isRequired,
    toggleTextToSpeech: PropTypes.func.isRequired,
    clearHistory: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    firstName: state.auth.user.name,
    lastName: state.auth.user.family_name,
    textToSpeech: state.configs.textToSpeech,
    speechToText: state.configs.speechToText,
    messageInProgress: state.configs.messageInProgress,
    clearingHistory: state.configs.clearingHistory
})

export default connect(
    mapStateToProps, 
    {
        signOut,
        toggleSpeechToText,
        toggleTextToSpeech,
        clearHistory
    }
)(Head);

import React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { connect } from 'react-redux';
import { getRequestById } from '../../../reducers/liveAgent/selectors';
import Amplify from '@aws-amplify/core';
import PropTypes from 'prop-types';

const log = new Amplify.Logger(':::Req Irem Description:::');

function ReqDescription({description}){
    log.debug('render');
    return <Ellipsis lines={1}>{description}</Ellipsis>;
}

ReqDescription.propTypes = {
    description: PropTypes.string.isRequired
}

export function connectReqDescription(id) {
    return connect( 
        state => {
            const item = getRequestById(state, id);
            return {
                description: item.description
            };
        },
        {}
    )(ReqDescription);
}
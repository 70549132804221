import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { 
    submitSmartForm,
    closeSmartForm
} from '../../../actions/socket';
import { Form } from 'antd';
import TextInputField from './TextInputField';
import LongTextInput from './LongTextInput';
import SelectInputField from './SelectInputField';
import CheckboxInputField from './CheckboxInputField';
import RadioButtonInputField from './RadioButtonInputField';
import DatePickerInput from './DatePickerInput';
import './FormComponent.sass';
import t from '../../../i18';

class FormComponent extends Component {

    constructor(){
        super();
        this.state = {
            displayFilter: 'all',
        } 
        this.handleSubmit = this.handleSubmit.bind(this);
        this.initializeFormFields = this.initializeFormFields.bind(this);
        this.errorMessage = this.errorMessage.bind(this);
        this.setDisplayFilter = this.setDisplayFilter.bind(this);
    }

    handleSubmit = (e) => {
        const { message, dataId } = this.props;
        e.preventDefault();
        let dateObj = null;
        if (message.data[dataId] &&  message.data[dataId].formData) {
            dateObj = message.data[dataId].formData.find( obj => obj.type === 'date');
        }
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (dateObj && dateObj.key) {
                values = {...values,
                    [dateObj.key] : values[dateObj.key].toISOString()
                };
            }
            if (!err) this.props.actions.submitSmartForm(values, message.id, dataId);
        });
    }

    initializeFormFields = () => {
        const { displayFilter } = this.state;
        const { message, dataId, form: { getFieldDecorator, setFields } } = this.props;
        const { formData } = message.data[dataId];

        let _formData = [];
        if (displayFilter !== 'all') {
            _formData = formData.filter(field => {
                const isRequired = field.required && JSON.parse(field.required);
                switch( displayFilter ) {
                    case 'required':
                        return isRequired;
                    case 'optimal':
                        return isRequired || (field.optimal && JSON.parse(field.optimal));
                    default:
                        return true;
                }
            });
        } else _formData = formData;
        
        return _formData.map(field => {
            switch(field.type){
                case 'select':
                    return (
                        <SelectInputField key={field.key} field={field}
                            getFieldDecorator={getFieldDecorator}
                            errorMessage={this.errorMessage}
                        />
                    );
                case 'checkbox':
                    return (
                        <CheckboxInputField key={field.key} field={field}
                            getFieldDecorator={getFieldDecorator}
                            errorMessage={this.errorMessage}
                        />
                    );
                case 'radio':
                    return (
                        <RadioButtonInputField key={field.key} field={field}
                            getFieldDecorator={getFieldDecorator}
                            errorMessage={this.errorMessage}
                        />
                    );
                case 'longText':
                    return (
                        <LongTextInput key={field.key} field={field}
                            getFieldDecorator={getFieldDecorator}
                            errorMessage={this.errorMessage}
                            setFields={setFields}
                        />
                    );
                case 'date':
                    return (
                        <DatePickerInput key={field.key} field={field}
                            getFieldDecorator={getFieldDecorator}
                            errorMessage={this.errorMessage}
                        />
                    );
                default:
                    return (<TextInputField key={field.key} field={field}
                        getFieldDecorator={getFieldDecorator}
                        errorMessage={this.errorMessage}
                        setFields={setFields}
                            />)
            }
        })
    }

    errorMessage = (field) => {
        let errors = this.props.form.getFieldError(field);
        if(errors){
            if(errors.length > 0) {
                return (
                    <div className="mt1 ph2 pv1 br1 bg-washed-red red f7 fw6">{errors[0]}</div>
                )
            } else {
                return null;
            }
        }

    }

    setDisplayFilter = (displayFilter) => {
        this.setState({ displayFilter });
    }

    renderFilter() {
        const { displayFilter } = this.state;

        return (
            <p className="mt3 mb2 lh-copy">
                <span className="navy mr2">{t('formComponent.fiterTitle')}</span>
                    {[{
                        key: 'all',
                        val: t('formComponent.fiterAll')
                    },{
                        key: 'required',
                        val: t('formComponent.fiterRequired')
                    },{
                        key: 'optimal',
                        val: t('formComponent.fiterOptimal')
                    }].map(el => (
                        <span key={el.key} className={`${displayFilter === el.key ? 'active ' : ''}ml3 fields-filter-link`}
                            onClick={() => this.setDisplayFilter(el.key)}
                        >{el.val}</span>
                    ))}
            </p>
        )
    }

    render() {
        const { message, dataId } = this.props;
        const { showFilter, hide, title } = message.data[dataId];

        if(hide) return null;
        else {
            return (
                <div className="pl2 bl b--black-05 bw3 measure smartForm">
                    <h4 className="mb2">{ title }</h4>
                    {
                        showFilter ? this.renderFilter() : null
                    }
                    <Form 
                        onSubmit={this.handleSubmit}
                        className="mt3">
                        {
                            this.initializeFormFields()
                        }
                        <div className="mt3">
                            <input type="submit" 
                                value={t('formComponent.save')} 
                                className="dib btn btn-blue pv2 ph3 f5 mr1" />
                            <input type="button" 
                                value={t('formComponent.cancel')}
                                onClick={() => this.props.actions.closeSmartForm(message.id, dataId)}
                                className="dib btn btn-ghost pv2 ph3 f5" />
                        </div>
                    </Form>
                </div>
            );
        }
    }

}

FormComponent.propTypes = {
    actions: PropTypes.shape({
        submitSmartForm: PropTypes.func.isRequired,
        closeSmartForm: PropTypes.func.isRequired
    }).isRequired,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        getFieldError: PropTypes.func.isRequired
    }).isRequired,
    message: PropTypes.object.isRequired,
    dataId: PropTypes.number.isRequired,
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        submitSmartForm,
        closeSmartForm
    }, dispatch)
})

export default connect(null, mapDispatchToProps)(Form.create()(FormComponent));

import React from 'react';
import Amplify from '@aws-amplify/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Badge } from 'antd';
import classnames from 'classnames';

const log = new Amplify.Logger(':::LiveAgent:::FilterBadge:::');

function FilterBadge({ value, name }) {
    log.debug('render');
    return <Badge showZero={true} className={classnames(name,{
        none: value===0
    })} count={value}/>;
}

FilterBadge.propTypes = {
    value: PropTypes.number.isRequired
};

export default name => connect(
    state => ({
        value: state.liveAgent.counters[name],
        name: name
    }),
    {}
)(FilterBadge);
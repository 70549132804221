import { 
    LA_filterOnline,
    LA_filterAssigned,
    LA_filterNotAssigned
} from '../../constants';

const init = {
    [LA_filterOnline]: 0,
    [LA_filterAssigned]: 0,
    [LA_filterNotAssigned]: 0
};

export default (state = init, { type, payload }) => {
    switch(type) {
        default:
            return state;
    }
};
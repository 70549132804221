import React, { memo, useCallback, useEffect, useState } from 'react';
import Amplify from '@aws-amplify/core';
import ListItem from '../../../components/ProductsList/Item';
import { useOpenItem } from '../../../actions/purchaseRequisition';
import { useNextPage, execAction } from '../../../actions/fetcher';
import { useMappedState, useDispatch } from 'redux-react-hook';
import ItemAction from '../ItemAction';
import InfiniteItemsList from '../../../components/InfiniteItemsList';
import { 
    purchaseProductsFetch,
    purchaseProductsFetchSuccess
} from '../../../constants';
import './index.sass';

const log = new Amplify.Logger(':::ProductsList:::');
const actionName = "purchaseProducts";

export default memo(() => {
    log.debug('render');
    const [hasMore, setHasMore] = useState(true);
    const dispatch = useDispatch();
    const mapState = useCallback(
        state => ({
            search: state.productsFilter.search,
            page: state.productsFilter.page,
            limit: state.productsFilter.limit,
            category: state.categories.path,
            hasCart: state.actions.saveDraft ? true : false,
            action: state.actions.getProductsList,
            data: state.products.items,
            loading: state.products.loading
        }),
        []
    );
    const { data, loading, hasCart, action, search, page, limit, category } = useMappedState(mapState);

    useEffect(() => {
        let cb = _data => {
            dispatch({type: purchaseProductsFetchSuccess, payload: _data});
            const _hasMore = !(limit && _data.length < limit);
            if (hasMore !== _hasMore) setHasMore(_hasMore);
        };
        dispatch({type: purchaseProductsFetch});
        dispatch(execAction({
            action,
            params: { search, page, limit, category }
        }))
        .then(_data => cb(_data));
        return () => cb = () => null;
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, [search, page, limit, category]);

    const loadMore = useNextPage(actionName);
    const addItem = useOpenItem();
    return (
        <InfiniteItemsList 
            className="products-list"
            data={data} 
            loading={loading} 
            loadMore={loadMore} 
            hasMore={hasMore}
            renderItem={item => 
                <ListItem 
                    onClick={!item.loading && hasCart ? () => addItem(item) : null}
                    item={item} 
                    descriptionType="short"
                    actions={hasCart ? [<ItemAction item={item}/>] : null}
                />
        }/>
    );
});
import React, { memo, useEffect } from 'react';
import { StoreContext } from 'redux-react-hook';
import configureStore from '../../store';
import reducer from '../../reducers/purchaseRequisition';
import ValidateDepartmant from './ValidateDepartmant';
import ContentWrapper from './ContentWrapper';
import { purchaseInit, purchaseClean } from '../../constants';
import './index.sass';

const store = configureStore(reducer);

export default memo(({ actions, orders, department, isLiveAgent, currentUser }) => {
    store.dispatch({type: purchaseInit, payload: { 
        actions, 
        orders, 
        department, 
        isLiveAgent,
        currentUser
    }});
    useEffect(() => {
        return () => store.dispatch({type: purchaseClean});
    }, []);
    return (
        <StoreContext.Provider value={store} >
            <ValidateDepartmant department={department} action={actions.getDepartmentList}/>
            <ContentWrapper />
        </StoreContext.Provider>
    )
});
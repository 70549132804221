import React, { memo, useCallback } from 'react';
import Amplify from '@aws-amplify/core';
import PurchaseInfo from '../PurchaseInfo';
import PurchaseTotal from '../PurchaseTotal';
import PurchaseList from '../PurchaseList';
import { Col, Row, Button } from 'antd';
import { useMappedState } from 'redux-react-hook';
import { useShowProducts } from '../../../actions/purchaseRequisition';
import t from '../../../i18';

const log = new Amplify.Logger(':::Cart:::');

export default memo(() => {
    log.debug('render');

    const addProducts = useShowProducts();
    const isSended = useMappedState(useCallback(
        state => state.cartInfo.status === 'sended',
        []
    ));

    return (
        <React.Fragment>
            <Row type="flex">
                <Col span={18} >
                    <PurchaseInfo/>
                </Col>
                <Col span={6} >
                    <PurchaseTotal/>
                </Col>
            </Row>
            {
                !isSended
                ? <Button 
                    className="add-more-products link-st"  
                    type="primary" 
                    onClick={addProducts}
                    icon="plus">{t('cart.addProducts')}</Button>
                : null
            }
            <PurchaseList/>
        </React.Fragment>
    );
});
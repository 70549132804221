import { connect } from 'react-redux';
import InputMessage from '../../../components/Chat/InputMessage';
import { sendMessage } from '../../../actions/socket';
import { getRequestById } from '../../../reducers/liveAgent/selectors';

export default connect(
    state => {
        let show = state.configs.showInput;
        let loading = state.configs.messageInProgress;
        if (state.chat.current !== 'hala') {
            const curr = getRequestById(state, state.chat.current);
            if (curr && curr.agent) show = true;
            else show = false;
            loading = false;
        } else if (state.liveAgent.request.blockInput) {
            show = false;
            loading = true;
        } else if (state.chat.current === 'hala' && state.liveAgent.request.channel) {
            show = true;
            loading = false;
        }
        return {
            show,
            loading
        };
    },{ 
        sendMessage
    }
)(InputMessage);
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Amplify from '@aws-amplify/core';
import PropTypes from 'prop-types';
import SideContent from '../SideContent';
import MainContent from '../MainContent';

const log = new Amplify.Logger(':::DynamicView Container:::');

function DynamicView({layoutRef, sidePosition}) {
	log.debug('render', );

	useEffect(() => {
		if (sidePosition) {
			layoutRef.current.classList.add('wide-view');
			return () => {
				if (layoutRef.current && layoutRef.current.classList) {
					// eslint-disable-next-line react-hooks/exhaustive-deps
					layoutRef.current.classList.remove('wide-view');
				}	
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sidePosition]);

	return (
		<React.Fragment>
			{
				sidePosition === 'left'
				? <SideContent/>
				: null
			}
			<div className="main-content">
				<MainContent/>
			</div>
			{
				sidePosition === 'right'
				? <SideContent/>
				: null
			}
		</React.Fragment>
	);
}

DynamicView.propTypes = {
	layoutRef: PropTypes.object.isRequired
}

export default connect(
	state => ({
		sidePosition: state.view.sidePosition
	})
)(DynamicView);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sendMessage } from '../../../actions/socket';

class AvailableSkills extends Component {

	sendPredefinedCommand = (skill) => {
		if(skill.conversationInfo && skill.conversationInfo.length){
			this.props.sendMessage(skill.conversationInfo[0]);
		} else{
			console.log('no command found in the skill conversationInfo array');
		}
	}

	render(){
		const { data } = this.props;
		const skillGroups = Object.keys(data);
		return (
			<div className="ml4 pl2">
				<p className="mv2 lh-copy">Hala's Digital Skills available for the usage:</p>
				{
					skillGroups.length ? (
						<div className="ph2 bl b--black-05 bw3">
						{
							skillGroups.map( i => {
								if(data[i].data.length) {
									const d = data[i];
									return (
										<React.Fragment key={i}>
											<h4 className="mb0">{d.name}</h4>
											<ul className="list ph0 mv0 lh-copy f6">
												{
													d.data.map( sk =>
														<li key={ sk.id } 
														onClick={ () => this.sendPredefinedCommand(sk)}
														className="mt1"><span className="link-action">{sk.name}</span></li>
													)
												}
											</ul>
										</React.Fragment>
									)
								}
								return null
							})
						}
						</div>
					) : null
				}
			</div>
		)
	}
}

export default connect(null, { sendMessage })(AvailableSkills);

AvailableSkills.propTypes = {
	sendMessage: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table as AntTable } from 'antd';
import CardTable from '../CardTable';
// import MoreMenuIcon from '../MoreMenuIcon';
import { setSideContent } from '../../actions/view';
import TableItemDetails from '../TableItemDetails';
import './index.sass';

class SmartTable extends Component {
    
    getColumns(legend, fields = null) {
        const columns = [];
        if (fields && fields.length) {
            fields.forEach(field => {
                columns.push({
                    title: legend[field] ? legend[field] : field,
                    dataIndex: field,
                    key: field,
                    render: val => val ? val : '-'
                })
            })
        } else {
            Object.keys(legend).some((field, i) => {
                columns.push({
                    title: legend[field] ? legend[field] : field,
                    dataIndex: field,
                    key: field,
                    render: val => val ? val : '-'
                })
                return i === 4;
            });
        }
        // columns.push({
        //     title: '',
        //     key: 'action',
        //     fixed: 'right',
        //     render: () => <MoreMenuIcon/>
        // })
        return columns;
    }

    openDetails = data => {
        const legend = this.props.data.legend;
        this.props.setSideContent({
            sideContent: props => <TableItemDetails {...props} data={data} legend={legend}/>,
            closeIcon: true
        });
    }

    applyFilter = (data, filters) => {
        const fKeys = filters ? Object.keys(filters) : [];
        let filteredData = [];
        if(fKeys.length){
            for(let i = 0; i < data.length;){
                let pass = false;
                let d = data[i];
                for(let j = 0; j < fKeys.length;) {
                    let f  = fKeys[j];
                    if(!!d[f] && !!(filters[f].indexOf(d[f]) > -1)){
                        pass = true;
                        j++;
                    } else {
                        pass = false;
                        j = fKeys.length;
                    }
                }
                if(pass){
                    filteredData.push(d);
                }
                i++;
            }
            return filteredData;
        } else{
            return data;
        }
    }

	render() {
        const { 
            data: { 
                legend = {}, 
                requiredFields = [], 
                sourceData = [],
                itemsPerPage = 8,
                filters = {}
            } 
        } = this.props;

        const _sourceData = this.applyFilter(sourceData, filters);

        if (!_sourceData.length) return null;
        if (_sourceData.length === 1) {
            return (<CardTable 
                legend={legend} 
                requiredFields={requiredFields}
                sourceData={_sourceData[0]}
                openDetails={() => this.openDetails(_sourceData[0])}
            />)
        }

        const uniqid = Date.now();

		return (
            <div className="custom-table">
                <AntTable 
                    rowKey={(r,k) => `${uniqid}-${k}`}
                    columns={this.getColumns(legend, requiredFields)} 
                    dataSource={_sourceData}
                    pagination={{
                        defaultPageSize: itemsPerPage,
                        hideOnSinglePage: true
                    }}
                    scroll={{ x: 900 }}
                    onRow={(record) => {
                        return {
                          onClick: () => this.openDetails(record)
                        };
                    }}
                />
			</div>
		);
	}
}

SmartTable.propTypes = {
    data: PropTypes.object.isRequired
}

export default connect(
    null, 
    { 
        setSideContent
    }
)(SmartTable);
import { connect } from 'react-redux';
import Avatar from '../../../components/Avatar';
import { getRequestById } from '../../../reducers/liveAgent/selectors';

export default function connectUserAvatar(id) {
    return connect( 
        state => {
            const item = getRequestById(state, id);
            return {
                firstName: item.firstName || '',
                lastName: item.lastName || '',
                logo: item.logo || '',
                online: item.online || false
            };
        },
        {}
    )(Avatar);
}
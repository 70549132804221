/* Auth */
export const SIGNED_IN = 'signedIn';
export const SIGNED_OUT = 'signedOut';
export const SIGN_IN = 'signIn';
export const SIGN_UP = 'signUp';
export const SIGNED_UP = 'signedUp';
export const CONFIRM_SIGN_IN = 'confirmSignIn';
export const CONFIRM_SIGN_UP = 'confirmSignUp';
export const FORGOT_PASSWORD = 'forgotPassword';
export const VERIFY_CONTACT = 'verifyContact';
export const REQUIRE_NEW_PASSWORD = 'requireNewPassword';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';

/* User credentials */
export const userName = 'Andrii Rudchuk';
export const SET_USER_INFO = 'SET_USER_INFO';

/* Messages */
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const RECEIVE_MESSAGE_BACKGROUND = 'RECEIVE_MESSAGE_BACKGROUND';
export const TOGGLE_SPEECH_TO_TEXT = 'TOGGLE_SPEECH_TO_TEXT';
export const TOGGLE_TEXT_TO_SPEECH = 'TOGGLE_TEXT_TO_SPEECH';
export const CLOSE_FORM = 'CLOSE_FORM';
export const QUICK_REPLY_CLICKED = 'QUICK_REPLY_CLICKED';
export const MESSAGE_IN_PROGRESS = 'MESSAGE_IN_PROGRESS';
export const SHOW_INPUT = 'SHOW_INPUT';

/* Agent */
export const LA_changeFilter = 'LA_changeFilter';
export const LA_currentRequests = 'LA_currentRequests';
export const LA_filterOnline = 'online';
export const LA_filterAssigned = 'assigned';
export const LA_filterNotAssigned = 'notAssigned';
export const LA_filterClosed = 'closed';
export const LA_setCurrentChat = 'LA_setCurrentChat';
export const LA_assignRequest = 'LA_assignRequest';
export const LA_assignRequestSuccess = 'LA_assignRequestSuccess';
export const LA_assignRequestError = 'LA_assignRequestError';
export const LA_requestAssigned = 'LA_requestAssigned';
export const LA_requestUpdated = 'LA_requestUpdated';
export const LA_newRequest = 'LA_newRequest';
export const LA_requestStatus = 'LA_requestStatus';
export const LA_requestTimeout = 'LA_requestTimeout';
export const LA_cancelRequest = 'LA_cancelRequest';
export const LA_closeRequest = 'LA_closeRequest';
export const userConnected = 'userConnected';
export const userDisconnected = 'userDisconnected';
export const userTyping = 'userTyping';


/* IO */
export const CONNECT = 'CONNECT';
export const CONNECTED = 'CONNECTED';
export const DISCONNECTED = 'DISCONNECTED';
export const RECONNECT_ATTEMPT = 'RECONNECT_ATTEMPT';
export const CONNECT_ERROR = 'CONNECT_ERROR';
export const RECONNECTED = 'RECONNECTED';
export const MESSAGE = 'MESSAGE';

/* PROCESS */
export const CLEARING_HISTORY_START = 'CLEARING_HISTORY_START';
export const CLEARING_HISTORY_STOP = 'CLEARING_HISTORY_STOP';
export const WELCOME_MESSAGE = 'WELCOME_MESSAGE';
export const ERROR = 'ERROR';
export const DISMISS_ERROR = 'DISMISS_ERROR';

/* VIEWS */
export const CHANGE_VIEW = 'CHANGE_VIEW';
export const CLOSE_SIDE_CONTENT = 'CLOSE_SIDE_CONTENT';
export const CHANGE_SIDE_VIEW = 'CHANGE_SIDE_VIEW';

/* Purchase Requisition */
export const updatePurchaseInfo = 'updatePurchaseInfo';
export const purchaseView = 'purchaseView';
export const purchaseProductSearch = 'purchaseProductSearch';
export const purchaseProductPage = 'purchaseProductPage';
export const purchaseViewCart = 'cart';
export const purchaseViewOrders = 'orders';
export const purchaseViewProducts = 'products';
export const purchaseProductsNextPage = 'purchaseProductsNextPage';
export const purchaseProductsFetch = 'purchaseProductsFetch';
export const purchaseProductsFetchSuccess = 'purchaseProductsFetchSuccess';
export const purchaseInit = 'purchaseInit';
export const purchaseOpenItem = 'purchaseOpenItem';
export const purchaseCloseItem = 'purchaseCloseItem';
export const purchaseEditItem = 'purchaseEditItem';
export const purchaseAddItem = 'purchaseAddItem';
export const purchaseDeleteItem = 'purchaseDeleteItem';
export const purchaseItemQty = 'purchaseItemQty';
export const purchaseSaveRequest = 'purchaseSaveRequest';
export const purchaseSaveSuccess = 'purchaseSaveSuccess';
export const purchaseSaveError = 'purchaseSaveError';
export const purchaseOrdersSearch = 'purchaseOrdersSearch';
export const purchaseOrdersClean = 'purchaseOrdersClean';
export const purchaseOrdersNextPage = 'purchaseOrdersNextPage';
export const purchaseFetchOrdersSuccess = 'purchaseFetchOrdersSuccess';
export const purchaseFetchOrders = 'purchaseFetchOrders';
export const purchaseClean = 'purchaseClean';
export const purchaseEditOrder = 'purchaseEditOrder';
export const purchaseCreateOrder = 'purchaseCreateOrder';
export const purchaseDeleteOrder = 'purchaseDeleteOrder';
export const purchaseDeleteOrderSuccess = 'purchaseDeleteOrderSuccess';
export const purchaseDeleteOrderError = 'purchaseDeleteOrderError';
export const PR_fetchCategories = 'PR_fetchCategories';
export const PR_fetchCategoriesSuccess = 'PR_fetchCategoriesSuccess';
export const PR_fetchCategoriesError = 'PR_fetchCategoriesError';
export const PR_setCategory = 'PR_setCategory';
export const typePRAttachmentUpload = 'PRAttPRAttachmentUploadachment';
export const typePRAttachmentInput = 'PRAttachmentInput';
export const typePRAttachmentDel = 'PRAttachmentDel';

/* Actions Fetcher */
export const fetchAction = 'fetchAction';
export const fetchActionSuccess = 'fetchActionSuccess';
export const fetchActionError = 'fetchActionError';


export const emptyImg = "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAxKSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxlbGxpcHNlIGZpbGw9IiNGNUY1RjUiIGN4PSIzMiIgY3k9IjMzIiByeD0iMzIiIHJ5PSI3Ii8+CiAgICA8ZyBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iI0Q5RDlEOSI+CiAgICAgIDxwYXRoIGQ9Ik01NSAxMi43Nkw0NC44NTQgMS4yNThDNDQuMzY3LjQ3NCA0My42NTYgMCA0Mi45MDcgMEgyMS4wOTNjLS43NDkgMC0xLjQ2LjQ3NC0xLjk0NyAxLjI1N0w5IDEyLjc2MVYyMmg0NnYtOS4yNHoiLz4KICAgICAgPHBhdGggZD0iTTQxLjYxMyAxNS45MzFjMC0xLjYwNS45OTQtMi45MyAyLjIyNy0yLjkzMUg1NXYxOC4xMzdDNTUgMzMuMjYgNTMuNjggMzUgNTIuMDUgMzVoLTQwLjFDMTAuMzIgMzUgOSAzMy4yNTkgOSAzMS4xMzdWMTNoMTEuMTZjMS4yMzMgMCAyLjIyNyAxLjMyMyAyLjIyNyAyLjkyOHYuMDIyYzAgMS42MDUgMS4wMDUgMi45MDEgMi4yMzcgMi45MDFoMTQuNzUyYzEuMjMyIDAgMi4yMzctMS4zMDggMi4yMzctMi45MTN2LS4wMDd6IiBmaWxsPSIjRkFGQUZBIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K";
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sendQuickReply } from '../../../actions/socket'
import uniq from 'lodash/uniq';

const TextMessage = ({ message, dataId, act }) => {
    let d = message.data[dataId] //&& typeof dataId != 'undefined' ? message.data[dataId] : message;
    if (typeof d.value === 'number') d.value = '' + d.value;
    return (
        <div>
            {(typeof d.value === 'string' ? [d.value] : d.value).map((mssg, i) => (
                <p key={i} className="mv2 lh-copy"
                    style={{wordWrap: 'break-word'}}
                    dangerouslySetInnerHTML={{__html: mssg}}
                    >
                    {/* {mssg} */}
                </p>
            ))}
            {
                d.quickReply ?
                    (<div>
                        {
                            uniq(d.quickReply).map((reply, i)  => (
                                <button key={i} 
                                    type="button"
                                    onClick={() => act.sendQuickReply(reply, dataId, message.id)}
                                    className="btn btn-ghost mb2 mr2 pv1 ph2 f6"
                                >{reply}</button>
                            ))
                        }
                    </div>)
                : null
            }
        </div>
    )
};

TextMessage.propTypes = {
    message: PropTypes.object.isRequired,
    act: PropTypes.shape({
        sendQuickReply: PropTypes.func.isRequired
    }).isRequired
};

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
    act: bindActionCreators({
        sendQuickReply
    }, dispatch)
})

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(TextMessage);

import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import './polyfill.js';
import React from 'react';
import { render } from 'react-dom';
import Root from './containers/Root';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import config, { prefix } from './config';
import Analytics from '@aws-amplify/analytics';
import configureStore from './store';
import './antd-general.css';
import 'antd/es/table/style/index.css';
import 'antd/lib/list/style/index.css';
import 'antd/lib/avatar/style/index.css';
import 'antd/lib/skeleton/style/index.css';
import 'antd/lib/icon/style/index.css';
import 'antd/lib/empty/style/index.css';
import 'antd/lib/tag/style/index.css';
import 'antd/lib/tooltip/style/index.css';
import 'antd/lib/grid/style/index.css';
import 'antd/lib/modal/style/index.css';
import 'antd/lib/badge/style/index.css';
import 'antd/lib/auto-complete/style/index.css';
import 'antd/lib/cascader/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/descriptions/style/index.css';
import 'antd/lib/select/style/index.css';
import 'antd/lib/dropdown/style/index.css';
import 'antd/lib/input-number/style/index.css';
import 'ant-design-pro/lib/DescriptionList/style/index.css';
import 'ant-design-pro/lib/Ellipsis/style/index.css';
import './index.css';
import './index.sass';
import smoothscroll from 'smoothscroll-polyfill';
// import ReactGA from 'react-ga';
import rootReducers from './reducers';
import { Hub } from '@aws-amplify/core';

const $body = document.querySelector('body').classList;
$body.add(prefix);
$body.add('bg');

if (config.googleAnalytics) {
    // ReactGA.initialize(config.googleAnalytics);
    // ReactGA.pageview(window.location.pathname + window.location.search);
}

window.__forceSmoothScrollPolyfill__ = true;
smoothscroll.polyfill();

Amplify.configure(config.aws);
Analytics.disable()
if (process.env.NODE_ENV === 'production') {
    // Analytics.enable()
} else {
    Amplify.Logger.LOG_LEVEL = 'DEBUG';
    // const {whyDidYouUpdate} = require('why-did-you-update');
    // whyDidYouUpdate(React);
}

const store = configureStore(rootReducers);

Hub.listen('rootDispatch', ({payload}) => {
    store.dispatch(payload && payload.payload ? payload.payload : payload);
});

render(
    <Root store={store} />,
    document.getElementById('root')
);

serviceWorker.unregister();
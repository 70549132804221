import { combineReducers } from 'redux';
import errors from './errors';
import auth from './auth';
import messages from './messages';
import configs from './configs';
import notifications from './notifications';
import view from './view';
import liveAgent from './liveAgent';
import chat from './chat';
import reduceReducers from 'reduce-reducers';
import postReducer from './postReducer';

const rootReducer = combineReducers({
    auth,
    errors,
    messages,
    configs,
    notifications,
    view,
    liveAgent,
    chat
});

export default reduceReducers(rootReducer, postReducer);

import {
    purchaseClean
} from '../../constants';

const delRelValsCache = state => {
    const id = state.actions.getRelevantValues;
    if (!id) return state;
    const keys = Object.keys(state.cacheData);
    keys.forEach(k => {
        if (k.indexOf(`${id}:`) === 0) {
            delete state.cacheData[k];
        }
    });
    return state;
};

export default (state, { type, payload }) => {
    switch(type) {
        case purchaseClean:
            state = delRelValsCache(state);
            return state;
        default:
            return state;
    }
};
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Logger } from '@aws-amplify/core';
import { sendMessage } from '../../../actions/socket';
import { onWelcomeMessage } from '../../../actions/configs';
import './HelloMessage.sass';
import classNames from'classnames';
import t from '../../../i18';

const log = new Logger(':::Hello Message:::'); 
const helloMessages = t('helloMessage.quickMessages');

function HelloMessage({
    sendMessage, 
    onWelcomeMessage,
    welcomeMessage,
    firstName,
    disabled
}) {
    log.debug('render');
    const [state, setState] = useState({
        value: '',
        show: false,
        animateHide: false
    });

    const onInput = useCallback(event => {
        setState({
            ...state,
            value: event.target.value
        });
    }, [state]);

    const onSendMessage = useCallback(msg => {
        if (!msg && !state.value) return false;
        setState({
            ...state,
            animateHide: true
        });
        setTimeout(() => {
            msg = msg || state.value;
            setState({
                animateHide: false,
                show: false,
                value: ''
            });
            sendMessage(msg);
            onWelcomeMessage();
        }, 1000);
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, [state]);

    const keyPress = useCallback(e => {
        if(e.keyCode === 13) onSendMessage();
    }, [onSendMessage]);

    useEffect(() => {
        if (!welcomeMessage) {
            const tId = setTimeout(() => setState({
                animateHide: false,
                show: true,
                value: ''
            }), 500);
            return () => clearTimeout(tId);
        }
    }, [welcomeMessage]);

    return (
            <div className={classNames("pa2", {
                'show-hl-message': state.show,
                'animate-hide': state.animateHide,
                'animate-done': welcomeMessage
            })}>
                <div className="ph2 pv4 pv5-ns w-100 tc">
                    <div className="hello-msg-content lh-copy center">
                        <div className="hl-message-anim hl-message-step1">
                            <p className="f3 mb0"><span role="img" aria-label="hi">👋</span></p>
                            <p>{t('helloMessage.title')} { firstName },</p>
                        </div>
                        <p className="mb4 hl-message-anim hl-message-step2">
                            <span>{t('helloMessage.description')}</span>
                        </p>
                        <div className="hl-message-input-wrap flex hl-message-anim hl-message-step3">
                            <input  
                                onChange={onInput} 
                                onKeyDown={keyPress}
                                id="" 
                                type="text" 
                                name="" 
                                value={state.value}
                                className="w-100 pv2 ph2 f5" 
                                disabled={disabled}
                                placeholder={t('helloMessage.inoutPlaceholder')}/>
                            <div className="fr">
                                <button 
                                    onClick={onSendMessage}
                                    type="button" className="fr dib btn btn-blue pa2 f5 ml2 ph3">
                                    <img className="v-btm" 
                                        src="/assets/img/24icn-send-white.svg" 
                                        alt="" 
                                        style={{
                                        width: "24px",
                                        height: "24px"
                                    }}/>
                                </button>
                            </div>
                        </div>
                        <div className="mt2 lh-copy pl2">
                            <p className="f6 o-60 hl-message-anim hl-message-step4">
                                {t('helloMessage.startWith')}
                            </p>
                            <div className="hl-message-anim hl-message-step5">
                                {helloMessages.map((msg,k) => (
                                    <button 
                                        key={`welMsg${k}`}
                                        type="button"
                                        style={{margin: '0 .15em .3em'}} 
                                        className="btn btn-ghost pv1 ph2 f6"
                                        onClick={() => onSendMessage(msg)}
                                    >{ msg }</button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

HelloMessage.propTypes = {
    firstName: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    sendMessage: PropTypes.func.isRequired,
    onWelcomeMessage: PropTypes.func.isRequired,
    welcomeMessage: PropTypes.bool.isRequired
}

export default connect(
    state => ({
        firstName: state.auth.user.name,
        welcomeMessage: state.configs.welcomeMessage,
        disabled: state.liveAgent.request.blockInput
    }), 
    {
        sendMessage,
        onWelcomeMessage
    }
)(HelloMessage);
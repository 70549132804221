import React, { memo, useCallback } from 'react';
import Amplify from '@aws-amplify/core';
import { Badge } from 'antd';
import { FiShoppingCart } from "react-icons/fi";
import { useMappedState } from 'redux-react-hook';
import { showSummary } from '../../../actions/purchaseRequisition';
import './index.sass';

const log = new Amplify.Logger(':::CartBadge:::');

export default memo(() => {
    log.debug('render');

    const mapState = useCallback(state => state.cartInfo.count, []);
    const count = useMappedState(mapState);
    const onClick = showSummary();

    return (
        <Badge onClick={onClick} count={count}>
            <FiShoppingCart />
        </Badge>
    )
})
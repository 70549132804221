import { 
    purchaseInit,
    purchaseOrdersSearch,
    purchaseOrdersNextPage,
    purchaseClean,
    purchaseOrdersClean
} from '../../constants';

const init = {
    search: '',
    page: 0,
    limit: 20
};

export default (state = init, { type, payload }) => {
    switch(type) {
        case purchaseInit:
            const d = payload.orders && payload.orders.search ? {
                search: payload.orders.search.join(',').replace(/\[at\]/g,'@'),
                page: 0
            } : {};
            return {
                ...state,
                ...d
            };
        case purchaseOrdersSearch:
            return {
                ...state,
                search: payload.join(','),
                page: init.page
            };
        case purchaseOrdersNextPage:
            return {
                ...state,
                page: state.page + 1
            };
        case purchaseOrdersClean:
        case purchaseClean:
            return init;
        default:
            return state;
    }
};
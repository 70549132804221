import {
    PR_fetchCategoriesSuccess,
    PR_setCategory,
    purchaseInit
} from '../../constants';
import { buildTree } from './categoriesTree';

const stKey = 'PR_Categories';

function getItems() {
    const data = localStorage.getItem(stKey);
    return data ? JSON.parse(data) : [];
}

function save(d) {
    localStorage.setItem(stKey, JSON.stringify(d));
}

function _init() {
    return buildTree({
        items: getItems(),
        tree: [],
        current: [],
        path: null,
        name: null 
    });
}

const init = _init();

export default (state = init, {type, payload}) => {
    switch(type) { 
        case PR_fetchCategoriesSuccess: {
            const items = [
                ...payload
            ];
            save(items);
            return {
                ...state,
                items,
                tree: []
            };
        }
        case PR_setCategory: {
            const data = payload.options ? (payload.options.pop() || null) : null;
            return {
                ...state,
                current: payload.value || [],
                ...(data && data.path ? {
                    path: data.path,
                    name: data.label
                } : {
                    path: null,
                    name: null 
                })
            };
        }
        case purchaseInit:
            return _init();
        default: 
            return state;
    }
};
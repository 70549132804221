import React, { useMemo, useEffect, useState } from 'react';
import Amplify from '@aws-amplify/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { cancelRequest, requestTimeout } from '../../../actions/liveAgent';
import t from '../../../i18';
import { notification } from 'antd';
import { waitMinutes } from '../../../reducers/liveAgent/request';
import './index.sass';

const log = new Amplify.Logger(':::Live Agent Status:::');

function createDuration(fromDate, durationNumber = waitMinutes, durationFormat = 'minutes') {
    if (!fromDate) return null;
    return moment.duration((moment(fromDate).add(durationNumber, durationFormat).unix() - moment().unix())*1000, 'milliseconds');
}

const key = 'live-agent-timer';
const _total = waitMinutes*60;
let firstTime = true;
setTimeout(() => {
    firstTime = false;
}, 1000);

function openNotification({onClose, seconds, minutes}) {
    if (firstTime) {
        return false;
    }
    let description = '';
    if (minutes>0) description = `${minutes}:`;
    description += minutes > 0 && seconds < 10 ? `0${seconds}` : seconds;
    const btn = (
        <button onClick={onClose} type="button" className="btn btn-blue">
            {t('liveAgent.cancelRequest')}
        </button>
    )
    const tt = minutes * 60 + seconds;
    notification.open({
        className:"live-agent-timer",
        message: t('liveAgent.responseTimeTitle'),
        description: (
            <span>
                <div style={{width: `${100 - (100*tt/_total)}%`}} className="progress"></div>
                {description}
            </span>
        ),
        btn,
        key,
        onClose,
        duration: 0
    });
}

function closeNotification() {
    notification.close(key);
}

function _Timer({date, cancelRequest, requestTimeout}) {
    log.debug('render Timer');
    const [duration, setDuration] = useState(null);

    useEffect(() => () => closeNotification(), []);
    useEffect(() => { setDuration(createDuration(date)); }, [date]);

    const isValid = useMemo(() => {
        if (!date || !duration) return false;
        if (duration.minutes() > 0 || duration.seconds() > 0) {
            return true;
        }
        return false;
    }, [duration, date]);

    useEffect(() => {
        if (duration) {
            const seconds = duration.seconds();
            const minutes = duration.minutes();
            log.debug(`render Timer ${minutes} ${seconds}`);
            if (minutes <= 0 && seconds === 0) {
                requestTimeout();
                closeNotification();
            }
        }
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, [duration]);

    useEffect(() => {
        if (isValid) {
            var tId = setTimeout(() => {
                setDuration(createDuration(date));
            }, 1000);
            
            const seconds = duration.seconds();
            const minutes = duration.minutes();
            openNotification({
                onClose: () => cancelRequest(),
                seconds,
                minutes
            })
            return () => clearTimeout(tId);
        }
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, [duration, isValid, date]);
    return null;
}

_Timer.propTypes = {
    cancelRequest: PropTypes.func.isRequired,
    requestTimeout: PropTypes.func.isRequired
}

const Timer = connect(
    state => ({
        date: state.liveAgent.request.timer
    }),
    { cancelRequest, requestTimeout }
)(_Timer);

function Status({status}) {
    log.debug('render');
    if (status === 'pending') {
        return <Timer/>
    }
    return null;
}

export default connect(
    state => ({
        status: state.liveAgent.request.status
    }),
    {}
)(Status);
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { 
    SIGNED_OUT,
    AUTH_LOADING,
    AUTH_ERROR,
    SIGNED_IN,
    SIGN_IN
} from '../constants';

export const authStatusChanged = (type, authData) => ({
    type,
    authData
});

export const signedIn = authData => ({
    type: SIGNED_IN,
    authData
});

export const signIn = () => ({
    type: SIGN_IN
});

export const signOut = (s,s1) => {
    return async dispatch => {
        await Auth.signOut()
        .then(() => Hub.dispatch(SIGNED_OUT));
        dispatch({
            type: SIGNED_OUT
        });
    };
};

export const authError = (authData) => ({
    type: AUTH_ERROR,
    authData
})

export const authLoading = (authData) => (
    dispatch => {
        dispatch({
            type: AUTH_LOADING,
            authData
        });
    }
);
import React, { useCallback, useRef, useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import './index.sass';
import t from '../../../i18';
import Amplify from '@aws-amplify/core';

const { TextArea } = Input;
const log = new Amplify.Logger(':::InputMessage:::');

function InputMessage({sendMessage, loading, show}) {
    log.debug('render');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const ref = useRef(null);
    const wrap = useRef(null);
    const handleClick = useCallback(() => {
        if (loading) setError(true);
        else ref.current.focus();
    }, [ref, loading]);
    const send = useCallback(() => {
        if (message && show) {
            sendMessage(message);
            setMessage('');
        }
    }, [message, sendMessage, show]);
    const onChange = useCallback(e => setMessage(e.target.value), []);
    const onPressEnter = useCallback(e => {
        e.preventDefault();
        send();
    }, [send]);

    useEffect(() => {
        if (!loading || show) {
            ref.current.focus();
            if (error) setError(false);
        }
    }, [loading, error, ref, show]);

    useEffect(() => {
        if (show) wrap.current.classList.add('show');
        else {
            setMessage('');
            wrap.current.classList.remove('show');
        }
    }, [show]);

    return (
        <div ref={wrap} onClick={handleClick} className="message-input-wrap">
            <div className="main-message-input">
                <TextArea
                    rows="1"
                    ref={ ref }
                    autoFocus={ true }
                    className="message-input"
                    placeholder={
                        error
                        ? t('inputMessage.placeholderWait')
                        : t('inputMessage.placeholder')
                    }
                    autoSize={{ minRows: 1, maxRows: 5 }}
                    value={ message }
                    disabled={ loading }
                    onChange={ onChange }
                    onPressEnter={ onPressEnter } 
                />
                <img 
                    className="main-send-message"
                    src="assets/img/24icn-send.svg" 
                    alt=""
                    onClick={send}
                />
            </div>
        </div>
    );
}

InputMessage.propTypes = {
    loading: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
    sendMessage: PropTypes.func.isRequired
}

export default memo(InputMessage);
import React, { memo, useRef, useEffect, useMemo } from 'react';
import { Badge, Avatar } from 'antd';
import Amplify from '@aws-amplify/core';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import './index.sass';

const log = new Amplify.Logger(':::User Avatar:::');

function charAt(str) {
    return str.charAt(0).toUpperCase();
}

function UAvatar({firstName, lastName, logo, online}) {
    log.debug('render memo');

    const ref = useRef(null);
    useEffect(() => {
        const el = findDOMNode(ref.current);
        if (online) el.classList.add('online');
        else el.classList.remove('online');
    }, [online]);

    const fullName = useMemo(() => `${charAt(firstName)}${charAt(lastName)}`, [firstName, lastName]);
    return useMemo(() => {
        log.debug('render');
        return (
            <Badge ref={ref} dot className="user-avatar">
                <Avatar src={logo?logo:null} shape="square" size="large" >
                    {
                        !logo
                        ? fullName
                        : null
                    }
                </Avatar>
            </Badge>
        )
    }, [logo, fullName]);
};

UAvatar.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    logo: PropTypes.string,
    online: PropTypes.bool.isRequired
}

export default memo(UAvatar);
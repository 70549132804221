import React from 'react';
import Amplify from '@aws-amplify/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MessagesList from '../../components/Chat/MessagesList';
import HelloMessage from '../../components/Chat/Message/HelloMessage';
import InputMessage from './InputMessage';
import ChatContainer from '../../components/ChatContainer';
import RequestDescription from '../LiveAgent/RequestDescription';
import ChatHeader from '../LiveAgent/ChatHeader';
import './index.sass';

const log = new Amplify.Logger(':::Chat:::');

function Chat({isHala, disabled, noChannel}) {
    log.debug('render');
    return (
        <ChatContainer 
            className={disabled ? 'disabled' : null}
            header={<ChatHeader/>}
            input={<InputMessage/>}>
            {
                isHala && noChannel
                ? <HelloMessage/>
                :  <RequestDescription/>
            }
            <MessagesList/>
        </ChatContainer>
    )
}

Chat.propTypes = {
    isHala: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    noChannel: PropTypes.bool.isRequired
};

export default connect(
    state => {
        const isHala = state.chat.current === 'hala';
        return {
            isHala: isHala,
            disabled: state.liveAgent.request.blockInput && isHala ? true : false,
            noChannel: state.liveAgent.request.channel ? false : true
        };
    },
    {}
)(Chat);
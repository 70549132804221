// import sumBy from 'lodash/sumBy';
import dotProp from 'dot-prop-immutable';
import {
    purchaseEditItem,
    purchaseAddItem,
    purchaseDeleteItem,
    purchaseItemQty
} from '../../constants';
import numeral from 'numeral';

// export const sumTotal = items => {
//     const t = sumBy(items, i => (parseFloat(i.price) * (parseFloat(i.qty) || 1)) || 0);
//     if (t % 1 !== 0) return t.toFixed(2);
//     return t.toString();
// };

export const sumTotal = items => {
    if (!items || !items.length) return '0';
    const t = items.reduce((acc, i) => {
        if (!i.price) return acc;
        if (i.qty) {
            return acc.add(
                numeral(i.price).multiply(i.qty).value()
            );
        }
        return acc.add(i.price);
    }, numeral(0));
    return t.format('0.[00]');
};

const updateSummary = state => {
    state = dotProp.set(
        state,
        `cartInfo.count`,
        state.cart.items.length
    );
    state = dotProp.set(
        state,
        `cartInfo.total`,
        sumTotal(state.cart.items)
    );
    return state;
};

export default (state, { type, payload }) => {
    switch(type) {
        case purchaseEditItem:
        case purchaseAddItem:
        case purchaseDeleteItem:
        case purchaseItemQty:
            return updateSummary(state);
        default:
            return state;
    }
};
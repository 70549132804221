import React from 'react';
import './MessageSpinner.css';

const MessageSpinner = () => (
    <div className="spinner mv2">
        <div key="bn1" className="bounce1"></div>
        <div key="bn2" className="bounce2"></div>
        <div key="bn3" className="bounce3"></div>
    </div>
);

export default MessageSpinner;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Amplify from '@aws-amplify/core';

const log = new Amplify.Logger(':::LegalInformation Container:::');

const html = {
    __html:`<div class="h-copy">
    <h2 class="mt0 mb2">Legal information</h2>
    <p class="mt0 lh-title">BY CLICKING THE CONFIRM BUTTON OR BY USING OR ACCESSING HALA SERVICE, YOU (“CUSTOMER”) AGREE TO BE BOUND BY THE TERMS OF THIS HALA TERMS OF SERVICE AGREEMENT (“AGREEMENT”).  IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, YOU MAY NOT USE OR ACCESS HALA SERVICE. “YOU” MEANS THE NATURAL PERSON OR THE ENTITY YOU REPRESENT THAT IS AGREEING TO BE BOUND BY THIS AGREEMENT, THEIR EMPLOYEES AND THIRD PARTY CONTRACTORS THAT PROVIDE SERVICE TO YOU.  YOU SHALL BE LIABLE FOR ANY FAILURE BY SUCH EMPLOYEES AND THIRD PARTY CONTRACTORS TO COMPLY WITH THE TERMS OF THIS AGREEMENT.</p>
    <h3 class="mb2">Service description</h3>
    <p class="mt2">The Artificial Intelligence based Hala Solution is a communication interface (smart machine, chatbot, digital assistant, virtual assistant), allowing the users to communicate with the machine in a natural language and solve the queries. The solution makes automation of IT processes in ERP, CRM and HCM enterprise software from one side and from another side Hala makes automation of Business Processes. Hala solution is able to cover all routine (common, most frequent) requests from the users.</p>
    <p>The Hala system is easy to customize to the needs of the company. The advanced Artificial Intelligence and Machine Learning technologies with predefined Digital Skills enable fast and cost-effective implementation and system extensions and other further modifications, where necessary.</p>
    <p>Users can communicate with Hala by using text and voice, they also able to send the image with the problem. Users can use different devices such as computers, mobile phones, and tablets. Available next communication channels - web browsers (Hala Web Communication Interface), Skype for business, slack, lync, and other communication tools available on the market.</p>
    <p>Hala has pre-built digital features that work with enterprise software. Clients do not need to spend time on training NLP and ML models. All you need is to set up an account, make integration, and activate the skills.</p>
    <h3 class="mb2">License and rights</h3>
    <p class="mt2">The Customer will be granted for a period of the Usage a non-exclusive, non-transferable license to use the Hala Service from any location. The Customer is not entitled to assign, transfer, lease or rent the license to any third party and is only entitled to use the Hala Software for purposes of the Customer and its affiliates. The software may be run by third parties only if so expressly agreed between Hala and the Customer in writing. Any attempted assignment or sublicense without such consent will be void. The rights and duties of the Parties hereunder shall inure to the benefit of and be binding upon their respective successors and permitted assigns and sub-licensees.</p>
    <h3 class="mb2">Ownership of data and software</h3>
    <p class="mt2">Data (means personal data relating to a natural person who is or can be identified from this data and has been entered into the Pilot Project by or upon the request from the Customer) remains the property of the Customer both during Agreement execution and after Agreement termination.</p>
    <p>Hala is and remains the owner of the intellectual property rights of each part of the Solution made available to the Customer, and more generally of the IT infrastructure (including software, source codes, design, sui generis databases) used or developed in relation to the Agreement.</p>
    <p>The Agreement does not grant the Customer any right of ownership of the Solution or hardware. For the avoidance of doubt, any intellectual property developed by the Hala upon request of the Customer within the frame of this Agreement shall remain the sole property of Hala, unless otherwise mutually agreed upon by the Parties.</p>
    <p>Except as expressly set forth herein, Hala alone will retain all intellectual property rights relating to the Service or the Software or any suggestions, ideas, training data, enhancement requests, feedback, recommendations or other information provided by Customer or any other party relating to the Service and/or the Software, which are hereby assigned to Hala.</p>
    <p>Customer will not, and will not permit any third party to: reverse engineer (except to the extent statutory law expressly prohibits or limits restrictions on reverse engineering, but only to the extent required by such statute), decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas or algorithms of the Services or any software, documentation or data related to the Services (“Software”); modify, translate, or create derivative works based on the Services or Software; use in excess as set forth below; use the Services or Software for timesharing or service bureau purposes or for any purpose other than its own internal business purposes; or use the Services or Software other than in accordance with this Agreement and in compliance with all applicable laws and regulations (including but not limited to any European privacy laws) and intellectual property.</p>
    <p>The Customer agrees not to reproduce any part of the Solution, or any related documentation, by any means whatsoever, in any form whatsoever and on any medium whatsoever.</p>
    <h3 class="mb2">Technical support</h3>
    <p class="mt2">Technical support is provided for the Service during the Agreements term and is not available as a separate offering.</p>
    <h4 class="mb2">Contact</h4>
    <p class="mt2">There are two channels to contact Customer Support:</p>
    <ul>
      <li>Email access: <a class="link" href="mailto:support@hala.ai">support@hala.ai</a>, <a class="link" href="mailto:rudchuk@hala.ai">rudchuk@hala.ai</a>, <a class="link" href="mailto:mykyta.bazhenov@hala.ai">mykyta.bazhenov@hala.ai</a>;</li>
      <li>Voice access: +372 59823235, +372 58529042</li>
    </ul>
    <p>Please ensure the service representative understands the business impact (Severity) of your issue.</p>
    <p>* We provide only English language Support.</p>
    <h3 class="mb2">Hours</h3>
    <p class="mt2"><strong>Support Hours:</strong> Hala Support Center hours cover the prevailing business hours in the country where your product is licensed and/or your contract is registered.</p>
    <p><strong>Off shift support:</strong> off shift hours are defined as all other hours outside of normal country business hours. During Off shift hours, we will use commercially reasonable efforts to respond by telephone within two hours to customer critical problems (severity 1). Off shift support will be provided in English.</p>
    <h3 class="mb2">Language</h3>
    <p class="mt2">Support language is English.</p>
    <h3 class="mb2">Severity</h3>
    <p class="mt2">The following table defines severity levels and provides some examples:</p>
  </div>
  <table class="table w-100 tl">
    <tr class="bg-navy-near-white">
      <th class="w-5">Severity</th>
      <th class="w-55">Severity Definition</th>
      <th class="w-20">Response Time Objectives</th>
      <th class="w-20">Response Time Coverage</th>
    </tr>
    <tr>
      <td class="v-top"><p class="mv0">1</p></td>
      <td class="v-top"><p class="mv0"><strong>Critical business impact/service down:</strong> Business critical functionality is inoperable or critical interface has failed. This usually applies to a production environment and indicates an inability to access services resulting in a critical impact on operations. This condition requires an immediate solution.</p></td>
      <td class="v-top"><p class="mv0">Within 1 hour</p></td>
      <td class="v-top"><p class="mv0">24/7</p></td>
    </tr>
    <tr>
      <td class="v-top"><p class="mv0">2</p></td>
      <td class="v-top"><p class="mv0"><strong>Significant business impact:</strong> A service business feature or function of the service is severely restricted in its use or you are in jeopardy of missing business deadlines.</p></td>
      <td class="v-top"><p class="mv0">Within 2 business hours</p></td>
      <td class="v-top"><p class="mv0">M-F business hours</p></td>
    </tr>
    <tr>
      <td class="v-top"><p class="mv0">3</p></td>
      <td class="v-top"><p class="mv0"><strong>Minor business impact::</strong> A service business feature or function of the service is severely restricted in its use or you are in jeopardy of missing business deadlines.</p></td>
      <td class="v-top"><p class="mv0">Within 4 business hours</p></td>
      <td class="v-top"><p class="mv0">M-F business hours</p></td>
    </tr>
    <tr>
      <td class="v-top"><p class="mv0">4</p></td>
      <td class="v-top"><p class="mv0"><strong>Minimal business impact:</strong> An inquiry or non-technical request</p></td>
      <td class="v-top"><p class="mv0">Within 1 business day</p></td>
      <td class="v-top"><p class="mv0">M-F business hours</p></td>
    </tr>
  </table>
  <div class="lh-copy">
    <h3 class="mb2">Identifiers of the service</h3>
    <p class="mt2">Customer are responsible for establishing a username and password (or any other means required by Hala “Service”) for verifying that only designated employees of Customer have access to any administrative functions of the Services.  Customer will be responsible for maintaining the security of Customer account, passwords (including but not limited to administrative and user passwords) and files, and for all uses of Customer account with or without Customer knowledge or consent.</p>
    <p>Customer will designate an employee who will be responsible for all matters relating to this Agreement (“Primary Contact”).  Customer may change the individual designated as Primary Contact at any time by providing written notice to Hala. Hala is not liable for any losses, damages, claims, demands, actions, costs and expenses (including reasonable attorneys’ fees and court costs) arising from or created by any of Customer acts or omissions related to the access or use of the Service.</p>
    <h3 class="mb2">Cookies</h3>
    <p class="mt2">You are aware and agree that Hala may, as part of the normal operation and support of the Service, collect personal information from you (your employees and contractors) related to the use of the Service, through tracking and other technologies. Hala does so to gather usage statistics and information about effectiveness of our Service for the purpose of improving user experience and/or tailoring interactions with Customer. Customer confirm that Customer will obtain or have obtained consent to allow Hala to process the collected personal information for the above purpose within Hala in compliance with applicable law. Hala will comply with requests from Customer employees and contractors to access, update, correct or delete their collected personal information.</p>
    <h3 class="mb2">Use of client content and data</h3>
    <p class="mt2">Hala will not use or disclose the results arising from Customer's use of the Service that are unique to Customer Content (Insights) or that otherwise identify Customer. However, as part of the Service, Hala uses Content and other information (except for Insights) that results from Content in the course of providing the Service for research, testing, and offering development related to the Service. Customer also agrees that Hala may use without restriction whatsoever any feedback and suggestions they provide. Customer represents and warrants that it has all rights and permissions to provide the Content, data, suggestions and feedback to the Service.</p>
    <p>Customer can instruct Hala not to use Customer Content for the purposes outlined in paragraph below by sending the request to Hala support.</p>
    <h3 class="mb2">Continues delivery and model improvements</h3>
    <p class="mt2">These Service deployment plan operate under a continuous delivery model, which allows for updates without causing downtime to the Customer.</p>
    <p>The underlying learning models in the Service may be periodically modified by Hala, based on learning, in order to improve its performance. Existing models that was trained for Customer in a Service deployment will not be immediately impacted. Expired models will be updated to the current model, if not already done so by the Customer, without interruption to the Service. Any new model trained will incorporate the latest model available.</p>
    <h3 class="mb2">Confidentiality and references</h3>
    <p class="mt2">The Parties agree that keeping the information, which they exchange during the Pilot Project, in strict confidence is essential for the successful performance of the obligations of both of the Parties, for successful execution of the Pilot Project and that disclosing such confidential information without express consent from the other Party could cause irreparable harm to the disclosing Party.</p>
    <p>This Agreement is a reciprocal agreement and the obligation to keep information confidential extends to both Parties. Any information that would be regarded as confidential by a reasonable business person and relating to:</p>
    <ol type="a">
      <li>the business, affairs, customers (including but not limited to their personal data and the fact of being a customer), suppliers, plans, intentions, or market opportunities of the disclosing Party or of any member of the group of companies to which the disclosing Party belongs to;</li>
      <li>the operations, processes, product information, know-how, designs, trade secrets, materials, products, technology and software of the disclosing Party or of any member of the group of companies to which the disclosing Party belongs to;</li>
      <li>nformation which is marked with a restrictive legend, e.g. using the word “CONFIDENTIAL”;</li>
      <li>any information developed by the Parties in the course of carrying out the Pilot Project or this Agreement</li>
    </ol>
    <p>The recipient of confidential information shall hold the confidential information in confidence and shall not use the discloser’s confidential information in other manner than for the purposes of conducting its business. Customer agrees not to use any confidential information as a basis upon which to develop or have a third party develop a competing or similar product as Hala Solutions or any of its group companies have, unless Parties expressly agree that such right exists.</p>
    <p>Neither this Agreement nor any disclosure of Confidential Information made under it grants the Customer any right or license for any trademark, copyright or patent.</p>
    <p>Upon termination of this Agreement, the Pilot Project or if it is evident that a Customer no longer needs the confidential information for working on the Pilot Project or for fulfilling any agreement with the discloser, the Customer shall within ten (10) days of the discloser’s request; return all confidential information received or kept in tangible form, including copies, or reproductions or other media containing confidential information; delete all confidential information received or kept in non-tangible form; provide a written certificate to discloser regarding destruction and/or deletion of the confidential information;</p>
    <p>Any unauthorised disclosure or use of confidential information otherwise than permitted in this Agreement shall be a breach of this Agreement and without limiting any other claims attributable by law, the non-defaulting Party shall be entitled to penalty payment in the total sum of 10 000 euros.</p>
    <p>Unless otherwise agreed, Hala will be entitled to use Customer's name and logo in Hala's presentation material and on Hala's websites and include the name of the Customer on Hala's customer and reference list.</p>
    <h3 class="mb2">Warranties and disclaimer</h3>
    <p class="mt2">Hala undertakes to make every effort to ensure the permanence, continuity and quality of Pilot Project provided to the Customer. Given the nature of the Pilot Project however, Hala cannot guarantee the absence of Solution interruptions. The Solution may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by Hala or by third-party providers, or because of other causes beyond Hala’s reasonable control, but Hala shall use reasonable efforts to provide advance notice by e-mail of any scheduled service disruption.</p>
    <p>However, Hala does not warrant that the Solution will be uninterrupted or error free; nor does it make any warranty as to the results that may be obtained from use of the Solution. Except as expressly set forth in this Agreement, the Solution is provided “as is” and Hala disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose and non-infringement.</p>
    <h3 class="mb2">Indemnity</h3>
    <p class="mt2">Hala shall defend, at its sole expense, any third-party claim, demand or suit against the Customer alleging and/or arising out of the Solution infringes a third party’s patent, copyright, trademark, trade secret or other intellectual property right. Hala shall indemnify and hold the Customer harmless from and against any and all losses, liabilities, damages, fines, penalties, costs, expenses and/or fees (including reasonable attorneys’ fees) incurred by or awarded or assessed against the Customer in connection with the third party’s patent, copyright, trademark, trade secret or other intellectual property right, or reached through a negotiated settlement of the claim.</p>
    <p>In addition to Hala’s obligations under this section, if the Solution is held to be infringing or to violate any third party’s patent, copyright, trademark, trade secret or other intellectual property right, or Hala believes that the Solution is likely to be held as infringing or to violate of any third party’s rights, then Hala shall either, at its sole expense: (a) procure for the Customer the right to continue receiving and/or using the Solution; or (b) modify the software solution so that it is non-infringing or non-violative of the third party’s rights, but maintains substantially the same functionality and characteristics.</p>
    <h3 class="mb2">Applicable law</h3>
    <p class="mt2">The Agreement will be governed by laws of Republic of Estonia.</p>
    <h3 class="mb2">Severability and waiver</h3>
    <p class="mt2">Nullity, lapse, the lack of binding or unenforceability of one or any of the provisions of the Agreement shall not constitute nullity, lapse, no binding or unenforceability of any other provision, which retain their full effect. However, the Parties may, by mutual agreement, agree to replace invalid stipulations. Any waiver of a Party’s right or remedy related to this Agreement must be in writing, signed by that Party to be effective. No waiver shall be implied from a failure of either Party to exercise a right or remedy. In addition, no waiver of a Party's right or remedy will affect the other provisions of this Agreement.</p>
    <h3 class="mb2">Liability</h3>
    <p class="mt2">Hala is not liable for any loss of operations, loss of profits, corruption or loss of messages, loss of expected savings or earnings, the Customer's costs of examination and rectification of damage or a defect, the Customer's internal hours spent, third-party expenses, interest expenses, lost interest income, loss of goodwill, indirect losses and consequential claims. Loss and corruption of data will be regarded as an indirect loss.</p>
    <p>Except as expressly provided herein, in no event shall either Party be liable to the other Party in connection with this Agreement and/or the Solution, regardless of the form of action or theory of recovery, for any:</p>
    <ol type="a">
      <li>indirect, special, exemplary, consequential, incidental or punitive damages, even if that Party has been advised of the possibility of such damages;</li>
      <li>lost profits, lost revenues, lost business expectancy, business interruption losses and/or benefit of the bargain damages; and/or</li>
      <li>direct damages in an amount in excess of the amounts paid to Hala under this Agreement during the twelve (12) month period immediately preceding the event giving rise to the claim.</li>
    </ol>
    <p>Notwithstanding the foregoing, the limitations set forth in this section shall not apply to:</p>
    <ol type="i">
      <li>Hala’s indemnification obligations under this Agreement;</li>
      <li>either Party’s breach of its confidentiality obligations under this Agreement; and/or</li>
      <li>either Party’s infringement, misappropriation or violation of the other Party’s intellectual property rights.</li>
    </ol>
    <p>Any claim arising out of this Agreement must be initiated within three (3) months of the date the Party knew, or reasonably should have known, of the existence of such claim against the other Party.</p>
    <h3 class="mb2">Force majeure</h3>
    <p class="mt2">The Parties shall not be held liable for any damage caused by the interruption or deterioration of telecommunications or electricity services; nor in case of Force majeure. If either Party hereto is prevented in the performance of any act required hereunder by reason of Acts of God, fire, flood, or other natural disaster, malicious injury, strikes, lock-outs, or shortages of labour, riots, insurrection, war or other reason of like nature not the fault of the party in performing under this Contract, then:</p>
    <ul>
      <li>performance of such act shall be excused for the period of the delay; and</li>
      <li>the period for the performance of any such act shall be extended for a period equivalent to the period of such delay; except if any delay exceeds three (3) months, then the party entitled to such performance shall have the option to terminate this Contract for cause.</li>
    </ul>
    <p>The Party taking notice of such event shall promptly notify the other party of its inability to perform the work.</p>
    <h3 class="mb2">Termination</h3>
    <p class="mt2">In the case of a breach of the Agreement, the Customer will be entitled to terminate this Agreement with immediate effect only in the event of a significant delay, which means that the final deliverable is not delivered within 30 working days after the final delivery date set out in Appendix 2 and this delay is attributable to Hala.</p>
    <p>Upon termination of this Agreement, the Customer’s license to use the Solution will terminate as well. With regard to software run by the Customer, the Customer must remove all copies from its hardware and return the software and any delivered documentation to Hala. Any back-ups must be physically destroyed.</p>
    <p>In the event of termination of the Agreement, Hala will disable the Customer’s access to the Solution. Subject to the terms of this Agreement, all provisions of this Agreement relating to ownership, limitations of liability, confidentiality and indemnification shall survive termination of this Agreement.</p>
    <h3 class="mb2">Notices</h3>
    <p class="mt2">All notices must be in writing and sent to the individual who executed this Agreement on the other Party’s behalf, either by hand delivery; messenger; certified mail, return receipt requested; overnight courier; or by e-mail (with a confirming copy by regular mail) and shall be effective when received by such Party at the address listed herein or other address provided in writing.</p>
    <h3 class="mb2">Effectiveness</h3>
    <p class="mt2">The Agreement shall be valid during the Usage of the service.</p>
    <h3 class="mb2">Confirmation required</h3>
    <p class="mt2">Please confirm that you've read and agree with our terms:</p>
  </div>`
}

class LegalInformation extends Component {

  render() {
    log.debug('render');
    return (
      <div className="main-content">
        <div className="pa4 scroll-y">
          <div className="ph2">
            <div className="lh-copy">
              <p className="f6">
                <Link to="/" className="link f6">← Back to Chat</Link>
              </p>
            </div>
            <div dangerouslySetInnerHTML={html}>
            </div>
          </div>
        </div>
        <div className="pa2 bt black-10 bg-white">
          <div className="mh4 mv2">
            <Link to="/" className="dib btn btn-blue pv1 ph2 f5">Confirm</Link>
          </div>
        </div>
      </div>
    );
  }
}

LegalInformation.propTypes = {
}

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  {}
)(LegalInformation);

import React from 'react';
import Amplify from '@aws-amplify/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { changeFilter } from '../../../actions/liveAgent';
import t from '../../../i18';
import FilterBadge from '../FilterBadge';
import {
    LA_filterOnline,
    LA_filterAssigned,
    LA_filterNotAssigned
    // LA_filterClosed
} from '../../../constants';

const log = new Amplify.Logger(':::LiveAgent:::Filter:::');

const statuses = [{
    name: 'statuses.online',
    key: LA_filterOnline,
    badge: FilterBadge(LA_filterOnline)
}, {
    name: 'statuses.assignedToMe',
    key: LA_filterAssigned,
    badge: FilterBadge(LA_filterAssigned)
},{
    name: 'statuses.notAssigned',
    key: LA_filterNotAssigned,
    badge: FilterBadge(LA_filterNotAssigned)
}
// ,{
//     name: 'statuses.closed',
//     key: LA_filterClosed,
//     badge: null
// }
];

function Filter({ value, changeFilter }) {
    log.debug('render');
    return (
        <Row className="requests-filter" type="flex">   
            {
                statuses.map(el => (
                    <Col 
                        key={el.key} 
                        className={el.key === value ? 'active' : null}
                        onClick={() => changeFilter(el.key)}
                    >
                        <div className="name">
                            {
                                el.badge
                                ? <el.badge/>
                                : null
                            }
                            <span>{t(el.name)}</span>
                        </div>
                    </Col>
                ))
            }
        </Row>
    );
}

Filter.propTypes = {
    value: PropTypes.string.isRequired,
    changeFilter: PropTypes.func.isRequired
}

export default connect(
    state => ({
        value: state.liveAgent.filter
    }),
    { changeFilter }
)(Filter);
import React, { memo } from 'react';
import Amplify from '@aws-amplify/core';
import { Icon } from 'antd';
import "./index.sass";

const log = new Amplify.Logger(':::ItemAction:::');

export default memo(() => {
    log.debug('render');

    return (
        <div className="item-action">
            <Icon type="plus" />
        </div>
    )
})
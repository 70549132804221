import {
    CHANGE_VIEW,
    CLOSE_SIDE_CONTENT,
    CLEARING_HISTORY_START,
    SET_USER_INFO,
    CHANGE_SIDE_VIEW,
    LA_setCurrentChat
} from '../constants';

const initialState = {
    sidePosition: null,
    sideContent: null,
    mainContent: null,
    closeIcon: false,
    props: {},
    roleContent: {}
};

const configs = (state = initialState, { type, data, authData }) => {
    switch(type){
        case SET_USER_INFO:
            let roleContent = {};
            if (authData && authData.roles) {
                authData.roles.forEach(role => {
                    switch (role) {
                        case "LiveAgent":
                            roleContent = {
                                sideContent: 'liveAgent',
                                sidePosition: 'left'
                            };
                            break;
                        default:
                            return;
                    }
                });
            }
            return {
                ...state,
                ...roleContent,
                roleContent
            };
        case CHANGE_VIEW: {
            const mainContent = data && data.mainContent ? data.mainContent : null;
            const sideContent = mainContent ? 'chat' : (data && data.sideContent ? data.sideContent : null);
            const roleContent = state.roleContent; 
            return {
                ...state,
                mainContent: mainContent,
                sideContent: sideContent,
                sidePosition: sideContent ? (data.sidePosition || 'right') : null,
                closeIcon: data.closeIcon || false,
                props: data.props || {},
                ...(sideContent ? {} : roleContent)
            };
        }
        case CHANGE_SIDE_VIEW: {
            return {
                ...state,
                sideContent: data
            };
        }
        case LA_setCurrentChat: {
            if (state.sideContent === 'liveAgent' && state.mainContent !== null) {
                return {
                    ...state,
                    sideContent: 'chat'
                };
            }
            return state;
        }
        case CLEARING_HISTORY_START:
        case CLOSE_SIDE_CONTENT: {
            const roleContent = state.roleContent; 
            return {
                ...initialState,
                ...roleContent,
                roleContent
            };
        }
            
        default:
            return state;
    }
}

export default configs;
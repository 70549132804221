import React , { useMemo } from 'react';
import Amplify from '@aws-amplify/core';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import "./index.sass";
import t from '../../../i18';

const log = new Amplify.Logger(':::Description:::');
const { Description } = DescriptionList;
const _keys = [{
    key: "itemId",
    label: t('cart.itemId')
},{
    key: "categoryName",
    label: t('cart.category')
},{
    key: "reason",
    label: t('cart.reason')
},{
    key: "segment",
    label: t('cart.segment')
},{
    key: "department",
    label: t('cart.department')
},{
    key: "departmentId",
    label: t('cart.departmentId')
},{
    key: "supplier",
    label: t('cart.supplier')
},{
    key: "supplierId",
    label: t('cart.supplierId')
},{
    key: "costCenter",
    label: t('cart.costCenter')
},{
    key: "costCenterId",
    label: t('cart.costCenterId')
},{
    key: "fixedAssetSet",
    label: t('cart.fixedAssetSet')
},{
    key: "fixedAssetSetId",
    label: t('cart.fixedAssetSetId')
}, {
    key: "coupaContractNo",
    label: t('cart.contractNo')
}, {
    key: "coupaContractNoId",
    label: t('cart.contractNoId')
}, {
    key: "description",
    label: t('cart.description')
}];

export default React.memo(({item, type = "full", children}) => {
    log.debug('render'); 
    const keys = useMemo(() => type === "short" ? _keys.slice(0,2) : _keys, [type]);
    return (
        <DescriptionList size="large" col={2} >
            {
                keys.map((el,k) => (
                    <Description key={`desc${k}`} term={el.label}>{item[el.key] || '-'}</Description>
                ))
            }
            { children }
        </DescriptionList>
    )
});
import React, { memo, useCallback, useState, useEffect, useMemo } from 'react';
import Amplify from '@aws-amplify/core';
import { useMappedState } from 'redux-react-hook';
import { Button, Form, InputNumber, Icon, Input } from 'antd';
import { useSaveItem, useCloseItem } from '../../../actions/purchaseRequisition';
import DataSelector from '../../../components/DataSelector';
import pick from 'lodash/pick';
import t from '../../../i18';
import { useAction } from '../../../actions/fetcher';
import { Tooltip } from 'antd';
import './index.sass';

const { TextArea } = Input;

const log = new Amplify.Logger(':::ProductForm:::');
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    }
};
const _formData = [{
    label: t('cart.segment'),
    action: 'getSegmentList',
    optionName: 'name',
    optionKey: 'id',
    localName: 'segment',
    localKey: 'segmentId',
    info: t('cart.segmentInfo')
},{
    label: t('cart.department'),
    action: 'getDepartmentList',
    optionName: 'description',
    optionKey: 'code',
    localName: 'department',
    localKey: 'departmentId',
    info: t('cart.departmentInfo'),
    required: true
},{
    label: t('cart.supplier'),
    action: 'getSupplierList',
    optionName: 'vendorName',
    optionKey: 'vendorCode',
    localName: 'supplier',
    info: t('cart.supplierInfo'),
    localKey: 'supplierId'
},{
    label: t('cart.costCenter'),
    action: 'getCostCenterList',
    optionName: 'description',
    optionKey: 'code',
    localName: 'costCenter',
    info: t('cart.costCenterInfo'),
    localKey: 'costCenterId'
}, {
    label: t('cart.fixedAssetSet'),
    action: 'getFixedAssetList',
    optionName: 'description',
    optionKey: 'code',
    localName: 'fixedAssetSet',
    localKey: 'fixedAssetSetId'
},{
    label: t('cart.reason'),
    action: 'getReasonList',
    optionName: 'name',
    optionKey: 'id',
    localName: 'reason',
    localKey: 'reasonId',
    info: t('cart.reasonInfo'),
    // infoContent: d => (
    //         <span className="reason-item-info">
    //             <span>
    //                 {t('cart.costOfGoods')}: <b>
    //                 {
    //                     d.costOfGoods
    //                     ? t('yes')
    //                     : t('no')
    //                 }
    //                 </b>
    //             </span>
    //             <span>
    //                 {t('cart.costsValidation')}: <b>
    //                 {
    //                     d.costsValidation
    //                     ? t('yes')
    //                     : t('no')
    //                 }
    //                 </b>
    //             </span>
    //         </span>
    // )
}, {
    label: t('cart.contractNo'),
    action: 'getCoupaContractNo',
    optionName: 'vendorName',
    optionKey: 'contractNo',
    localName: 'coupaContractNo',
    localKey: 'coupaContractNoId',
}];

const initState = {
    reason: '',
    reasonId: '',
    coupaContractNo: '',
    coupaContractNoId: '',
    segment: '',
    segmentId: '',
    department: '',
    departmentId: '',
    supplier: '',
    supplierId: '',
    costCenter: '',
    costCenterId: '',
    fixedAssetSet: '',
    fixedAssetSetId: '',
    qty: 1,
    price: 1,
    description: '',
};
const initKeys = Object.keys(initState);

function getRelevantValues(data = [], key, val) {
    const keys = [];
    const _keys = {};
    data.forEach(el => {
        if (el._id && el._id[key] && !_keys[el._id[key]] && el[val]?.[0]) {
            keys.push({
                key: el._id[key],
                val: el[val][0]
            });
            _keys[el._id[key]] = true;
        }
    });
    return keys.slice(0, 4);
}

function toFixed(price) {
    try {
        if (!price || String(price).endsWith('.')) return price;
        let _price = Number(price);
        if (Number.isNaN(_price)) return 0;
        if (!Number.isInteger(_price)) {
            return Number(_price.toFixed(2))
        }
        return _price;
    } catch (e) {}
    return 0
}

export default memo(() => {
    log.debug('render');
    const mapState = useCallback(state => ({
        actions: state.actions,
        item: state.cart.edit,
        defState: {
            department: state.department.departmentName || '',
            departmentId: state.department.departmentCode || '' 
        }
    }), []);
    let { actions, item, defState } = useMappedState(mapState);

    actions.getFixedAssetList = "5fd727d4a17ea86c5687ae9d";
    actions.getCoupaContractNo = "62d71207a954601e266bc4b1";

    const {department, departmentId} = defState;
    const [state, setValue] = useState(initState);
    const defRelValues = useAction({
        action: item && item.itemId ? actions.getRelevantValues : null,
        fetch: true,
        params: item && item.itemId ? {
            itemId: item.itemId
        } : null
    });
    const [params, setParams] = useState(null);
    useEffect(() => {
        const cb = () => false;
        if (!item || !item.itemId) return cb;
        let cr = {
            itemId: item.itemId
        };
        ['coupaContractNoId', 'reasonId', 'segmentId', 'departmentId', 'supplierId', 'costCenterId', 'fixedAssetSetId']
        .forEach(k => {
            if (state[k]) cr[k] = state[k];
        });
        const n = Object.keys(cr).length;
        if (n > 1) setParams(cr);
    }, [state, item]);
    const liveRelValues = useAction({
        action: params ? actions.getRelevantValues : null,
        fetch: true,
        params: params ? params : null
    }); 
    const relValues = useMemo(() => 
        liveRelValues.data.concat(defRelValues.data)
    ,[liveRelValues, defRelValues]);

    const { 
        mandatoryReason, 
        mandatoryCostCenter,
        mandatorySegment,
        mandatoryFixedAssetSet,
    } = item || {};
    const formData = useMemo(() => {
        if (
            !mandatoryReason && !mandatoryCostCenter
            && !mandatorySegment && !mandatoryFixedAssetSet
        ) {
            return [..._formData];
        }
        return _formData.map(el => {
            switch(el.localKey) {
                case "fixedAssetSetId":
                    el.required = mandatoryFixedAssetSet?.toLowerCase() === 'yes';
                    break;
                case "reasonId":
                    el.required = mandatoryReason.toLowerCase() === 'yes';
                    break;
                case "costCenterId":
                    el.required = mandatoryCostCenter.toLowerCase() === 'yes';
                    break;
                case "segmentId":
                    el.required = mandatorySegment.toLowerCase() === 'yes';
                    break;
                default:
                    break;
            }
            return el;
        })
    }, [
        mandatoryReason, mandatoryCostCenter,
        mandatorySegment, mandatoryFixedAssetSet
    ]);

    const saveVal = useCallback(v => setValue({...state, ...v}), [state]);
    const closeForm = useCloseItem();
    const saveItem = useSaveItem();    
    const onSubmit = useCallback(() => {
        let isValid = true;
        formData.forEach(el => {
            if (el.required && !state[el.localKey]) {
                isValid = false;
            } 
        });
        if (state.qty < 1) isValid = false;
        saveItem({
            ...item,
            ...state,
            isValid
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps    
    },[item, state, formData]);

    useEffect(() => {
        const _def = Object.assign({}, initState, {department, departmentId});
        setParams(null);
        setValue(
            item 
            ? Object.assign(_def, pick(item, initKeys))
            : _def
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, department, departmentId]);

    const onChangePrice = useCallback((price) => {
        saveVal({price: price ? toFixed(price) : 0})
    }, [saveVal]);

    if (!item) return null;

    return (
        <div className="purchase-item-form">
            <h3>{item.nameAlias}</h3>
            <Form>
                {
                    formData.map((el,k) => (
                        <Form.Item 
                            key={k} 
                            {...formItemLayout} 
                            label={el.label}
                            required={el.required}
                            className="form-item"
                        >
                            <DataSelector 
                                value={{
                                    [el.optionKey]: state[el.localKey],
                                    [el.optionName]: state[el.localName],
                                }} 
                                onChange={v => saveVal({
                                    [el.localKey]: v ? v[el.optionKey] : '',
                                    [el.localName]: v ? v[el.optionName] : '',
                                })}
                                action={actions[el.action]}
                                optionName={el.optionName}
                                optionKey={el.optionKey}
                                infoContent={el.infoContent}
                                item={item}
                            />
                            {el.info ? <Tooltip className="form-info" title={el.info}>
                                <Icon type="info-circle"/>
                            </Tooltip> : null}
                            <div className="relevantValues">
                            {
                                relValues.length
                                ? getRelevantValues(relValues, el.localKey, el.localName).map((v, k) => (
                                    <Tooltip key={k} placement="bottom" title={`${v.val}(${v.key})`}>
                                    <div
                                    onClick={() => saveVal({
                                        [el.localKey]: v.key,
                                        [el.localName]: v.val
                                    })}
                                    className="btn btn-ghost ellipsis">
                                        {v.val}
                                    </div>
                                    </Tooltip>
                                ))
                                : null
                            }
                            </div>
                            
                        </Form.Item>
                    ))
                }
                <Form.Item         
                    {...formItemLayout} 
                    label={t('cart.description')}
                    className="form-item"
                >
                    <TextArea
                        rows={1}
                        autoSize
                        value={state.description}
                        onChange={e => saveVal({ description: e?.target?.value })} 
                    />
                    <Tooltip className="form-info" title={t('cart.descriptionInfo')}>
                        <Icon type="info-circle"/>
                    </Tooltip>
                    <div style={{fontSize: "14px"}}>Tekst, mida tarnija näeb ostutellimusel</div>
                </Form.Item>
                <Form.Item required={true} className="form-price-with-q" {...formItemLayout} label={t('cart.quantity')}>
                    <InputNumber 
                        value={state.qty} 
                        onChange={qty => saveVal({qty: qty ? qty : 1})} 
                        decimalSeparator=","
                        parser={value => value.replace(/€\s?|(,*)/g, '')}
                        min={1}
                    />
                    <Form.Item
                        label={t('cart.price')}
                        className="form-item"
                    >
                        <InputNumber 
                            value={state.price} 
                            onChange={onChangePrice} 
                            decimalSeparator=","
                            formatter={value => `€ ${toFixed(value)}`}
                            parser={value => value.replace(/€\s?|(,*)/g, '')}
                            min={0}
                            style={{ width: "230px" }}
                        />
                        <Tooltip className="form-info" title={t('cart.priceInfo')}>
                            <Icon type="info-circle"/>
                        </Tooltip>
                        <div style={{fontSize: "14px", width: "230px"}}>Eurost erineva valuuta täpsustus märgi järgmisel sammul "kirjeldus ostuosakonnale" väljale</div>
                    </Form.Item>
                </Form.Item>
                <div className="requiredInfo">
                    <span>*</span>
                    <span>{t('cart.requiredInfo')}</span>
                </div>
                <Button key="cancel" className="btn" onClick={closeForm}>{t('cancel')}</Button>
                <Button key="submit" className="btn btn-blue" type="primary" onClick={onSubmit}>
                  {item.cartId ? t('edit') : t('add')}
                </Button>
            </Form>
        </div>
    );
});
import React from 'react';
import { DatePicker } from 'antd';
import 'antd/lib/date-picker/style/index.css';

const DatePickerInput = ({field, getFieldDecorator, errorMessage, }) => {
    return (
        <div className="mt3">
                <label className="b f6">{field.label}:</label>
                {   field.optimal && JSON.parse(field.optimal) 
                    ? (<span className="fr f6 o-60">Optional</span>) 
                    : null
                }
                {
                    getFieldDecorator(field.key, {
                        rules: [{ type: 'object', required: true, message: 'Please select time!' }],
                        validateTrigger: "onChange",
                        onChange: getFieldDecorator.handleInputChange,
                    })(<DatePicker type={field.type}  className="w-100 mt1 pv2 ph2 f5"/>)
                }
                {
                    errorMessage(field.key)
                }
            </div>
    );
};

export default DatePickerInput;
import {
    LA_filterOnline,
    LA_filterAssigned,
    LA_filterNotAssigned
} from '../../constants';

export function getRequestsByFilter(items, filter) {
    switch (filter) {
        case LA_filterOnline:
            return items.filter(i => i.online);       
        case LA_filterAssigned:
            return items.filter(i => i.agent && i.id !== 'hala');
        case LA_filterNotAssigned:
            return items.filter(i => !i.agent && i.id !== 'hala');
        default:
            return [];
    }
}

export function getRequestById(state, id) {
    return state.liveAgent.requests.items[state.liveAgent.requests.ids[id]] || {};
}
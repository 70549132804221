import {
    CLOSE_SIDE_CONTENT,
    CHANGE_VIEW,
    CHANGE_SIDE_VIEW
} from '../constants';

export const closeSideContent = () => ({
    type: CLOSE_SIDE_CONTENT
});

export const setSideContent = data => ({
    type: CHANGE_VIEW,
    data: data
});

export const setSideView = view => ({
    type: CHANGE_SIDE_VIEW,
    data: view
});

export const liveAgent = () => setSideContent({
    sideContent: 'liveAgent',
    sidePosition: 'left'
});
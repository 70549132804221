import React, {
    memo, useCallback, useRef
} from 'react';
import cn from 'classnames';
import { useDispatch } from 'redux-react-hook';
import { typePRAttachmentInput } from '../../constants';

import './index.sass';

function FileUploader({ title, disabled }) {
    const input = useRef();
    const dispatch = useDispatch();   
    const handleInput = useCallback(e => {
        if (!e.target.files) return;
        dispatch({
            type: typePRAttachmentInput,
            payload: [...e.target.files],
        })
        input.current.value = '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={cn("file-uploader", { disabled })}>
            <div className="uploader-content">
                <div className="picker-wrap">
                    <button disabled={disabled} type="button" className="ant-btn btn">
                        {title}
                    </button>
                    {
                        disabled
                            ? null
                            : <input ref={input} multiple className="file-input" type="file" onChange={handleInput} />
                    }
                </div>
            </div>
        </div>
    )
}

export default memo(FileUploader);
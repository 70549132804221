import React, { memo, useCallback, useMemo, useState, useRef, useEffect } from 'react';
import Amplify from '@aws-amplify/core';
import { useNextPage, execAction } from '../../../actions/fetcher';
import { useMappedState, useDispatch } from 'redux-react-hook';
import './index.sass';
import {
    List, 
    Avatar, 
    Skeleton,
    Tag,
    Dropdown,
    Menu,
    Icon,
    Descriptions
} from 'antd';
import InfiniteItemsList from '../../../components/InfiniteItemsList';
import { useEditOrder, useDeleteOrder } from '../../../actions/purchaseRequisition';
import moment from 'moment';
import { MdMoreVert } from "react-icons/md";
import classnames from 'classnames';
import t from '../../../i18';
import {
    purchaseFetchOrders,
    purchaseFetchOrdersSuccess,
    purchaseOrdersClean
} from '../../../constants';
import { sumTotal } from '../../../reducers/purchaseRequisition/postReducer';

const log = new Amplify.Logger(':::OrdersList:::');

const OrderItemAction = memo(({item}) => {
    log.debug('render OrderItemAction');
    const [visible, setVisible] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const ref = useRef(null);
    const editOrder = useEditOrder(item);
    const deleteOrder = useDeleteOrder(item);
    const onConfirm = useCallback((bool) => {
        setConfirm(false);
        if (bool) deleteOrder();
    },[deleteOrder]);
    const onClick = useCallback(e => {
        if (e.domEvent) {
            e.domEvent.stopPropagation();
            e.domEvent.preventDefault();
            switch(e.key) {
                case 'delete':
                    setConfirm(true);
                    break;
                case 'edit':
                    editOrder();
                    break;
                default:
                    break;
            }
        } else {
            e.stopPropagation();
            e.preventDefault();
        }
    }, [editOrder]);

    const onVisibleChange = useCallback(v => {
        setVisible(v);
        if (!v) setConfirm(false);
    },[]);

    const menu = useMemo(() => (
        <Menu onClick={onClick}>
            <Menu.Item key="edit"><Icon type="info-circle" />{t('details')}</Menu.Item>
            {
                item.status === 'draft' 
                ? <Menu.Item key="delete"><Icon type="delete" />{t('delete')}</Menu.Item>
                : null
            }
        </Menu>
    ),[item, onClick]);

    const confirmation = useMemo(() => (
        <div  onClick={onClick} className="dropdown-confirmation">
            <div>{t('areYouSure')}</div>
            <div className="link-st" onClick={() => onConfirm(true)}>{t('yes')}</div>
            <div className="link-st" onClick={() => onConfirm(false)}>{t('no')}</div>
        </div>
    ),[onConfirm, onClick]);

    return (
        <div className="item-action" ref={ref}>
            <Dropdown 
                onClick={onClick} 
                overlay={confirm ? confirmation : menu} 
                placement="bottomRight"
                onVisibleChange={onVisibleChange}
                visible={visible}
                getPopupContainer={ref.current ? () => ref.current : null}
            >
                <MdMoreVert className={classnames("anticon icon-more", {
                    active: visible
                })}/>
            </Dropdown>
        </div>
    )
})

export default memo(() => {
    log.debug('render');
    const [hasMore, setHasMore] = useState(true);
    const dispatch = useDispatch();
    const mapState = useCallback(
        state => ({
            search: state.ordersFilter.search,
            page: state.ordersFilter.page,
            limit: state.ordersFilter.limit,
            data: state.orders.items,
            loading: state.orders.loading,
            action: state.actions.getOrders
        }),
        []
    );
    const { search, page, limit, data, loading, action } = useMappedState(mapState);

    useEffect(() => {
        let cb = _data => {
            dispatch({type: purchaseFetchOrdersSuccess, payload: _data});
            const _hasMore = !(limit && _data.length < limit);
            if (hasMore !== _hasMore) setHasMore(_hasMore);
        };
        dispatch({type: purchaseFetchOrders});
        dispatch(execAction({
            action,
            params: { search, page, limit }
        }, false))
        .then(_data => cb(_data));
        return () => cb = () => null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page, limit]);

    useEffect(() => {
        return () => dispatch({type: purchaseOrdersClean});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadMore = useNextPage('orders');
    const editOrder = useEditOrder();

    return (<InfiniteItemsList 
        className="orders-list"
        data={data} 
        loading={loading} 
        loadMore={loadMore} 
        hasMore={hasMore}
        renderItem={item => 
            <List.Item key={item.id} onClick={item.loading?null:editOrder(item)} actions={item.loading ? null : [<OrderItemAction key={item.id} item={item} />]}>
                <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                        avatar={<Avatar></Avatar>}
                        description={`${t('createdOn')} ${moment(item.createdAt).format('MMMM Do YYYY, H:mm:ss')}`}
                        title={item.description}
                    />
                    <Descriptions>
                        <Descriptions.Item span={3} label={t('cart.requesterName')}>{
                            item.userData
                            ? `${item.userData.firstName} ${item.userData.lastName}`
                            : null
                        }</Descriptions.Item>
                        <Descriptions.Item span={3} label={t('cart.requisitionId')}>
                            <strong>{item.reqId || '-'}</strong>
                        </Descriptions.Item>
                    </Descriptions>
                    <div className="item-status">
                        <Tag color={item.status === 'sended'?"#87d068":"#0099ff"}>{t(item.status)}</Tag>
                    </div>
                    <div className="item-price">
                        {
                            item.items && item.items.length 
                            ? `${sumTotal(item.items)} ${item.items[0].currency}`
                            : '0 EUR'
                        }
                    </div>
                </Skeleton>
            </List.Item>
        }/>);
});
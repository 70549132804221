import React, { memo, useCallback } from 'react';
import FileUploader from '../../../components/FileUploader';
import t from '../../../i18';
import { useMappedState } from 'redux-react-hook';
import File from '../../../components/FileUploader/File';

function PurchaseAttachment() {
    const { file, files, disabled } = useMappedState(
        useCallback(
            state => ({
                file: state.cartInfo.header.file,
                files: state.cartInfo.files,
                disabled: state.cartInfo.status === 'sended'
                    || (
                        state.cartInfo.isLiveAgent
                        && state.cartInfo.createdBy
                        && state.cartInfo.currentUser !== state.cartInfo.createdBy
                    )
            }),
            []
        )
    );

    return (
        <>
            {file ? <File disabled={disabled} file={file} /> : null}
            {files.map(file => <File 
                disabled={disabled} 
                file={file} 
                key={file.id || file.opId}
            />)}
            <FileUploader
                title={t('cart.attachFileBtn')}
                disabled={disabled}
            />
        </>
    )
}

export default memo(PurchaseAttachment);
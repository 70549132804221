import React, { memo, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdInformationCircleOutline
} from "react-icons/io";
import Spinner from '../Spinner';
import { Storage } from 'aws-amplify';
import FileSize from './FileSize';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { typePRAttachmentUpload, typePRAttachmentDel } from '../../constants';
import { removeAttachment } from '../../actions/purchaseRequisition';
import uuid from 'uuid/v1';

function getFileExtension(name) {
  if (!name) return null;
  return name.split('.').pop();
}

async function uploadToS3(file) {
  return Storage.put(`${uuid()}.${getFileExtension(file.name)}`, file, {
      level: 'private',
      bucket: 'telia-hala'
  }).then(result => result.key);
}

async function deleteFromS3(fileId) {
  return Storage.remove(fileId, {
    level: 'private',
    bucket: 'telia-hala'
  });
}

function onLoad(file) {
  if (!file?.id) return;
  let params = {
    level: 'private',
    bucket: 'telia-hala'
  };
  if (file.identityId) {
    params.identityId = file.identityId;
  }
  Storage.get(file.id, params)
    .then(link => window.open(link, '_blank'));
}

function File({ file, disabled }) {
  const dispatch = useDispatch();
  const { prId } = useMappedState(
    useCallback(
      state => ({ prId: state.cartInfo.id || null }),
      []
    )
  );
  const [{ loading, error, tId }, setState] = useState({
    error: null,
    loading: false,
  });

  const { id, opId } = file;

  useEffect(() => {
    if (opId) {
      setState({ loading: true, error: null });
      uploadToS3(file)
        .then((id) => {
          setState({ loading: false, error: null });
          dispatch({
            type: typePRAttachmentUpload,
            payload: {
              opId,
              name: file.name,
              size: file.size,
              id
            }
          });
        })
        .catch((err) => {
          setState({ loading: false, error: err.message });
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opId]);

  const onDelete = useCallback(async () => {
    if (!id) return;
    setState({ error: null, loading: true });
    try {
      await deleteFromS3(id);
      if (prId) await removeAttachment(prId, id);
      setState({ 
        error: null, 
        loading: false,
        tId: setTimeout(() => {
          dispatch({
            type: typePRAttachmentDel,
            payload: id
          })
        }, 1100)
      });
    } catch (err) {
      setState({ error: err.message, loading: false });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [id, prId]);

  const _onLoad = useCallback(() => onLoad(file), [file]);

  useEffect(() => {
    if (tId) return () => clearTimeout(tId);
  }, [tId]);

  return (
    <div className={cn("file-uploader hasFile", {
      error: !!error,
      loading,
    })}>
      <div className="uploader-content">
        <div className="picker-wrap" />
        <div className="file-wrap">
          <div className="file-data">
            <div className="file-name" onClick={_onLoad}>{file.name}</div>
            <div className="file-size" onClick={_onLoad}>
              <FileSize value={file.size} />
            </div>
            <div className="file-icon">
              {loading ? <Spinner /> : null}
              <div className="done">
                <IoMdCheckmark />
                {disabled ? undefined : <IoMdClose onClick={onDelete} />}
              </div>
            </div>
          </div>
          <div className="file-error">
            <IoMdInformationCircleOutline />
            <span>{error}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(File);
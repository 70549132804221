import React, { useMemo, useCallback } from 'react';
import Amplify from '@aws-amplify/core';
import { connect } from 'react-redux';
import { getRequestById } from '../../../reducers/liveAgent/selectors';
import UserMeta, { connectUserMeta } from '../UserMeta';
import PropTypes from 'prop-types';
import OnlineDetails, { connectOnlineDetails } from '../OnlineDetails';
import Avatar from '../../../components/Avatar';
import connectAvatar from '../Avatar';
import { MdMoreVert } from "react-icons/md";
import { closeRequest } from '../../../actions/liveAgent';
import { setSideView } from '../../../actions/view';
import t from '../../../i18';
import './index.sass';

const log = new Amplify.Logger(':::Chat Header:::');

function _AgentHeader({id, actions = null}) {
    log.debug('render AgentHeader');

    const UserMeta = useMemo(() => connectUserMeta(id), [id]);
    const OnlineDetails = useMemo(() => connectOnlineDetails(id), [id]);
    const Avatar = useMemo(() => connectAvatar(id), [id]);

    return (
        <div className="chat-header">
            <UserMeta
                description={<OnlineDetails/>}
                avatar={<Avatar/>}
            />
            { actions }
        </div>
    );
}

_AgentHeader.propTypes = {
    id: PropTypes.string.isRequired
}

const AgentHeader = connect( 
    state => {
        const item = getRequestById(state, state.chat.current);
        return {
            id: item.id
        };
    },
    {}
)(_AgentHeader);


function _UserHeader({agent, closeRequest}) {
    const description = useMemo(() => {
        return <OnlineDetails
            online={agent.online}
            lastLogin={agent.lastLogin}
            lastLogout={agent.lastLogout}
        />;
    }, [agent.online, agent.lastLogin, agent.lastLogout]);

    const avatar = useMemo(() => {
        return <Avatar
            firstName={agent.firstName}
            lastName={agent.lastName}
            online={agent.online}
        />;
    }, [agent.firstName, agent.lastName, agent.online]);

    return (
        <div className="chat-header">
            <UserMeta 
                fullName={`${agent.firstName} ${agent.lastName}`}
                description={description}
                avatar={avatar}
            />
            <button onClick={closeRequest} type="button" className="btn btn-blue">
                {t('liveAgent.closeRequest')}
            </button>
        </div>
    );
}

const UserHeader = connect( 
    state => ({
        agent: state.liveAgent.request.agent
    }),
    { closeRequest }
)(_UserHeader);

function _LAChatSideActions({setSideView}){
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    const onClick = useCallback(() => setSideView('liveAgent'), []);
    return <MdMoreVert className="anticon icon-more" onClick={onClick} />
}

const LAChatSideActions = connect( 
    null,
    { setSideView }
)(_LAChatSideActions);

function ChatHeader({isRequest, isHala, isLAChatIsSide}) {
    if (isHala && isRequest) return <UserHeader/>;
    else if (isLAChatIsSide) return <AgentHeader actions={<LAChatSideActions/>}/>;
    else if (!isHala) return <AgentHeader/>;
    return null;
}

export default connect( 
    state => ({
        isRequest: state.liveAgent.request.agent.id ? true : false,
        isHala: state.chat.current === 'hala',
        isLAChatIsSide: state.view.sideContent === 'chat' 
            && state.auth.user.roles 
            && state.auth.user.roles.indexOf('LiveAgent') !== -1
    }),
    {}
)(ChatHeader);
import { 
    updatePurchaseInfo, 
    purchaseView,
    purchaseViewProducts,
    purchaseViewOrders,
    purchaseInit,
    purchaseClean,
    purchaseEditOrder,
    purchaseViewCart,
    purchaseCreateOrder,
    typePRAttachmentUpload,
    purchaseSaveError,
    purchaseSaveSuccess,
    purchaseSaveRequest,
    typePRAttachmentInput,
    typePRAttachmentDel
} from '../../constants';
import { sumTotal } from './postReducer';
import uuid from 'uuid/v1';

const init = {
    header: {
        date: null,
        description: '',
        notes: '',
        file: null
    },
    loading: false,
    id: null,
    createdBy: null,
    currentUser: null,
    status: null,
    submitDate: null,
    count: 0,
    total: 0,
    hasChanges: false,
    view: purchaseViewProducts,
    isLiveAgent: false,
    files: [],
};

export default (state = init, { type, payload }) => {
    switch(type) {
        case purchaseSaveError:
        case purchaseSaveSuccess:
            return {
                ...state,
                loading: false
            };
        case purchaseSaveRequest:
            return {
                ...state,
                loading: true
            };
        case typePRAttachmentInput: {
            return {
                ...state,
                files: [
                    ...state.files,
                    ...payload.map(file => {
                        file.opId = uuid();
                        return file;
                    })
                ]
            };
        }
        case typePRAttachmentUpload: {
            const { opId, ...newFile } = payload;
            return {
                ...state,
                files: state.files.map(file => {
                    if (file.opId === opId) {
                        return newFile
                    }
                    return file;
                })
            };
        }
        case typePRAttachmentDel:
            return {
                ...state,
                header: {
                    ...state.header,
                    file: state.header.file?.id === payload 
                        ? null
                        : state.header.file
                },
                files: state.files.filter(file => file.id !== payload)
            }
        case purchaseInit:
            return {
                ...init,
                isLiveAgent: payload.isLiveAgent ? true : false,
                currentUser: payload.currentUser,
                view: payload.orders ? purchaseViewOrders : purchaseViewProducts
            };
        case updatePurchaseInfo:
            return {
                ...state,
                hasChanges: true,
                header: {
                    ...state.header,
                    [payload.name]: payload.value
                }
            };
        case purchaseView:
            return {
                ...state,
                view: payload
            };
        case purchaseClean:
        case purchaseCreateOrder:
            return {
                ...init,
                isLiveAgent: state.isLiveAgent,
                currentUser: state.currentUser
            };
        case purchaseEditOrder: 
            return {
                header: {
                    date: payload.date,
                    description: payload.description,
                    notes: payload.notes,
                    file: payload.attachment?.id ? payload.attachment : null
                },
                id: payload.id,
                status: payload.status || null,
                hasChanges: false,
                submitDate: payload.updatedAt,
                count: payload.items.length,
                total: sumTotal(payload.items),
                view: purchaseViewCart,
                isLiveAgent: state.isLiveAgent,
                createdBy: payload.user,
                currentUser: state.currentUser,
                reqId: payload.reqId || null,
                userData: payload.userData || null,
                loading: false,
                files: payload.attachments || []
            };
        default:
            return state;
    }
};
import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const VideoComponent = ({ message: { title, value}}) => (
            <div className="pl2 bl b--black-05 bw3 measure">
                {title ? <h4 className="mb2">{title}</h4> : null}
                <div className="aspect-ratio aspect-ratio--16x9">
                    <ReactPlayer
                        url={value}
                        className="aspect-ratio--object cover"
                        width="560"
                        height="315"
                        controls
                    />
                </div>
                <p className="mt2 mb0"><a rel="noopener noreferrer" target="_blank" href={value} className="link">Open link</a></p>
            </div>
        );

export default VideoComponent;

VideoComponent.propTypes = {
    message: PropTypes.object.isRequired
}

import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import KeyValView from '../KeyValView';
import t from '../../i18';
import './index.sass';

class TableItemDetails extends Component {
    render() {
        const { data, legend } = this.props;
        const id = Date.now();

        return (
            <div className="table-item-details">
                <h3>{t('tableItemDetails.title')}</h3>
                {
                    Object.keys(data).map((key,n) => (
                        <KeyValView 
                            key={`${id}-${n}`}
                            label={legend[key] ? legend[key] : key}
                            value={data[key] || '-'}
                        />
                    ))
                }
            </div>
        );
    }
};

TableItemDetails.propTypes = {
    data: PropTypes.object.isRequired,
    legend: PropTypes.object.isRequired
}

export default TableItemDetails;
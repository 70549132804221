import React, { useMemo } from 'react';
import Amplify from '@aws-amplify/core';
import {
    List, 
    Avatar, 
    Skeleton
} from 'antd';
import Description from '../Description';
import './index.sass';

const log = new Amplify.Logger(':::Item:::');

export default React.memo(({onClick, item, descriptionType, actions = [], children, descriptionChildren}) => {
    log.debug('render'); 

    const price = useMemo(() => {
        if (!item.price) {
            return '0';
        } else {
            return item.price.toString().replace('.', ',');
        }
    }, [item]);

    return (
        <List.Item onClick={onClick} actions={item.itemId ? actions : null}>
            <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                    avatar={<Avatar>{item.nameAlias ? item.nameAlias.charAt(0) : ''}</Avatar>}
                    description={<Description type={descriptionType} item={item} children={descriptionChildren}/>}
                    title={item.nameAlias}
                />
                <div className="item-price">
                    {price} {item.currency}
                </div>
                { children }
            </Skeleton>
        </List.Item>
    );
});
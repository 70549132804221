import { 
    LA_currentRequests,
    userConnected,
    userDisconnected,
    LA_assignRequest,
    LA_assignRequestSuccess,
    LA_assignRequestError,
    LA_requestUpdated,
    LA_newRequest,
    SIGNED_OUT,
    DISCONNECTED
} from '../../constants';

const init = {
    items: [],
    ids: {}
};

function setOnline(state, payload, online) {
    return {
        ...state,
        items: state.items.map(el => {
            if (el.user === payload.id) {
                return {
                    ...el,
                    firstName: payload.firstName || el.firstName,
                    lastName: payload.lastName || el.lastName,
                    lastLogout: payload.lastLogout || el.lastLogout,
                    lastLogin: payload.lastLogin || el.lastLogin,
                    online
                };
            }
            return el;
        })
    };
}

function mapItems(state, fn) {
    return {
        ...state,
        items: state.items.map(fn)
    };
}

function setloading(state, id, loading) {
    return mapItems(state, el => {
        if (el.id === id) {
            return {
                ...el,
                loading
            };
        }
        return el;
    });
}

function requestUpdated(state, { id, agent, status, currentUser }) {
    let items = state.items.map(el => {
        if (el.id === id) {
            return {
                ...el,
                agent,
                status
            };
        }
        return el;
    });
    items = items.filter(el => {
        if (el.id === id) {
            if (agent && agent !== currentUser) {
                return false;
            } else if (['closed','canceled', 'timeout'].indexOf(status) !== -1) {
                return false;
            }
        }
        return true;
    });
    return {
        ...state,
        items
    };
}

const halaData = {
    id: 'hala',
    user: 'hala',
    firstName: 'Hala',
    lastName: '',
    current: true,
    agent: 'hala',
    logo: 'assets/img/hala-logo-white.svg',
    description: '',
    online: true
};

const agentRequests = (state = init, {type, payload}) => {
    switch(type){
        case LA_newRequest:
            state.items.splice(1, 0, payload);
            return {
                ...state,
                items: [
                    ...state.items
                ]
            };
        case LA_requestUpdated:
            return requestUpdated(state, payload);
        case LA_assignRequest:
            return setloading(state, payload, true);
        case LA_assignRequestSuccess:
        case LA_assignRequestError:
            return setloading(state, payload.id, false);
        case userConnected:
            return setOnline(state, payload, true);
        case userDisconnected:
            return setOnline(state, payload, false);
        case LA_currentRequests:
            payload.unshift(halaData);
            return {
                ...state,
                items: payload
            };
        case SIGNED_OUT:
        case DISCONNECTED:
            return init;
        default:
            return state;
    }
};

export default agentRequests;
import { memo, useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useAction } from '../../../actions/fetcher';

export default memo(({ department, action }) => {
    const {departmentCode, departmentName} = department || {};
    const dispatch = useDispatch();

    const { data, fetched } = useAction({ 
        action,
        fetch: departmentName ? true : false, 
        params: {
            search: departmentName
        }
    });

    let isExist = true;
    if (data && data.length) {
        isExist = data.find(({code, description}) => code === departmentCode && description === departmentName) ? true : false;  
    }

    useEffect(() => {
        if (fetched && !isExist) {
            dispatch({type: 'invalidDepartment'});
        }
    }, [fetched, isExist, dispatch]);
    return null;
});
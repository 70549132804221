import React from 'react';
import PropTypes from 'prop-types';
import FieldDescription from './FieldDescription';
import './TextInputField.sass';
import { Input } from 'antd';

class TextInputField extends React.Component {

    setFieldValue = (field, s) => {
        this.props.setFields({
            [field]: {
              value: s,
              errors: [],
            },
          });
    }

    render(){
        const { field, getFieldDecorator, errorMessage } = this.props;
        let { suggestion, value } = field, options = [];
        
        if (suggestion && suggestion.options && suggestion.options.length) {
            suggestion.options.map(el => {
                if (el && options.indexOf(el) === -1) {
                    options.push(el);
                }
                return false;
            });
        }
        return(
            <div className="mt3">
                <label className="b f6">{field.label}:</label>
                {   field.optimal && JSON.parse(field.optimal) 
                    ? (<span className="fr f6 o-60">Optional</span>) 
                    : null
                }
                {
                    getFieldDecorator(field.key, {...field.validationOptions,
                        validateTrigger: "onChange",
                        onChange: getFieldDecorator.handleInputChange,
                        initialValue: value || null
                    })(<Input type={field.type}  className="w-100 mt1 pv2 ph2 f5"/>)
                }
                {
                    (field.suggestion && options.length )? (
                        <div className="mt1 f6 o-60 field-suggestions"><span>{field.suggestion.title}</span>
                        {
                            options.map( (o,i) => (
                                <span key={`sugg-${i}`}>
                                    {i===0 ? ` ` : ` | `}
                                    <strong onClick={() => this.setFieldValue(field.key, o)}>
                                        {o}
                                    </strong>
                                </span>
                            ))
                        }
                        </div>)
                    : null
                }
                {
                    field.description ? <FieldDescription description={field.description} />
                    : null
                }
                {
                    errorMessage(field.key)
                }
            </div>
        );
    }
}

export default TextInputField;

TextInputField.propTypes = {
    field: PropTypes.object.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    errorMessage: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
}

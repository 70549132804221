import { 
    TOGGLE_SPEECH_TO_TEXT,
    TOGGLE_TEXT_TO_SPEECH,
    WELCOME_MESSAGE,
    SHOW_INPUT
} from '../constants';
import { 
    initContext, 
    closeContext,
    unlockContext
} from '../services/audio';
import { 
    stopRecording, 
    getMediaStream
} from '../services/recorder';
import { sendAudioStream } from './socket';

if (localStorage.getItem('textToSpeech') === 'true' ) {
    unlockContext();
}

export const toggleSpeechToText = data => {
    return async dispatch => {
        dispatch({
            type: TOGGLE_SPEECH_TO_TEXT,
            data
        });
        if (data === true) {
            sendAudioStream(await getMediaStream());
            dispatch({
                type: TOGGLE_SPEECH_TO_TEXT,
                data: false
            });
        } else stopRecording();
    };
}

export const toggleTextToSpeech = data => {
    if (data === false) closeContext();
    else if (data === true) initContext();
    localStorage.setItem('textToSpeech', data); 
    return {
        type: TOGGLE_TEXT_TO_SPEECH,
        data
    }
}

export const onWelcomeMessage = () => (
    async dispatch => {
        dispatch({
            type: WELCOME_MESSAGE
        });
    }
)

export const showInput = () => ({
    type: SHOW_INPUT,
    data: true
});

export const hideInput = () => ({
    type: SHOW_INPUT,
    data: false
});
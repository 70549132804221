import React from 'react';
import * as d3 from 'd3';

import './Chart.sass';

const barsSize = {
  t: 60,
  r: 0,
  b: 30,
  l: 0
};
barsSize.w = 600 - barsSize.l - barsSize.r;
barsSize.h = 300 - barsSize.t - barsSize.b;

const pieSize = {
  width: 250,
  height: 250
};
pieSize.radius = Math.min(pieSize.width, pieSize.height) / 2;

var elem = document.createElement('textarea');

function toName(v) {
  elem.innerHTML = v;
  return elem.value;
}

const colorsBlue = ['#00bfff','#06b9f2','#09b3e5','#0caed7','#0ca7ca','#0ca1bc','#0b9cb1','#0896a4','#049097','#008b8b'];

const colorsGreen = ['#3cb371','#4ab66c','#58b966','#64bc60','#6fbe59','#79c253','#82c44b','#8bc744','#93ca3b','#9acd32'];

class Chartt {

  constructor(el) {
    this.barData = [];
    this.bars = null;
    this.barsLegendEls = null;
    this.activeBar = null;
    this.root = el;
    this.barsWrap = d3.select(el).append("div")
        .attr("class", "bars-wrap");

    this.barsWrap.append("h4").attr("class", "chart-title").text('Costs by Cost center');

    this.barsChart = this.barsWrap.append("svg")
        .attr("viewBox", "0 0 " + 600 + " " + 300 + "")
        .attr("preserveAspectRatio", "xMidYMid meet")
        .append("g")
        .attr('class', 'bars')
        .attr("transform", "translate(" + barsSize.l + "," + barsSize.t + ")");

    this.barsLegend = this.barsWrap.append("ul").attr('class', 'bars-legend');

    var pieWrap =  this.pieWrap = d3.select(el).append("div")
        .attr("class", "pie-wrap");

    pieWrap.append("h4").attr("class", "chart-title").text('Costs by Cost Element');

    this.pieChart = pieWrap.append("svg")
      .attr("viewBox", "0 0 250 250")
        .append("g")
        .attr("transform", "translate(" + pieSize.width / 2 + "," + pieSize.height / 2 + ")");

    this.pieLegend = pieWrap.append("table").attr('class', 'pie-legend');
    var lTr = this.pieLegend.append("thead").selectAll("tr").data([{}]).enter().append("tr");
    lTr.append("td").text('Color');
    lTr.append("td").text('Name');
    lTr.append("td").text('value');
    lTr.append("td").text('%');

    setTimeout(() => {
      this.root.classList.add('show');
    }, 100);
  }

  clear() {
    return false;
  }


  upData(data) {
    if (!data || !data.length) return this.clear();

    var nData = {};
    
    data.forEach((d, k) => {

      if (!nData[d.KOSTL]) {
        nData[d.KOSTL] = {
          id: d.KOSTL,
          name: toName(d.KTEXT),
          total: 0,
          els: {}
        };
      }

      if (!nData[d.KOSTL].els[d.KSTAR]) {
        nData[d.KOSTL].els[d.KSTAR] = {
          name: toName(d.ETEXT),
          price: d.WTGBTR,
          num: d.KSTAR
        }
      } else nData[d.KOSTL].els[d.KSTAR].price += d.WTGBTR;

      nData[d.KOSTL].total += d.WTGBTR;
    });

    this.barData = [];
    var _maxEls = 0,
        _keys = Object.keys(nData);

    _keys.forEach(k => {
      var n = Object.keys(nData[k].els).length;
      if (n > _maxEls) _maxEls = n;
    });

    _keys.forEach((k, kn) => {
      var els = [],
          _elsK = Object.keys(nData[k].els),
          _n = _elsK.length;

      _elsK.forEach(kk => els.push(nData[k].els[kk]));

      if (_maxEls !== _n) {
        var it = _maxEls - _n;
        for (var i = 0; i < it; i++) {
          els.push({
            price: 0
          });
        }
      }

      this.barData.push({
        name: nData[k].name,
        total: nData[k].total,
        id: k,
        els: els,
        color: colorsBlue[kn]
      });
    });

    this.render();
  }

  renderBars() {
    if (this.bars) this.bars.remove();

    var x = d3.scale
        .ordinal()
        .rangeBands([0, barsSize.w], 0.2, 0)
        .domain(this.barData.map(d => d.name));

    var y = d3.scale
        .linear()
        .range([barsSize.h, 0])
        .domain([0, d3.max(this.barData, d => d.total)]);

    var bars = this.bars = this.barsChart.selectAll(".bar").data(this.barData).enter()
        .append("g").attr("class", d => `bar id${d.id}`);

    bars.append("rect")
        .attr("x", d => x(d.name))
        .attr("y", d => y(d.total))
        .attr("width", x.rangeBand())
        .attr("height", d => barsSize.h - y(d.total))
        .attr('fill', d => d.color)
        .on("click", this.onBarClick.bind(this))
        .on("mouseover", this.onBarHover.bind(this))
        .on("mouseout", this.onBarUnHover.bind(this));

    bars.append("text")
        .text(d => d3.format(",")(d.total))
        .attr("x", d => x(d.name) + x.rangeBand()/2)
        .attr("y", d => y(d.total) - 5)
        .attr("text-anchor", "middle");
  }

  renderBarsLegend() {
    if (this.barsLegendEls) this.barsLegendEls.remove();

    var li = this.barsLegendEls = this.barsLegend
        .selectAll("li")
        .data(this.barData)
        .enter()
        .append("li")
        .attr("class", d => `legend-item id${d.id}`)
        .on("click", this.onBarClick.bind(this))
        .on("mouseover", this.onBarHover.bind(this))
        .on("mouseout", this.onBarUnHover.bind(this));

    li.append("div")
        .attr("class", "legend-color")
        .append("svg").attr("width", '16').attr("height", '16')
        .append("circle").attr("cx", 8).attr("cy", 8).attr("r", 8)
        .attr("fill", d => d.color);

    li.append("div")
        .attr("class", "legend-name")
        .text(d => d.name);
  }

  renderPieChart() {
    this.pieChart.selectAll("path").remove();
    this._arc = d3.svg.arc().outerRadius(pieSize.radius - 10).innerRadius(0);
    this._pie = d3.layout.pie().sort(null).value(d => d.price);
    this.pieChart.selectAll("path").data(this._pie(this.barData[0].els)).enter().append("path").attr("d", this._arc)
        .each(function(d) { this._current = d; })
        .style("fill", (d, k) => colorsGreen[k])
        .attr("class", d => `id${d.data.num}`)
        .on("mouseover", this.onChartHover.bind(this))
        .on("mouseout", this.onChartUnHover.bind(this));
  }

  renderPieLegend(data) {
    if (!data) {
      if (this.activeBar) data = this.activeBar;
      else data = this.barData[0];
    }

    this.pieLegend.selectAll("tbody").remove();
    this.pieLegend.selectAll("tfoot").remove();

    var tr = this.pieLegend.append("tbody").selectAll("tr").data(data.els).enter()
        .append("tr")
        .style('display', d => d.price? null: 'none')
        .attr("class", d => `legend-item id${d.num}`)
        .on("mouseover", this.onChartHover.bind(this))
        .on("mouseout", this.onChartUnHover.bind(this));

    tr.append("td").attr("class",'legend-color')
        .append("svg").attr("width", '16').attr("height", '16')
        .append("circle").attr("cx", 8).attr("cy", 8).attr("r", 8)
        .attr("fill", (d,k) => colorsGreen[k]);

    tr.append("td").text(d => d.name);
    tr.append("td").text(d => d3.format(",")(d.price));
    tr.append("td").text(d => this.getPiePercentage(d));

    var fTr = this.pieLegend.append("tfoot").selectAll("tr").data([{}]).enter()
        .append("tr");

    fTr.append("td");
    fTr.append("td");
    fTr.append("td").text(data.total);
    fTr.append("td").text('100%');
  }

  render() {
    this.renderBars();
    this.renderBarsLegend();
    this.renderPieChart();
    this.renderPieLegend();
  }

  getPiePercentage(d) {
    return d3.format(",.2%")(d.price/d3.sum((this.activeBar ? this.activeBar.els : this.barData[0].els).map(v => v.price)));
  }

  pieUpdate(data) {
    if (!data) data = this.activeBar;
    var _arc = this._arc;
    this.pieChart.selectAll("path").data(this._pie(data.els))
        .style("fill", d => d.data.color || false)
        .attr("class", d => `id${d.data.num}`)
        .transition().duration(500).attrTween("d", function(a) {
      var i = d3.interpolate(this._current, a);
      this._current = i(0);
      return t => _arc(i(t));
    });
    this.renderPieLegend(data);
  }

  onChartHover(d) {
    d = d.data ? d.data : d;
    this.pieWrap.classed("hover", true);
    this.pieChart.select(`.id${d.num}`).classed("hover", true);
    this.pieLegend.select(`.id${d.num}`).classed("hover", true);
  }

  onChartUnHover(d) {
    d = d.data ? d.data : d;
    this.pieWrap.classed("hover", false);
    this.pieChart.select(`.id${d.num}`).classed("hover", false);
    this.pieLegend.select(`.id${d.num}`).classed("hover", false);
  }

  onBarHover(d, isClick) {
    var cl = isClick === true ? "active" : "hover";
    this.barsWrap.classed(cl, true);
    this.barsLegend.select(`.id${d.id}`).classed(cl, true);
    this.barsChart.select(`.id${d.id}`).classed(cl, true);
    if (this.activeBar && cl === "hover") {
      this.onBarUnHover(this.activeBar, true);
      this.pieUpdate(d);
    }
  }

  onBarUnHover(d, isClick) {
    var cl = isClick === true ? "active" : "hover";
    this.barsWrap.classed(cl, false);
    this.barsLegend.select(`.id${d.id}`).classed(cl, false);
    this.barsChart.select(`.id${d.id}`).classed(cl, false);
    if (this.activeBar && cl === "hover") {
      this.onBarHover(this.activeBar, true);
      this.pieUpdate();
    }
  }

  onBarClick(d) {
    if (this.activeBar) 
      this.onBarUnHover(this.activeBar, true);
    this.activeBar = d;
    this.onBarHover(this.activeBar, true);
    this.pieUpdate();
    this.root.classList.add('active');
  }

}

let rId = 1;

class Chart extends React.Component {

  constructor() {
    super();
    this.id = `chart${rId}`;
    rId++;
  }

  componentDidMount() {
    this.chart = new Chartt(document.getElementById(this.id));
    this.chart.upData(this.props.data); 
  }
    
  render() {
    return(
      <div className="chart" id={this.id}></div>
    );
  }
}

export default Chart;

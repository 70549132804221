import React, { memo, useCallback } from 'react';
import Amplify from '@aws-amplify/core';
import { DatePicker, Input } from 'antd';
import { updateInfo } from '../../../actions/purchaseRequisition';
import CartMenu from '../CartMenu';
import { Form } from 'antd';
import { useMappedState } from 'redux-react-hook';
import t from '../../../i18';
import moment from 'moment';
import Attachment from '../Attachment';
import './index.sass';

const log = new Amplify.Logger(':::PurchaseInfo:::');
const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
    }
};

const Field = memo(({ name, disabled }) => {
    log.debug(`render ${name}`);
    const onChange = updateInfo(name);
    const value = useMappedState(useCallback(
        state => state.cartInfo.header[name],
        [name]
    ));
    switch (name) {
        case 'date': {
            const _value = value ? moment(value) : null;
            return <DatePicker disabled={disabled} placeholder={null} onChange={onChange} value={_value} format={'DD/MM/YYYY'} />;
        };
        case 'description':
            return <Input disabled={disabled} onChange={onChange} value={value} />;
        case 'notes':
            return <TextArea disabled={disabled} onChange={onChange} value={value} rows={3} />;
        default:
            return null;
    }
});

export default memo(() => {
    log.debug('render');
    const { isSended } = useMappedState(useCallback(
        state => ({
            isSended: state.cartInfo.status === 'sended',
        }),
        []
    ));
    return (
        <div className="purchase-info">
            <div className="title">
                <CartMenu products={isSended ? false : true} current="cart" />
                <h3>{t('cart.cartTitle')}</h3>
            </div>
            <Form>
                <Form.Item {...formItemLayout} label={t('cart.cartDeliveryDate')}>
                    <Field name="date" disabled={isSended} />
                </Form.Item>
                <Form.Item {...formItemLayout} label={t('cart.cartDescription')}>
                    <Field name="description" disabled={isSended} />
                </Form.Item>
                <Form.Item {...formItemLayout} label={t('cart.cartNotes')}>
                    <Field name="notes" disabled={isSended} />
                </Form.Item>
                <Form.Item {...formItemLayout} label={t('cart.attachment')}
                // help={(
                //     <React.Fragment>
                //         <IoIosInformationCircleOutline/>
                //         <span>{t('cart.attachmentInfo')}</span>
                //     </React.Fragment>
                // )}
                >
                    <Attachment />
                </Form.Item>
            </Form>
        </div>
    )
});
import React from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import { Form, Input } from 'antd';

class ForgotPasswordForm extends ForgotPassword {

    sendCode = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => { 
            if (!err) {
                this.setState({
                    username: values.username
                });
                this.send(e);
            }
        });
    }

    submitNewPassword = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) this.submit(e);
        });
    }

    back = () => {
        this.setState({ 
            delivery: null,
            username: ''
        });
        this.changeState('signIn');
    }

    renderError = (field) => {
        let errors = this.props.form.getFieldError(field);
        if(errors && errors.length > 0) {
            return (
                <div className="mt1 ph2 pv1 br1 bg-washed-red red f7 fw6">{errors[0]}</div>
            )
        } else return null;
    }

    sendView() {

        const {
            getFieldDecorator
        } = this.props.form;

        return (
            <div>
                <div className="mw6 center pv3 pv4-ns">
                    <div className="center flex items-center justify-center" style={{width:'128px', height:'32px'}}>
                        <img className="" src="/assets/img/hala-logo.svg" alt="" />
                    </div>
                </div>
                <div className="mw6 center ph2 mb2 mb5-l">
                    <div className="pa3 pa4-ns pa5-l bg-white br1 ba b--black-10">
                        <h1 className="f3 f2-ns mv0">Forgot password?</h1>
                        <p className="mt2 mb0 lh-copy">Enter email address associated with your account and we will email you a link to set new password.</p>
                        <Form onSubmit={this.sendCode} className="login-form">

                            <div className="">
                                <label htmlFor="email" className="b f6">Email:</label>
                                {getFieldDecorator('username', {
                                    rules: [{
                                        required: true,
                                        message: 'Please input your Email!'
                                    },{
                                        type: "email",
                                        message: 'Email address in invalid format!'
                                    }],
                                    validateTrigger: "onSubmit",
                                    onChange: this.handleInputChange
                                })(
                                    <Input className="w-100 mt1 pv2 ph2 f5" name="username"  />
                                )}
                                { this.renderError('username') }
                            </div>
                            <div className="mt3">
                                <input type="submit" value="Reset password" className="w-100 btn btn-blue pv2 ph3 f5" />
                            </div>
                        </Form>
                        <p className="mt3 mb0 lh-copy"><a href="/"
                            onClick={(e) => {e.preventDefault(); this.changeState('signIn')}}
                                                       className="link">Go back to Log in</a>
                        </p>
                    </div>
                </div>
            </div>
        )
    }

submitView() {

        const {
            getFieldDecorator
        } = this.props.form;

        return (
            <div>
                <div className="mw6 center pv3 pv4-ns">
                    <div className="center flex items-center justify-center" style={{width:'128px', height:'32px'}}>
                        <img className="" src="/assets/img/hala-logo.svg" alt="" />
                    </div>
                </div>
                <div className="mw6 center ph2 mb2 mb5-l">
                    <div className="pa3 pa4-ns pa5-l bg-white br1 ba b--black-10">
                        <h1 className="f3 f2-ns mv0">Create new password</h1>
                        <p className="mt2 mb0 lh-copy">Complete password reset for account associated with <strong>{this.state.username}</strong> e-mail.</p>
                        <Form onSubmit={this.submitNewPassword} className="mt3">
                            <div>
                                <label htmlFor="code" className="b f6">Confirm code:</label>
                                {getFieldDecorator('code', {
                                    rules: [{
                                            required: true,
                                            message: 'Please input your Code!'
                                    },{
                                            type: "string",
                                            pattern: /^\d+$/,
                                            message: 'Code in invalid format!'
                                    }],
                                        validateTrigger: "onSubmit",
                                        onChange: this.handleInputChange
                                })(
                                    <Input autoComplete="code" prefixCls="h" className="w-100 mt1 pv2 ph2 f5" name="code"  />
                                )}
                                { this.renderError('code') }
                            </div>
                            <div>
                                <label htmlFor="password" className="b f6">New password:</label>
                                {getFieldDecorator('password', {
                                    rules: [{
                                            required: true,
                                            message: 'Please input your Password!'
                                    }, {
                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,40}$/,
                                        message: 'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!'
                                    }],
                                        validateTrigger: "onSubmit",
                                        onChange: this.handleInputChange
                                })(
                                    <Input autoComplete="new-password" prefixCls="h" className="w-100 mt1 pv2 ph2 f5" name="password" type="password" />
                                )}
                                { this.renderError('password') }
                            </div>
                            <div className="mt3">
                                <input type="submit" value="Confirm new password" className="w-100 btn btn-blue pv2 ph3 f5" />
                            </div>
                        </Form>
                        <p className="mt3 mb0 lh-copy"><a href="/"
                            onClick={(e) => {e.preventDefault(); this.changeState('signIn')}}
                                                       className="link">Go Back to Sign In</a>
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    showComponent() {
        const { hide, authData={} } = this.props;
        if (hide && hide.includes(ForgotPasswordForm)) { return null; }

        return (
            <div>
                { this.state.delivery || authData.username ? this.submitView() : this.sendView() }
            </div>
        )
    }
}

const WrappedForgotPasswordForm = Form.create()(ForgotPasswordForm);
export default WrappedForgotPasswordForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { S3Image, PhotoPicker } from 'aws-amplify-react';
import Amplify from '@aws-amplify/core';
import Storage from '@aws-amplify/storage';
import { sendMessage } from '../../../actions/socket';
import uuid from 'uuid/v1';
import './FilePicker.sass';

const log = new Amplify.Logger(':::FilePicker:::');

class FilePicker extends Component {

    loading = false
    key = uuid()

    state = {
        key: null
    }

    onPick = (data) => {
        if (this.loading) return;
        this.loading = true;
        Storage.put(this.key, data.file, { 
            // level: 'private',
            contentType: data.type
        })
        .then(() => {
            this.setState({
                key: this.key
            })
            this.props.sendMessage('file Key', {
                fileKey: this.key,
                type: 'filePicker'
            }, true);
        })
        .catch(err => log.debug('handle pick error', err));
    }

    render() {
        return (
            <div className="file-picker pl2 bl b--black-05 bw3 measure smartForm">
                { this.state.key 
                    ? (<S3Image imgKey={this.state.key} 
                        //level="private" 
                        />)
                    : (<PhotoPicker
                        headerText=' '
                        headerHint='Choose a file or drag it here.'
                        onPick={this.onPick} />) 
                }
            </div>
        )
    }
}

FilePicker.propTypes = {
    data: PropTypes.object.isRequired,
    sendMessage: PropTypes.func.isRequired
}

export default connect(
    null, 
    {
        sendMessage
    }
)(FilePicker);
import { 
    purchaseProductSearch,
    purchaseProductPage,
    purchaseProductsNextPage,
    purchaseClean,
    PR_setCategory
} from '../../constants';

const init = {
    search: '',
    page: 0,
    limit: 20
};

export default (state = init, { type, payload }) => {
    switch(type) {
        case purchaseProductSearch: 
            return {
                ...state,
                search: payload,
                page: init.page
            };
        case PR_setCategory: 
            return {
                ...state,
                page: init.page
            };
        case purchaseProductPage: 
            return {
                ...state,
                page: payload
            };
        case purchaseProductsNextPage:
            return {
                ...state,
                page: state.page + 1
            };
        case purchaseClean:
            return init;
        default:
            return state;
    }
};
import { 
    fetchAction,  
    fetchActionSuccess,
    fetchActionError
} from '../../constants';

const init = {
    _null: {
        data: [],
        loading: false,
        error: null
    }
};

export default (state = init, { type, payload }) => {
    switch(type) {
        case fetchAction:
            return {
                ...state,
                [payload.action]: {
                    loading: true,
                    data: [],
                    error: null
                }
            };
        case fetchActionError:
            return {
                ...state,
                [payload.action]: {
                    loading: false,
                    data: [],
                    fetched: true,
                    error: payload.error
                }
            };
        case fetchActionSuccess: 
            return {
                ...state,
                [payload.action]: {
                    loading: false,
                    fetched: true,
                    data: [
                        ...payload.data
                    ],
                    error: null
                }
            };
        default:
            return state;
    }
};
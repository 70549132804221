import React from 'react';
import PropTypes from 'prop-types';
import FieldDescription from './FieldDescription';
import { Radio } from 'antd';

const RadioButtonInputField = ({field, getFieldDecorator, errorMessage}) => (
    <div className="mt3">
        <label className="b f6">{field.label}:</label>
        {field.required ?  null : (<span className="fr f6 o-60">Optional</span>)}
        <div className="flex items-center mt2">
            {getFieldDecorator(field.key, {...field.validationOptions,
                validateTrigger: "onChange",
                onChange: getFieldDecorator.handleInputChange
            })(
                <Radio.Group>
                    {field.options.map(op => {
                        return(
                            <div key={op}>
                                <Radio value={op}><label style={{paddingLeft: '1em'} }>{op}</label></Radio>
                            </div>
                        )
                    })
                    }
                </Radio.Group>
            )
            }
        </div>
        {
            field.description ? <FieldDescription description={field.description} />
            : null
        }
        {
            errorMessage(field.key)
        }
    </div>
);

export default RadioButtonInputField;

RadioButtonInputField.propTypes = {
    field: PropTypes.object.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    errorMessage: PropTypes.func.isRequired
}

import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import filter from './filter';
import requests from './requests';
import postRequests from './postRequests';
import counters from './counters';
import upCounters from './upCounters';
import request from './request';

const reducers = combineReducers({
    filter,
    requests: reduceReducers(requests, postRequests),
    counters,
    request
});

export default reduceReducers(reducers, upCounters);
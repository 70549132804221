import {
    SIGNED_IN,
    SIGNED_OUT,
    SIGN_IN,
    SET_USER_INFO,
    VERIFY_CONTACT,
    REQUIRE_NEW_PASSWORD,
    AUTH_LOADING,
    AUTH_ERROR,
    RECONNECT_ATTEMPT,
    CONNECTED
} from '../constants';
import { notification } from 'antd';
import t from '../i18';
  
const notificationId = 'offline';
const initialState = {
    authState: localStorage.getItem(SIGNED_OUT) === 'true' ? SIGNED_OUT : SIGN_IN,
    authData: {},
    user: {
        name: '',
        family_name: ''
    },
    loading: false
};
const cleanUri = window.location.protocol + "//" + window.location.host + window.location.pathname;

export function charAt(str) {
    return str.charAt(0).toUpperCase();
}

function closeNotification() {
    notification.close(notificationId);
}

export default function(state = initialState, { type, authData }) {
    switch (type) {
        case VERIFY_CONTACT:
        case REQUIRE_NEW_PASSWORD:
            return {
                ...state,
                authState: type,
                loading: false
            }
        case SIGN_IN:
            localStorage.setItem(SIGNED_OUT, false);
            return {
                ...state,
                authState: type,
                authData: {},
                loading: false
            }
        case AUTH_LOADING:
            return {
                ...state,
                loading: authData
            }
        case AUTH_ERROR:
            return {
                ...state,
                loading: false
            }
        case SIGNED_IN:
            window.history.replaceState({}, document.title, cleanUri);
            return {
                ...state,
                authState: type,
                authData: {
                    username: authData.username
                },
                loading: false
            }
        case SIGNED_OUT:
            closeNotification();
            localStorage.setItem(type, true);
            return {
                ...state,
                user: {},
                authState: type,
                authData: {},
                loading: false
            }
        case SET_USER_INFO: {
            authData.lastName = authData.family_name;
            authData.firstName = authData.name;
            authData.logo = `${charAt(authData.firstName)}${charAt(authData.lastName)}`;
            return {
                ...state,
                user: authData,
                loading: false
            };
        }
        case CONNECTED: 
            closeNotification();
            return state;
        case RECONNECT_ATTEMPT: {
            notification.open({
                className: "offline",
                message: t('offlineTitle'),
                description: t('offlineDescription'),
                key: notificationId,
                duration: 0
            });
            return state;
        }
        default:
            return state
    }
} 
import {
    TOGGLE_SPEECH_TO_TEXT,
    TOGGLE_TEXT_TO_SPEECH,
    CLEARING_HISTORY_START,
    CLEARING_HISTORY_STOP,
    MESSAGE_IN_PROGRESS,
    RECEIVE_MESSAGE,
    SIGNED_OUT,
    WELCOME_MESSAGE,
    SHOW_INPUT,
    RECEIVE_MESSAGE_BACKGROUND
} from '../constants';

const textToSpeech = localStorage.getItem('textToSpeech');

const initialState = {
    textToSpeech: textToSpeech ? JSON.parse(textToSpeech) : false,
    speechToText: false,
    clearingHistory: false,
    messageInProgress: false,
    welcomeMessage: false,
    showInput: false
};

const configs = (state = initialState, { type, data, payload, message }) => {
    switch(type){
        case SHOW_INPUT:
            return {
                ...state,
                showInput: data
            };
        case WELCOME_MESSAGE:
            return {
                ...state,
                welcomeMessage: true,
                showInput: true
            }; 
        case TOGGLE_SPEECH_TO_TEXT:
            return {
                ...state,
                speechToText: (typeof data !== 'undefined' ? data : !state.speechToText)
            };
        case TOGGLE_TEXT_TO_SPEECH:
            return {
                ...state,
                textToSpeech: (typeof data !== 'undefined' ? data : !state.textToSpeech)
            };
        case CLEARING_HISTORY_START:
            return {
                ...state,
                clearingHistory: true,
                welcomeMessage: false,
                showInput: false,
                rightPanel: false,
                rightPanelRender: null
            };
        case CLEARING_HISTORY_STOP:
            return {
                ...state,
                clearingHistory: false,
                messageInProgress: false
            };
        case MESSAGE_IN_PROGRESS:
            return {
                ...state,
                messageInProgress: true
            };
        case RECEIVE_MESSAGE:
            return {
                ...state,
                messageInProgress: state.messageInProgress && message.isHala ? false : state.messageInProgress,
                showInput: !state.showInput && message.isHala ? true : state.showInput
            };
        case RECEIVE_MESSAGE_BACKGROUND: 
            return {
                ...state,
                messageInProgress: state.messageInProgress && payload.isHala ? false : state.messageInProgress
            };
        case SIGNED_OUT:
            return {
                ...initialState
            };
        default:
            return state;
    }
}

export default configs;
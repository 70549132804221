import React from 'react';
import { connect } from 'react-redux';
import Amplify from '@aws-amplify/core';
import Chat from '../Chat';
import PurchaseRequisition from '../PurchaseRequisition';
import './index.sass';

const log = new Amplify.Logger(':::MainContent Container:::');

function isLiveAgent(auth) {
	if (
		auth.user && auth.user.roles
		&& (
			auth.user.roles.includes('LiveAgent')
			|| auth.user.roles.includes('PurchaseSpecialists')
		)
	) {
		return true;
	}
	return false;
}

function MainContent({mainContent, props, department, isLiveAgent, user}) {
	log.debug('render');

	if (!mainContent) return <Chat/>;
	switch(mainContent) {
		case 'shoppingCart':
			return <PurchaseRequisition 
				actions={props.actions} 
				orders={props.orders}
				department={department}
				isLiveAgent={isLiveAgent}
				currentUser={user}
			/>;
		default:
			return mainContent;
	}
}

export default connect(
	state => ({
		mainContent: state.view.mainContent,
		props: state.view.props,
		department: state.auth.user.department,
		isLiveAgent: isLiveAgent(state.auth),
		user: state.auth.user.sub
	})
)(MainContent);
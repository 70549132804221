import React from 'react';
import PropTypes from 'prop-types';
import FieldDescription from './FieldDescription';

const CheckboxInputField = ({field, getFieldDecorator, errorMessage}) => (
        <div className="mt3">
            <div className="flex items-center relative">
                <span>
                    {
                        getFieldDecorator(field.key, {...field.validationOptions,
                            validateTrigger: "onChange",
                            onChange: getFieldDecorator.handleInputChange,
                            initialValue: field.value
                        })(<input type={field.type} id="checkbox" className="mr2"/>)
                    }
                </span>
                <span>
                    <label htmlFor="checkbox">{field.label}</label>
                </span>
            </div>
            {
                field.description ? <FieldDescription description={field.description} />
                : null
            }
            {
                errorMessage(field.key)
            }
        </div>
);

export default CheckboxInputField;

CheckboxInputField.propTypes = {
    field: PropTypes.object.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    errorMessage: PropTypes.func.isRequired,
}

import React, { memo, useCallback } from 'react';
import Amplify from '@aws-amplify/core';
import { Cascader } from 'antd';
import { useCategories } from '../../../actions/fetcher';
import { useSetCategory } from '../../../actions/purchaseRequisition';
import { useMappedState } from 'redux-react-hook';
import t from '../../../i18';
import './index.sass';

const log = new Amplify.Logger(':::Product Categories:::');

function filter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}

function displayRender(labels, options) {
    const el = options.pop();
    if (!el) return null;
    return <span key={el.value}>{el.label}</span>;
}

function renderSearch(inputValue, path) {
    return path.map(function(option, index) {
        var label = option.label;
        label = label.replace(new RegExp(`(${inputValue})`, 'ig'), '<b>$1</b>').split('<b>');
        label = label.map((d,k) => {
            if (d.indexOf('</b>') !== -1) {
                d = d.split('</b>');
                return (
                    <span key={`${k}-1`}>
                        <b>{d[0]}</b>{d[1]}
                    </span>
                )
            }
            return d;
        });
        return index === 0 ? label : [' / ', label];
    });
}

export default memo(() => {
    log.debug('render');
    const options = useCategories();
    const onChange = useSetCategory();
    const current = useMappedState(useCallback(
        state => state.categories.current,
        []
    ));

    return (
        <div className="categories-menu-wrap">
        <Cascader
            className="product-categories"
            options={options}
            changeOnSelect
            onChange={onChange}
            defaultValue={current}
            popupClassName="product-categories"
            placeholder={t('cart.categories')}
            popupPlacement="bottomRight"
            getPopupContainer={() => document.querySelector('.categories-menu-wrap')}
            displayRender={displayRender}
            showSearch={{ 
                filter, 
                matchInputWidth: false, 
                limit: 1000,
                render: renderSearch
            }}
        />
        </div>
    )
});
import React, { memo } from 'react';
import classnames from 'classnames';
import './index.sass';

function Spinner({className, ...props}) {
    return (
        <svg {...props} className={classnames("spinner-el", className)} viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
        </svg>
    )
}

export default memo(Spinner);
import React from 'react';
import PropTypes from 'prop-types';
import './index.sass';

const KeyValView = ({label, value}) => (
    <div className="key-val-item">
        <div className="label">{label}:</div>
        <div className="value">{value}</div>
    </div>
)

KeyValView.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
}

export default KeyValView;
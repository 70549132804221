import {
    purchaseProductSearch,
    purchaseProductsFetch,
    purchaseProductsFetchSuccess,
    purchaseClean,
    PR_setCategory
} from '../../constants';

const init = {
    items: [],
    loading: true
};

export default (state = init, {type, payload}) => {
    switch(type) {
        case purchaseProductsFetch: 
            return {
                ...state,
                loading: true
            };
        case purchaseProductsFetchSuccess:
            payload = payload.map(el => {
                el.currency = 'EUR';
                return el;
            });
            return {
                items: [
                    ...state.items,
                    ...payload
                ],
                loading: false
            };
        case purchaseProductSearch: 
        case PR_setCategory:
        case purchaseClean:
            return init;
        default: 
            return state;
    }
};
import { 
    LA_setCurrentChat,
    LA_requestUpdated,
    RECEIVE_MESSAGE_BACKGROUND,
    DISCONNECTED,
    SIGNED_OUT
} from '../constants';

const init = {
    current: 'hala',
    messages: {},
    newMessages: {},
    prev: null
};

function addNewMessage(state, payload) {
    let messages = state.messages[payload.channel] || [];
    messages = messages.filter(el => el.loading ? false : true);

    return {
        ...state,
        messages: {
            [payload.channel]: [
                ...messages,
                payload
            ]
        },
        newMessages: {
            [payload.channel]: (state.newMessages[payload.channel] || 0) + 1
        }
    };
}

export default function(state = init, {type, payload}) {
    switch(type){
        case LA_setCurrentChat:
            return {
                ...state,
                prev: state.current,
                current: payload
            };
        case RECEIVE_MESSAGE_BACKGROUND: 
            return addNewMessage(state, payload);
        case LA_requestUpdated:
            if (
                (state.current === payload.id)
                && (
                    (payload.agent && payload.agent !== payload.currentUser)
                    || 
                    (['closed','canceled', 'timeout'].indexOf(payload.status) !== -1)
                    )
                ) {
                const messages = state.messages;
                delete messages[payload.id];
                return {
                    ...state,
                    current: 'hala',
                    prev: null,
                    messages
                };
            }
            return state;
        case DISCONNECTED:
        case SIGNED_OUT:
            return init;
        default:
            return state;
    }
}
import React, { memo } from 'react';
import { List } from 'antd';
import Amplify from '@aws-amplify/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRequestById } from '../../../reducers/liveAgent/selectors';

const log = new Amplify.Logger(':::User Meta:::');

function UserMeta({fullName, description, avatar}) {
    log.debug('render');
    return (
        <List.Item.Meta
            avatar={avatar}
            title={fullName}
            description={description}   
        />
    )
};

UserMeta.propTypes = {
    fullName: PropTypes.string.isRequired
};

export default memo(UserMeta);

export function connectUserMeta(id) {
    return connect( 
        state => {
            const item = getRequestById(state, id);
            return {
                fullName: `${item.firstName} ${item.lastName}`
            };
        },
        {}
    )(UserMeta);
}
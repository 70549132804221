import React, { memo } from 'react';
import Amplify from '@aws-amplify/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './index.sass';

const log = new Amplify.Logger(':::Chat Container:::');

function ChatContainer({children, input, header, className = null}) {
    log.debug('render');
    return (
        <div className={classnames("chat-wrap", className)}>
            { header ? header : null}
            <div className="chat-container">
                { children }
            </div>
            { input }
        </div>
    );
}

ChatContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]).isRequired,
    input: PropTypes.object
}

export default memo(ChatContainer);
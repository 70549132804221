import {
    ERROR,
    DISMISS_ERROR
} from '../constants';

export const error = data => {
    return {
        type: ERROR,
        data
    }
}

export const dismissError = () => ({
    type: DISMISS_ERROR
})
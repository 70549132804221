import Auth from '@aws-amplify/auth';

let config = {};

export const prefix = 'telia';
export const isTelia = prefix === 'telia';

const _prefs = {
    telia: {
        Auth: {
            userPoolId: 'eu-central-1_jhmQCEryC',
            userPoolWebClientId: '3msstakb73gvig9piunnsh90qi',
            region: 'eu-central-1',
            identityPoolId: 'eu-central-1:18685d8d-790f-4ee4-9378-e55954e381f5',
            oauth: {
                iprovider: 'telia',
                domain: 'telia-auth.hala.ai',
                scope: ['email', 'openid'],
                redirectSignIn: process.env.REACT_APP_REDIRECT_URI || 'http://localhost:3000',
                redirectSignOut: 'https://telia.hala.ai',
                responseType: 'code'
            }
        }
    }
};

const customHeader = async () => { 
    return { 
        Authorization: (await Auth.currentSession()).idToken.jwtToken,
        prefix,
        'Cache-Control': 'no-cache', //fix for IE
        'Pragma': 'no-cache' //fix for IE
    };
};

export default {
    aws: {
        Storage: {
            AWSS3: {
                bucket: 'hala-user-files',
                region: 'eu-central-1'
            }
        },
        API: {        
            endpoints: [{
                "name": "api",
                "endpoint": process.env.REACT_APP_API_ENDPOINT || "http://localhost:9010",
                "custom_header": customHeader
            }, {
                "name": "sapi",
                "endpoint": process.env.REACT_APP_API2_ENDPOINT || "http://localhost:9000",
                "custom_header": customHeader
            }]
        },
        ...Object.assign({
            Auth: {
                userPoolId: 'eu-central-1_t59cuEfDz',
                userPoolWebClientId: '1ou024tnhqk4gij83rq4b2scof',
                region: 'eu-central-1',
                identityPoolId: 'eu-central-1:b6005257-14ad-4e13-a68c-32a457764df8'
            }
        }, _prefs[prefix] || {})
    },
    socketUrl: process.env.REACT_APP_API2_ENDPOINT || "http://localhost:9000",
    googleAnalytics: process.env.REACT_APP_GOOGLE_ANALYTICS,
    ...config
};

import React, { useCallback } from 'react';
import Amplify from '@aws-amplify/core';
import { connect } from 'react-redux';
import { getRequestById } from '../../../reducers/liveAgent/selectors';
import PropTypes from 'prop-types';
import { Icon, Button } from 'antd';
import t from '../../../i18';
import LiveDate from '../../../components/LiveDate';
import { assignRequest } from '../../../actions/liveAgent';
import './index.sass';

const log = new Amplify.Logger(':::Request Description:::');

function RequestDescription({id, loading, description, createdAt, assignRequest, agent}) {
    log.debug('render');
    const assign = useCallback(() => assignRequest(id), [id, assignRequest]);

    return (
        <div className="request-description">
            <h3>{t('liveAgent.requestTitle')}</h3>
            <p className="description">{description}</p>
            <p className="date">{t('created')} <LiveDate ago={true} date={createdAt}/></p>
            {
                !agent
                ? (
                    <Button onClick={loading ? null : assign} className="btn btn-blue">
                        <Icon type={loading ? "loading" : "check"} /> {t('liveAgent.takeRequest')}
                    </Button>
                )
                : null
            }
        </div>
    );
}

RequestDescription.propTypes = {
    description: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    id: PropTypes.string,
    assignRequest: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
}

export default connect( 
    state => {
        const item = getRequestById(state, state.chat.current);
        if ((!item || !item.id || item.id === 'hala') && state.liveAgent.request.channel) {
            return {
                description: state.liveAgent.request.description,
                createdAt: state.liveAgent.request.timer,
                id: null,
                agent: true,
                loading: false
            };
        }
        return {
            description: item.description,
            createdAt: item.createdAt,
            id: item.id,
            agent: item.agent,
            loading: item.loading || false
        };
    },
    { assignRequest }
)(RequestDescription);
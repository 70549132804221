import React, { memo, useState, useEffect } from 'react';
import Amplify from '@aws-amplify/core';
import moment from 'moment';

const log = new Amplify.Logger(':::Live Date:::');

function _time(d, ago) {
    if (!d) return '';
    return moment(d).fromNow(!ago);
}

function LiveDate({date, ago = false}) {
    log.debug('render');
    const [time, update] = useState(false);

    useEffect(() => {
        const tId = setInterval(() => update(!time), 60000);
        return () => clearInterval(tId);
    }, [time]);

    return (
        <span className="live-date">
            {_time(date, ago)}
        </span>
    )
}

export default memo(LiveDate);

import React, { useEffect, useCallback } from 'react';
import { Logger } from '@aws-amplify/core';
import { Auth, Hub } from 'aws-amplify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { signedIn, authError, signIn } from '../../actions/auth';
import config from '../../config';
import { SIGNED_IN, SIGNED_OUT } from '../../constants';
import App from '../App';
import t from '../../i18';

const log = new Logger(':::OAuth Container:::');
const _auth = config.aws.Auth;
const signUri = _auth.oauth ? `https://${_auth.oauth.domain}/oauth2/authorize`
    + `?identity_provider=${_auth.oauth.iprovider}`
    + `&redirect_uri=${_auth.oauth.redirectSignIn}`
    + `&response_type=${_auth.oauth.responseType}`
    + `&client_id=${_auth.userPoolWebClientId}`
    + `&scope=${_auth.oauth.scope.join(' ')}` : null;

const hasParams = () => Object.keys(queryString.parse(window.location.search)).length > 0;

function OAuth({ signedIn, isSignedIn, authError, isSignedOut, signIn}) {
    log.debug('render');

    const authEvent = useCallback(data => {
        switch (data.payload.event) {
            case 'signIn':
                signedIn({
                    username: data.payload.data.username
                });
                break;
            case 'cognitoHostedUI_failure':
                log.error('signIn_failure', data.payload);
                authError(data.payload.message);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        Hub.listen('auth', authEvent);
        return () => Hub.remove('auth', authEvent);
    }, [authEvent]);

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(user => {
            signedIn({ username: user.username });
        }).catch(e => {
            log.error(e);
            if (!isSignedOut && !hasParams()) window.location.assign(signUri);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedOut]);
    if (isSignedIn) {
        return <App/>;
    } else if (isSignedOut) {
        return <div onClick={signIn} className="btn btn-blue main-signed-in">{t('signIn')}</div>;
    }
    return null;
}

OAuth.propTypes = {
    signedIn: PropTypes.func.isRequired,
    authError: PropTypes.func.isRequired,
    signIn: PropTypes.func.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    isSignedOut: PropTypes.bool.isRequired
};

export default connect(
    state => ({
        isSignedIn: state.auth.authState === SIGNED_IN,
        isSignedOut: state.auth.authState === SIGNED_OUT,
        error: state.auth.error
    }),
    {
        signedIn,
        authError,
        signIn
    }
)(OAuth);
import { useEffect, useCallback, useState } from 'react';
import Amplify from '@aws-amplify/core';
import API from '@aws-amplify/api';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { 
    fetchAction,
    fetchActionSuccess,
    fetchActionError,
    PR_fetchCategories,
    PR_fetchCategoriesSuccess,
    PR_fetchCategoriesError,
    purchaseOrdersNextPage
} from '../constants';

const log = new Amplify.Logger(':::Fetcher Action:::');

export function useNextPage(key) {
    const dispatch = useDispatch();
    return useCallback(() => {
        let type;
        switch(key) {
            case 'orders':
                type = purchaseOrdersNextPage;
                break;
            default:
                type = `${key}NextPage`;
                break;
        }
        dispatch({type});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);
}

export const execAction = ({
    action,
    params = {}
}, useCache = true) => {
    return async (dispatch, getState) => {
        const key = `${action}:${JSON.stringify(params)}`;
        let data = [];
        if (useCache && getState().cacheData[key]) {
            data = getState().cacheData[key].data;
        } else {
            dispatch({type: fetchAction, payload: {action: key}});
            try {
                data = await API.get('api', `/actions/${action}/exec`, {
                    queryStringParameters: params
                });
                dispatch({type: fetchActionSuccess, payload: {data, action: key}});   
            } catch (error) {
                log.error(`useAction`, error);
                dispatch({type: fetchActionError, payload: {error,action: key}});
            }
        }
        return data;
    };
};

export const useAction = ({
    action, 
    fetch = false, 
    params = null
}) => {
    const dispatch = useDispatch();
    let name = action || '_null';
    if (params) {
        name = `${name}:${JSON.stringify(params)}`;
    }
    const mapState = useCallback(state => state.cacheData[name] || state.cacheData['_null'], [name]);
    const state = useMappedState(mapState);
    useEffect(() => {
        (async () => {
            if (!fetch || state.loading || !action) return;
            if (state.fetched || state.error) return;
            log.debug(`useAction fetch`, action, name);
            dispatch({type: fetchAction, payload: {action: name}});
            try {
                const data = await API.get('api', `/actions/${action}/exec`, {
                    queryStringParameters: params
                });
                dispatch({type: fetchActionSuccess, payload: {data, action: name}});
            } catch (error) {
                log.error(`useAction`, error);
                dispatch({type: fetchActionError, payload: {error,action: name}});
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, fetch, state, name]);
    return state;
};

export const useCategories = () => {
    const dispatch = useDispatch();
    const [fetched, setFetched] = useState(false);
    const mapState = useCallback(state => ({
        action: state.actions.getCategories,
        data: state.categories.tree
    }), []);
    const { action, data } = useMappedState(mapState);

    useEffect(() => {
        (async () => {
            if (data.length || fetched) return;
            setFetched(true);
            dispatch({type: PR_fetchCategories});
            try {
                const payload = await API.get('api', `/actions/${action}/exec`);
                dispatch({type: PR_fetchCategoriesSuccess, payload});
            } catch (error) {
                log.error(`useCategories`, error);
                dispatch({type: PR_fetchCategoriesError, payload: error});
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, data]);
    return data;
};
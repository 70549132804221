import React, { memo, useCallback } from 'react';
import Amplify from '@aws-amplify/core';
import CartBadge from '../CartBadge';
import { Row, Col, Input } from 'antd';
import { searchProducts } from '../../../actions/purchaseRequisition';
import CartMenu from '../CartMenu';
import { useMappedState } from 'redux-react-hook';
import ProductCategories from '../ProductCategories';
import t from '../../../i18';
import './index.sass';

const log = new Amplify.Logger(':::ProductsTopBar:::');

export default memo(() => {
    log.debug('render');

    const onChange = searchProducts();

    const mapState = useCallback(
        state => ({
            hasCart: state.actions.saveDraft ? true : false,
            hasOrders: state.actions.getOrders ? true : false
        }),
        []
    );
    const { hasCart, hasOrders } = useMappedState(mapState);
    
    return (
        <Row className="top-bar" type="flex">
            <Col className="title" span={hasCart?10:12}>
                <CartMenu current={hasOrders ? "products" : null}/>
                <h3>{t('cart.productsTitle')}</h3>
            </Col>
            <Col span={12}>
                <Input onChange={onChange} className="search" placeholder={t('searchPlaceholder')} />
                <ProductCategories/>
            </Col>
            {
                hasCart
                ? (
                    <Col className="bar-actions" span={2}>
                        <CartBadge/>
                    </Col>
                )
                : null
            }
        </Row>
    )
});
import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.sass';
import { closeSideContent } from '../../actions/view';
import Amplify from '@aws-amplify/core';
import Chat from '../Chat';
import LiveAgent from '../LiveAgent';

const log = new Amplify.Logger(':::RightContent Container:::');

function SideContent ({sideContent, sidePosition, closeIcon, closeSideContent}) {
    log.debug('render');
    
    const ref = useRef(null);
    useEffect(() => {
		if (sideContent) {
            const timeout = setTimeout(() => { 
                ref.current.classList.add('show');
            }, 0);
            return () => clearTimeout(timeout);
		}
    }, [sideContent]);
    
    const content = useMemo(() => {
        if (!sideContent) return null;
        if (typeof sideContent === 'function') {
            return sideContent();
        }
        switch(sideContent) {
            case 'chat':
                return (<Chat/>);
            case 'liveAgent':
                return (<LiveAgent/>)
            default:
                return null;
        }
    },[sideContent]);

    if (!content) return null;
    return (
        <div 
            ref={ref}
            className={`side-content-wrap ${sidePosition}`}
        >
            {
                closeIcon
                ? <div className="close-icon" onClick={closeSideContent}></div>
                : null
            }
            <div className="side-content">
                { content }
            </div>
		</div>
    )
}

SideContent.propTypes = {
    sideContent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.func
    ]),
    sidePosition: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    closeIcon: PropTypes.bool.isRequired,
    closeSideContent: PropTypes.func.isRequired
}

export default connect(
    state => ({
        sideContent: state.view.sideContent,
        sidePosition: state.view.sidePosition,
        closeIcon: state.view.closeIcon
    }), {
    closeSideContent
})(SideContent);

import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

function PictureComponent({
    message
}) {
    const [visible, setVisible] = useState(false);
    const { value, title } = message;
    const openModal = useCallback(() => setVisible(true), []);
    const closeModal = useCallback(() => setVisible(false), []);

    return (
        <div className="pl2 bl mt3 b--black-05 bw3">
            <img className="br1" style={{cursor: 'pointer'}} src={value} onClick={openModal} alt={title}/>
            <Modal
                visible={visible}
                footer={null}
                onOk={closeModal}
                onCancel={closeModal}
                width="90%"
                style={{ top: 20 }}
            >
                <img src={value} alt={title}/>
            </Modal>
        </div>
    )
}

PictureComponent.propTypes = {
    message: PropTypes.object.isRequired
}

export default memo(PictureComponent);
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import products from './products';
import productsFilter from './productsFilter';
import cart from './cart';
import postReducer from './postReducer';
import preReducer from './preReducer';
import cartInfo from './cartInfo';
import actions from './actions';
import ordersFilter from './ordersFilter';
import orders from './orders';
import cacheData from './cacheData';
import categories from './categories';
import categoriesTree from './categoriesTree';
import department from './department';

const reducers = combineReducers({
    cart,
    products,
    productsFilter,
    cartInfo,
    actions,
    ordersFilter,
    orders,
    cacheData, 
    department,
    categories: reduceReducers(categories, categoriesTree)
});

export default reduceReducers(preReducer, reducers, postReducer);
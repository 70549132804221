import { 
    LA_changeFilter,
    SIGNED_OUT,
    DISCONNECTED
} from '../../constants';

const init = 'online';

export default (state = init, { type, payload }) => {
    switch(type) {
        case LA_changeFilter:
            return payload;
        case SIGNED_OUT:
        case DISCONNECTED:
            return init;
        default:
            return state;
    }
};
import { useDispatch } from 'redux-react-hook';
import { useCallback } from 'react';
import {
    updatePurchaseInfo,
    purchaseView,
    purchaseProductSearch,
    purchaseViewCart,
    purchaseViewProducts,
    purchaseViewOrders,
    purchaseOpenItem,
    purchaseCloseItem,
    purchaseEditItem,
    purchaseAddItem,
    purchaseDeleteItem,
    purchaseItemQty,
    purchaseSaveRequest,
    purchaseSaveSuccess,
    purchaseSaveError,
    purchaseOrdersSearch,
    purchaseDeleteOrder,
    purchaseDeleteOrderSuccess,
    purchaseDeleteOrderError,
    purchaseEditOrder,
    CHANGE_VIEW,
    purchaseCreateOrder,
    PR_setCategory,
    ERROR
} from '../constants';
import { Modal } from 'antd';
import API from '../services/api';
import Amplify from '@aws-amplify/core';
import { Hub } from '@aws-amplify/core';
import { sendMessage } from './socket';
import t from '../i18';
import moment from 'moment';

const confirm = Modal.confirm;
const log = new Amplify.Logger(':::Purchase Requisition Action:::');

export function useSetCategory() {
    const dispatch = useDispatch();
    return useCallback((value, options) => {
        dispatch({
            type: PR_setCategory,
            payload: { value, options }
        });
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, []);
}

export function updateInfo(name) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    let info = {type: updatePurchaseInfo, payload: {name}};
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useCallback(e => {
        let val = null;
        if (name === 'date') {
            if (e && e._isAMomentObject) {
                val = e.clone().startOf('day').toISOString();
            }
        } else if (e) {
            val = e.target.value;
        }
        info.payload.value = val;
        dispatch(info);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

export const editOrder = item => ({
    type: purchaseEditOrder, 
    payload: item
});

export const useEditOrder = item => {
    const dispatch = useDispatch();
    if (item) {
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
        return useCallback(() => dispatch(editOrder(item)), [item]);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    return useCallback(item => () => dispatch(editOrder(item)), []);
};

const deleteOrder = (item) => (
    async (dispatch, getState) => {
        const actionId = getState().actions.deleteDraft;
        dispatch({ type: purchaseDeleteOrder, payload: item });
        try {   
            await API.del('api', `/actions/${actionId}/exec`, {
                body: {
                    id: item.id
                }
            });
            dispatch({ type: purchaseDeleteOrderSuccess });
        } catch (error) {
            log.error(error);
            dispatch({ type: purchaseDeleteOrderError, payload: error});
        }
    }
);

export const useDeleteOrder = item => {
    const dispatch = useDispatch();
    if (item) {
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
        return useCallback(() => dispatch(deleteOrder(item)), [item]);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    return useCallback(item => () => dispatch(deleteOrder(item)), []);
};

export const useCreateOrder = item => {
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    return useCallback(() => dispatch({type: purchaseCreateOrder}), []);
};

export function showSummary() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    return useCallback(() => dispatch({type: purchaseView, payload: purchaseViewCart}), []);
}

export const showProducts = {
    type: purchaseView, 
    payload: purchaseViewProducts
};

export function useShowProducts() {
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    return useCallback(() => dispatch(showProducts), []);
}

export function searchProducts() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    let timeout = null;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useCallback(e => {
        e.persist();
        if (timeout) clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
        timeout = setTimeout(() => {
            dispatch({type: purchaseProductSearch, payload: e.target.value});
        }, 500);
    }, []);
}

export function useSearchOrders() {
    const dispatch = useDispatch();
    return useCallback(val => {
        dispatch({type: purchaseOrdersSearch, payload: val});
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, []);
}

function openView(name) {
    return {type: purchaseView, payload: name};
}

export function useOpenView(name) {
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    const open = useCallback(() => dispatch(openView(name)),[name]);
    return useCallback(() => {
        if (name === purchaseViewOrders) {
            return dispatch(openViewConfirm(open));
        } else open();
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, [name]);
}

export function useClosePurchase() {
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    return useCallback(() => dispatch(openViewConfirm(closePurchase)),[]);
}

export function openViewConfirm(cb) {
    return (dispatch, getState) => {
        const state = getState();
        const {view, status, hasChanges} = state.cartInfo;
        const itemsChanges = state.cart.hasChanges;
        if ((view === purchaseViewCart || view === purchaseViewProducts) && (status !== 'sended') 
        && (hasChanges || itemsChanges)) {
            confirm({
                title: t('cart.unsavedConfirmation'),
                cancelText: t('no'),
                okText: t('yes'),
                onOk() {
                    cb();
                },
                onCancel() {}
            });
        } else cb();
    };
}

export function openItem(payload) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    return useCallback(() => dispatch({ type: purchaseOpenItem, payload }), [payload]);
}

export function useOpenItem() {
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    return useCallback(payload => dispatch({ type: purchaseOpenItem, payload }), []);
}

export const useEditItem = openItem;

export const saveItem = payload => {
    if (payload.cartId) return { type: purchaseEditItem, payload };
    else return { type: purchaseAddItem, payload };
};

export const useSaveItem = () => {
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    return useCallback((payload) => dispatch(saveItem(payload)), []);
};

export const closeItem = {
    type: purchaseCloseItem
};

export const useCloseItem = () => {
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    return useCallback(() => dispatch(closeItem), []);
};

export const deleteItem = payload => ({
    type: purchaseDeleteItem,
    payload
});

export function useDeleteItem(item) {
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    return useCallback(() => dispatch(deleteItem(item)), [item]);
}

export const changeQty = (payload, qty) => ({
    type: purchaseItemQty,
    payload,
    qty
});

export function useChangeQty() {
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    return useCallback(item => val => dispatch(changeQty(item, val)), []);
}

const symbols = ["!", "?", "=", "$", "*", ";", "&", "&lt;"]
const symbolsError = `${t('cart.symbols')} "${symbols.join(',')}" ${t('cart.are_not_allowed')}`;

function containSymbols(str) {
    return symbols.some(s => str.indexOf(s) !== -1);
}

const isValidPurchase = (data, isSend) => {
    if (!data.items.length) {
        return t('cart.emptyProducts');
    }
    if (!data.description) {
        return t('cart.requiredDescription');
    } 

    if (
        containSymbols(data.description)
        || containSymbols(data.notes)
        || (data?.attachment?.name && containSymbols(data.attachment.name))
        || (data?.attachments?.length && data.attachments.some(file => containSymbols(file.name)))
    ) {
        return symbolsError;
    }
  
    if (isSend) {
        if (!data.date) {
            return t('cart.requiredDate');
        }
        if (!data.items.every(el => el.isValid)) {
            return t('cart.emptyProductsDetails');
        }
    }
    return null;
};

export const savePurchase = isSend => {
    return async (dispatch, getState) => {
        const state = getState();
        const data = {
            date: state.cartInfo.header.date, 
            utcOffset: moment().utcOffset(),
            description: state.cartInfo.header.description.trim(),
            notes: state.cartInfo.header.notes.trim(),
            count: state.cartInfo.count,
            total: state.cartInfo.total,
            attachment: state.cartInfo.header.file,
            attachments: state.cartInfo.files,
            items: [...state.cart.items],
            status: isSend ? 'sended' : 'draft'
        };
        if (state.cartInfo.id) {
            data.id = state.cartInfo.id;
        }
        const error = isValidPurchase(data, isSend);
        if (error) {
            Hub.dispatch('rootDispatch', {type: ERROR, data: error});
            return;
        }
        try {   
            dispatch({ type: purchaseSaveRequest});
            const resp = await API.post('api', `/actions/${state.actions.saveDraft}/exec`, {
                body: data
            });
            if (data.status === 'sended') {
                data.mdId = resp;
            }
            dispatch({ type: purchaseSaveSuccess });
        } catch (error) {
            log.error(error);
            const err = error?.response?.data?.error || error?.response || error;
            Hub.dispatch('rootDispatch',{type: ERROR, data: err});
            dispatch({ type: purchaseSaveError });
            return;
        }
        Hub.dispatch('rootDispatch',{type: CHANGE_VIEW, data: {component:null}});
        Hub.dispatch('rootDispatch', {payload:sendMessage('cartData', {
            cartData: data
        }, true)});
    };
};

export const closePurchase = () => {
    Hub.dispatch('rootDispatch',{type: CHANGE_VIEW, data: {component:null}});
    Hub.dispatch('rootDispatch', {payload:sendMessage('cartData', {
        cartData: {
            status: 'canceled'
        }
    }, true)});
};

export async function removeAttachment(id, fileId) {
    return await API.del('api', `/shopping-cart/${id}/attachment/${fileId}`);
}
import './MessageLogo.sass';
import React, { memo } from 'react';
import PropTypes from 'prop-types';

function MessageLogo({isHala, logo}) {
    return (
        <div className={`message-logo ${isHala ? 'hala-logo' : 'user-logo'}`}>
            { 
                isHala
                ?   <img src="assets/img/hala-logo2.svg" alt="" />
                :   <span>{logo}</span>
            }
        </div>
    )
}

MessageLogo.propTypes = {
    isHala: PropTypes.bool.isRequired,
    logo: PropTypes.string.isRequired,
}

export default memo(MessageLogo);
import {
    SEND_MESSAGE,
    RECEIVE_MESSAGE,
    CLEARING_HISTORY_START,
    CLOSE_FORM,
    QUICK_REPLY_CLICKED,
    SIGNED_OUT,
    MESSAGE_IN_PROGRESS
} from '../constants';
import uuid from 'uuid/v1';

const initialState = [];

const messages = (state = initialState, action) => {
    switch (action.type) {
        case SEND_MESSAGE:
            return [
                ...state.filter(el => el.loading !== true), 
                action.payload
            ];
        case MESSAGE_IN_PROGRESS:
            return [
                ...state,
                {
                    id: uuid(),
                    isHala: true,
                    loading: true
                }
            ];
        case RECEIVE_MESSAGE:
            let inLoading = false;
            let res = state.map(msg => {
                if (msg.loading) {
                    inLoading = true;
                    return {
                        ...action.message,
                        anim: false
                    }
                }
                return msg;
            });
            if (inLoading) 
                return res;
            else 
                return [...state, action.message];
        case CLEARING_HISTORY_START:
            return initialState;
        case SIGNED_OUT:
            return initialState;
        case CLOSE_FORM:
            return state.map(msg => {
                if (msg.id === action.data.messageId) {
                    msg.data[action.data.dataId].hide = true;
                    return {
                        ...msg
                    }
                }
                return msg;
            });
        case QUICK_REPLY_CLICKED:
            return state.map(msg => {
                if (msg.id === action.data.messageId) {
                    msg.data[action.data.dataId].quickReply = null;
                    return {
                        ...msg
                    }
                }
                return msg;
            });
        default:
            return state;
    }
}

export default messages;

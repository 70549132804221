import React, { memo, useCallback } from 'react';
import Amplify from '@aws-amplify/core';
import { useMappedState } from 'redux-react-hook';
import PurchaseItem from '../PurchaseItem';
import { List } from 'antd';
import './index.sass';

const log = new Amplify.Logger(':::PurchaseList:::');

export default memo(() => {
    log.debug('render');

    const mapState = useCallback(state => ({
        items: state.cart.items,
        isSended: state.cartInfo.status === 'sended'
    }),[]);
    const { items, isSended } = useMappedState(mapState);

    return (
        <List
            className="purchase-list"
            itemLayout="horizontal"
            dataSource={items}
            renderItem={item => <PurchaseItem item={item} isSended={isSended}/>}
        />
    )
});
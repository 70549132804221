import React, { memo, useRef, useMemo } from 'react';
import Amplify from '@aws-amplify/core';
import InfiniteScroll from 'react-infinite-scroller';
import { List } from 'antd';
import './index.sass';

const log = new Amplify.Logger(':::InfiniteItemsList:::');
export const genMock = n => [...new Array(n)].map(() => ({ loading: true }));
const _mockData = genMock(8);
const _loadMore = () => false;

export default memo(({className, data, loading, loadMore = null, hasMore = false, renderItem, mockItemsN}) => {
    log.debug('render');
    const mockData = useMemo(() => mockItemsN ? genMock(mockItemsN) : _mockData, [mockItemsN]);
    const dataSource = useMemo(() => {
        if (loading) return data.concat(mockData);
        return data;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, data]);
    const scrollEl = useRef(null);
    if (!loadMore) loadMore = _loadMore;
    return (
        <div className={`infinite-list ${className ? className : ''}`} ref={scrollEl}>
            <InfiniteScroll
                className="list-auto-load"
                initialLoad={false}
                pageStart={0}
                loadMore={loading || !hasMore ? _loadMore : loadMore}
                hasMore={loading ? false : hasMore}
                useWindow={false}
                getScrollParent={() => scrollEl.current}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={dataSource}
                    renderItem={renderItem}
                />
            </InfiniteScroll>
        </div>
    )
});
import io from 'socket.io-client';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import uuid from 'uuid/v1';
import * as c from '../constants';
import { signOut } from './auth';
import config, { prefix, isTelia } from '../config';
import { textToSpeech } from '../services/audio';
import { getLanguageCode } from '../i18';
import { requestStatus } from './liveAgent';

const log = new Amplify.Logger(':::Socket IO:::');
export const socket = io(config.socketUrl, {  
    autoConnect: false,
    secure: true
});

let query = {};
let connectAttempt = 0;
let tokenAttempt = 0;

async function buildQuery(dispatch) {
    query = {};
    if (connectAttempt > 1) {
        if (connectAttempt > 10){
            connectAttempt = 0;
        } 
        return query;
    }
    try {
        const { idToken } = await Auth.currentSession();
        
        query = {
            token: idToken.jwtToken,
            user: idToken.payload.sub,
            prefix: prefix
        };

        let defGroup = null;
        if (idToken.payload.identities && idToken.payload.identities.length) {
            defGroup = `${idToken.payload.iss.split('/').pop()}_${idToken.payload.identities[0].providerName}`;
        }

        let mixedData = {};
        if (isTelia) {
            mixedData.department = {
                departmentCode: idToken.payload.departmentCode || '',
                departmentName: idToken.payload.departmentName || ''
            };
        }

        dispatch({type: c.SET_USER_INFO, authData: {
            roles: idToken.payload['cognito:groups'].map(g => {
                if (g === defGroup) return 'User';
                return g;
            }),
            family_name: idToken.payload.family_name || '',
            name: idToken.payload.name || '',
            sub: idToken.payload.sub,
            username: idToken.payload['cognito:username'],
            ...mixedData
        }}); 
    } catch (err) {
        log.error('buildQuery', err);
    }
    return query;
}

const _connect = async (dispatch) => {
    log.debug('connect');
    if (socket.connected) {
        log.debug('already connected');
        return;
    }
    connectAttempt = 0;
    socket.io.opts.query = await buildQuery(dispatch);
    dispatch({ type: c.CONNECT });
    socket.disconnect();
    socket.connect();
};

let _bind = false;
export const connect = () => (
    async (dispatch, getState) => {
        _connect(dispatch);
        if (_bind) return;
        _bind = true;

        socket.on('reconnect_attempt', (n) => {
            log.debug('reconnect_attempt', n);
            dispatch({ type: c.RECONNECT_ATTEMPT });
        });

        socket.on('reconnect', () => {
            log.debug('reconnected');
            dispatch({ type: c.RECONNECTED });
        });

        socket.on('connect', () => {
            log.debug('connected');
            tokenAttempt = 0;
            dispatch({ type: c.CONNECTED });
        });

        socket.on('disconnect', () => {
            log.debug('disconnected');
            dispatch({ type: c.DISCONNECTED });
        });

        socket.on('error', async (error) => {
            log.error(error);
            dispatch({ type: c.CONNECT_ERROR, error});
            if (error === 'ACCESS_DENIED') {
                dispatch(signOut());
            }
            else if (error === 'TOKEN_IS_EXPIRED') {
                if (tokenAttempt > 5) return false;
                ++tokenAttempt;
                _connect(dispatch);
            }
        });

        socket.on('message', message => {
            log.debug('message recived', message);

            if (message.isHala && message.data) {
                message.data = message.data.filter(d => {
                    switch(d.type) {
                        case "changeView":
                            setTimeout(() => {
                                dispatch({
                                    type: c.CHANGE_VIEW,
                                    data: d
                                });
                            }, 1000);
                            return false;
                        case "liveAgent":
                            return false;
                        default:
                            return true;
                    }
                });
            }
            if (message.isHala && !message.data.length) {
                return;
            }

            if (message.isHala && localStorage.getItem('textToSpeech') === 'true')
                textToSpeech(message)
            // clearMsgLoader();
            
            // if (_msgInProggress) {
            //     _tmpMsgs.push(message);
            // } else {
            const state = getState();
            if (!message.channel) message.channel = 'hala';
            if (
                (message.channel === state.chat.current)
                || (state.chat.current === 'hala' && message.channel === state.liveAgent.request.channel)
            ) {
                dispatch({ type: c.RECEIVE_MESSAGE,  message });
            } else {
                dispatch({ type: c.RECEIVE_MESSAGE_BACKGROUND,  payload: message });
            }
            // }
        });

        socket.on('recognizeResult', text => {
            dispatch(sendMessage(text));
        });

        socket.on('currentRequests', payload => dispatch({
            type: c.LA_currentRequests,
            payload
        }));

        socket.on('userConnected', payload => dispatch({
            type: c.userConnected,
            payload
        }));

        socket.on('userDisconnected', payload => dispatch({
            type: c.userDisconnected,
            payload
        }));

        socket.on('typing', payload => dispatch({
            type: c.userTyping,
            payload
        }));

        socket.on('requestUpdated', payload => {
            payload.currentUser = socket.io.opts.query.user;
            dispatch({
                type: c.LA_requestUpdated,
                payload
            });
        });

        socket.on('requestAssigned', payload => dispatch({
            type: c.LA_requestAssigned,
            payload
        }));

        socket.on('newAgentRequest', payload => dispatch({
            type: c.LA_newRequest,
            payload
        }));

        socket.on('requestStatus', payload => 
            dispatch(requestStatus(payload))
        );




    
        

        socket.on('startedTyping', (user) => {
            // let conversation = getState().conversations.activeConversation;
            // if(role === 'agent'){ // should be replaced when the role prop is added to the user object
            //     dispatch({
            //         type: c.USER_STARTED_TYPING,
            //         conversation,
            //         user
            //     })
            // } else {
            //     dispatch({
            //         type: c.STARTED_TYPING,
            //         user
            //     })
            // }
        });

        socket.on('stoppedTyping', (user) => {
            // let conversation = getState().conversations.activeConversation;
            // if(role === 'agent'){ // should be replaced when the role prop is added to the user object
            //     dispatch({
            //         type: c.USER_STOPPED_TYPING,
            //         conversation
            //     })
            // } else {
            //     dispatch({
            //         type: c.STOPPED_TYPING
            //     })
            // }
        });

    }
);

export const disconnect = () => (
    dispatch => {
        log.debug('disconnect');
        socket.disconnect();
    }
);

export const sendAudioStream = audioBytes => {
    socket.emit('audioStream', {
        audioBytes,
        languageCode: getLanguageCode()
    });
}

// let _tmpMsgs = [];
// let _msgInProggress = false;
// let _msgLoaderTimeout = null;
// function clearMsgLoader() {
//     // if (_msgLoaderTimeout) clearTimeout(_msgLoaderTimeout);
//     // _msgLoaderTimeout = null;
// }

export const sendMessage = (message, data, skipDispatch) => {
    return (dispatch, getState) => {
        if (!message) {
            return;
        }
        const st = getState();
        const isHala = st.chat.current === 'hala';
        if (isHala && st.liveAgent.request.blockInput) {
            return;
        }
        let payload = {
            id: uuid(),
            isHala: false,
            date: new Date().toISOString(),
            data: [{
                type: 'text',
                value: message
            }]
        };
        if (isHala && st.liveAgent.request.channel) {
            payload.channel = st.liveAgent.request.channel;
        } else if (!isHala && st.chat.current) {
            payload.channel = st.chat.current;
        }

        if (!isHala && data && data.cartData) {
            payload.channel = null;
        }

        if(data) payload.data.push(data);

        if (!skipDispatch) {
            dispatch({
                type: c.SEND_MESSAGE,
                payload
            });
            // clearMsgLoader()
            // _msgLoaderTimeout = setTimeout(() => {
            //     _msgInProggress = true;
                if (!payload.channel) {
                    dispatch({ type: c.MESSAGE_IN_PROGRESS });
                }
                // setTimeout(() => {
                    // _msgInProggress = false;
                    // _tmpMsgs.forEach(message => {
                        // dispatch({ type: c.RECEIVE_MESSAGE,  message });
                    // });
                    // _tmpMsgs = [];
                // }, 600);
            // }, 400);
        }
        socket.emit('userMessage', payload);
    }
}

const _closeSmartForm = (messageId, dataId) => ({
    type: c.CLOSE_FORM,
    data: {
        messageId,
        dataId
    }
});

export const submitSmartForm = (formResult, messageId, dataId) => {
     return dispatch => {
        dispatch(sendMessage('form Result', {
            formResult,
            type: 'formResult'
        }, true));
        dispatch(_closeSmartForm(messageId, dataId));
    }
}

export const closeSmartForm = (messageId, dataId) => {
    return dispatch => {
        dispatch(sendMessage('form Canceled', {
            formCanceled: true
        }, true));
        dispatch(_closeSmartForm(messageId, dataId));
    };
}

let clearHistoryTimeout = null;
export const clearHistory = () => (dispatch => {
    dispatch({ type: c.CLEARING_HISTORY_START });
    socket.emit('command', 'clearHistory');
    if (clearHistoryTimeout) {
        clearTimeout(clearHistoryTimeout);
    }
    clearHistoryTimeout = setTimeout(() => {
        clearHistoryTimeout = null;
        dispatch({ type: c.CLEARING_HISTORY_STOP });
    }, 1000);
});

export const sendQuickReply = (reply, dataId, messageId) => (dispatch => {
    dispatch(sendMessage(reply));
    dispatch({
        type: c.QUICK_REPLY_CLICKED,
        data: {
            messageId,
            dataId
        }
    });
});
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Chart from './Chart';
import PictureComponent from './PictureComponent';
import VideoComponent from './VideoComponent';
import FormComponent from '../Form/FormComponent';
import TextComponent from './TextComponent';
import MessageLogo from './MessageLogo';
import MessageSpinner from './MessageSpinner';
import classNames from'classnames';
import { connect } from 'react-redux';
import './Message.sass';
import FilePicker from './FilePicker';
import AvailableSkills from './AvailableSkills';
import SmartTable from '../../SmartTable';
import { getRequestById } from '../../../reducers/liveAgent/selectors';
import { charAt } from '../../../reducers/auth';

function Message({
    message, 
    msgRef,
    userFirstName,
    userLogo,
    agentId,
    agentFirstName,
    agentLogo
}) {
    const msg = useMemo(() => {
        if(message) {
            if (message.loading) 
                return [<MessageSpinner key={message.id}/>];
            return message.data
            .filter(el => !el.hide)
            .map((d,i) => {
                if(d.type === 'text') {
                    return (<TextComponent key={`${message.id}-${i}`} message={message} dataId={i} />);
                } else if(d.type === 'picture') {
                    return (<PictureComponent key={`${message.id}-${i}`} message={d} />);
                } else if(d.type === 'video') {
                    return (<VideoComponent key={`${message.id}-${i}`} message={d} />);
                } else if(d.type === 'form') {
                    return (<FormComponent key={`${message.id}-${i}`} message={message} dataId={i} />);
                } else if(d.type === 'chart') {
                    return (<Chart key={`${message.id}-${i}`} data={d.value} />);
                } else if (d.type === 'filePicker') {
                    return (<FilePicker key={`${message.id}-${i}`} data={d} />)
                } else if (d.type === 'availableSkills') {
                    return (<AvailableSkills key={`${message.id}-${i}`} data={d.data} />)
                } else if (d.type === 'table') {
                    return (<SmartTable key={`${message.id}-${i}`} data={d} />)
                } else return null;
            })
        } else {
            return <TextComponent key={message.id} message={message}/>
        }
    }, [message]);

    if (message.isHala && !msg.length) return null;

    let logo = userLogo, 
        firstName = userFirstName;
    let isAgent = message.user === agentId;
    if (isAgent) {
        logo = agentLogo;
        firstName = agentFirstName;
    } else if (message.agent) {
        logo = message.agent.logo;
        firstName = message.agent.firstName;
        isAgent = true;
    }

    return (
        <div 
            ref={msgRef} 
            className={classNames('message', {anim: message.anim})}
        >
            <div className="message-content">
                <MessageLogo isHala={message.isHala} logo={logo}/>
                <div className={`pa2${message.isHala || isAgent ? '' : ' bg-navy-near-white'}`}>
                    <h4 className="mt1 mb2">{ message.isHala ? 'Hala' : firstName }</h4>
                    { 
                        message.date
                        ? (<span className="message-date">{moment(message.date).format("hh:mm a")}</span>) 
                        : null
                    }
                    { msg }
                </div>
            </div>
        </div>
    )
}

Message.propTypes = {
    message: PropTypes.object.isRequired,
    userFirstName: PropTypes.string.isRequired,
    userLogo: PropTypes.string.isRequired
}

export default connect(
    state => {
        let agentId = '',
            agentFirstName = '',
            agentLogo = '';

        if (state.chat.current === 'hala' && state.liveAgent.request.agent.id) {
            agentId = state.liveAgent.request.agent.id;
            agentFirstName = state.liveAgent.request.agent.firstName;
            agentLogo = state.liveAgent.request.agent.logo;
        } else if (state.chat.current !== 'hala') {
            const req = getRequestById(state, state.chat.current);
            if (req) {
                agentId = req.user;
                agentFirstName = req.firstName;
                agentLogo = charAt(req.firstName) + charAt(req.lastName)
            }
        }
        return {
            userFirstName: state.auth.user.firstName,
            userLogo: state.auth.user.logo,
            agentId,
            agentFirstName,
            agentLogo
        };
    }, 
    {}
)(Message);
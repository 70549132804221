import React from 'react';

export default {
    "et":{
      "searchPlaceholder": "Otsing ...",
        "createNew": "Loo uus",
        "no": "Ei",
        "yes": "Jah",
        "edit": "Muuda",
        "delete": "Kustuta",
        "add": "Lisa",
        "details": "Andmed",
        "cancel": "Tühista",
        "areYouSure":"Oled sa kindel?",
        "createdOn": "Loodud:",
        "sended": "saadetud",
        "draft": "mustand",
        "cart": {
            "symbols": "sümbolid",
            "are_not_allowed": "ei ole lubatud",
            "invalidValue": "Vigane väärtus",
            "requesterName": "Taotleja nimi",
            "requisitionId": "Ostutaotlus ID",
            "attachment": "Manus",
            "attachFileBtn": "Manusta fail",
            "errorFileFormat": "",
            "emptyProducts": "Ostutaotlusel puuduvad tooted",
            "requiredDescription": "Lisa ostutaotlusele pealkiri",
            "requiredDate": "Lisa soovitud tarnekuupäev",
            "emptyProductsDetails": "Ostutaotluse ridadel on täitmata kohustuslikud finantsdimensioonid",
            "unsavedConfirmation": "Sul on salvestamata muudatused oma ostutaotlusel! Kas olete kindel, et soovite ostutaotluse sulgeda?",
            "totalAmount": "Kogu summa",
            "item": "Kaup",
            "items": "Rida",
            "submittedOn": "Esitatud",
            "sendPr": "Saada ostutaotlus",
            "saveDraft": "Salvesta mustandina",
            "or": "või",
            "itemId":"Kaubakood",
            "category": "Kategooria",
            "contractNo": "Coupa leping",
            "contractNoId": "Coupa leping kood",
            "description": "Kirjeldus PO-le",
            "descriptionInfo": "Ostutellimuse rea kirjeldus",
            "reason": "Põhjus",
            "reasonInfo": "Põhjus on valitav, kui dimensioon on kohustuslik (tähistatud *-ga).",
            "requiredInfo": "Märgitud väljad on kohustuslikud",
            "segmentInfo": "Vajadusel vali segment.",
            "departmentInfo": "Vaikimisi sinu osakond. Kui tellid kellelegi teisele, siis muuda osakond vastavalt.",
            "supplierInfo": "Vali, kui tead hankijat. Kui ei tea, jäta tühjaks.",
            "costCenterInfo": (
                <>
                    Vajadusel leiad kulukeskuse <a href="https://cs.telia.ee/llisapi.dll?func=ll&objId=92168662&objAction=Open&nexturl=%2Fllisapi%2Edll%3Ffunc%3Dll%26objId%3D80992198%26objAction%3Dbrowse" target="_blank" rel="noopener noreferrer">SIIT</a> või pöördu ärianalüüsi poole.
                </>
            ),
            "costOfGoods": "Kaup kuluks",
            "costsValidation": "Kulu kinnitamine",
            "priceInfo": "Kui hinda ei tea, jäta tühjaks.",
            "segment": "Segment",
            "department": "Osakond",
            "departmentId": "Osakonna kood",
            "supplier": "Tarnija",
            "supplierId": "Tarnija kood",
            "costCenter": "Kulukeskus",
            "costCenterId": "Kulukeskuse kood",
            "fixedAssetSet": "PV Kogum", 
            "fixedAssetSetId": "PV Kogum kood",
            "statusReady": "Valmis",
            "statusMissedInfo": "Täitmata info",
            "quantity":"Kogus",
            "price": "Hind",
            "addProducts": "Lisa tooteid",
            "filterUserTag": "@kasutaja:",
            "filterSendedTag": "@olek:",
            "filterSended": "saadetud",
            "filterDraft": "mustand",
            "ordersTitle": "Minu ostutaotlused",
            "cartTitle": "Ostutaotluse päise teave",
            "cartNotes": "Kirjeldus ostuosakonnale",
            "cartDescription": "Pealkiri",
            "cartDeliveryDate": "Soovitud tarnekuupäev",
            "productsTitle": "Tootekataloog",
            "menuOrders": "Minu ostutaotlused",
            "menuProducts": "Tootekataloog",
            "menuCart": "Ostukorv",
            "menuClose": "Sulge",
            "categories": "Hankekategooriad"
        },
        "helloMessage": {
            "title": "Tere",
            "description": "Kuidas saaksin Sulle abiks olla?",
            "startWith": "Või alusta:",
            "inoutPlaceholder": "Sisesta oma sõnum ...",
            "quickMessages": [
                "Uus ostutaotlus", 
                "Minu ostutaotlused", 
                "Muuda viimast ostutaotlust", 
                "Näita tootekataloogi", 
                "Kuidas luua uus ostutaotlus?"
            ]
        },
        "inputMessage": {
            "placeholderWait": "Oota Hala vastust ...",
            "placeholder": "Sisesta oma sõnum ..."
        },
        "tableItemDetails": {
            "title": "Detailed information"
        },
        "formComponent": {
            "fiterTitle":"Displaying fields:",
            "fiterAll": "All",
            "fiterRequired": "Required only",
            "fiterOptimal": "Optimal set",
            "save":"Save",
            "cancel":"Cancel"
        }
    },
    "en": {
        "signIn": "Sign In",
        "searchPlaceholder": "Search ...",
        "createNew": "Create New",
        "no": "No",
        "yes": "Yes",
        "edit": "Edit",
        "delete": "Delete",
        "add": "Add",
        "details": "Details",
        "cancel": "Cancel",
        "areYouSure":"Are you sure?",
        "createdOn": "Created on:",
        "created": "created",
        "sended": "sended",
        "draft": "draft",
        "offlineTitle": "You are Offline!",
        "offlineDescription": "Please check your Internet connection.",
        "statuses": {
            "online": "Online",
            "offline": "Offline",
            "assignedToMe": "Assigned",
            "notAssigned": "Not assigned",
            "closed": "Closed"
        },
        "liveAgent": {
            "emptyList": "No Items",
            "requestTitle": "Request description",
            "takeRequest": "Take this request",
            "assignRequestNotExist": "It's looks like this request was taken by another agent!",
            "cancelRequest": "Cancel request",
            "closeRequest": "Close request",
            "responseTimeTitle":"Approximately response time"
        },
        "cart": {
            "symbols": "symbols",
            "are_not_allowed": "are not allowed",
            "invalidValue": "Invalid Value",
            "requesterName": "Requester Name",
            "requisitionId": "Requisition ID",
            "attachmentInfo": "Please be aware, that currently, you can upload any files and you will see them in the drafts, but we are sending to the Dynamics only documents in Microsoft Word format. In the future, we will add the possibility to submit any type of files to Dynamics.",
            "attachment": "Attachment",
            "attachFileBtn": "Attach File",
            "errorFileFormat": "Invalid file format, should be .doc or .docx",
            "emptyProducts": "Purchase requisition is missing products",
            "requiredDescription": "Purchase Description is required",
            "requiredDate": "Purchase Date is required",
            "emptyProductsDetails": "Purchase requisition products is missing required information",
            "unsavedConfirmation": "You have unsaved changes in your Purchase requisition! Are you sure?",
            "totalAmount": "Total Amount",
            "item": "item",
            "items": "items",
            "submittedOn": "Submitted on",
            "sendPr": "Send purchase requisition",
            "saveDraft": "Save as a Draft",
            "or": "or",
            "itemId":"item ID",
            "category": "Category",
            "contractNo": "Coupa contract",
            "contractNoId": "Coupa contract ID",
            "description": "Description",
            "descriptionInfo": "Description of the purchase order",
            "reason": "Reason",
            "reasonInfo": "The reason is optional if the dimension is mandatory (marked with *).",
            "requiredInfo": "Marked fields are mandatory",
            "segmentInfo": "Select a segment if necessary.",
            "departmentInfo": "By default, your department. If you order someone else, the department will change accordingly.",
            "supplierInfo": "Choose when you know the vendor. If you don't know, leave it blank.",
            "costCenterInfo": (
                <>
                    If necessary, you will find a cost center <a href="https://cs.telia.ee/llisapi.dll?func=ll&objId=92168662&objAction=Open&nexturl=%2Fllisapi%2Edll%3Ffunc%3Dll%26objId%3D80992198%26objAction%3Dbrowse" target="_blank" rel="noopener noreferrer">FROM HERE</a> or contact a business analysis.
                </>
            ),
            "costOfGoods": "The cost of the goods",
            "costsValidation": "Validation of costs",
            "priceInfo": "If you don't know the price, leave it blank.",
            "segment": "Segment",
            "department": "Department",
            "departmentId": "Department ID",
            "supplier": "Supplier",
            "supplierId": "Supplier ID",
            "costCenter": "Cost Center",
            "costCenterId": "Cost Center ID",
            "statusReady": "Ready",
            "statusMissedInfo": "Missed info",
            "quantity":"Quantity",
            "price": "Price",
            "addProducts": "Add Products",
            "filterUserTag": "@user:",
            "filterSendedTag": "@status:",
            "filterSended": "sended",
            "filterDraft": "draft", 
            "ordersTitle": "My Orders",
            "cartTitle": "Purchase requisition Header Information",
            "cartNotes": "Notes",
            "cartDescription": "Description",
            "cartDeliveryDate": "Requested Delivery Date",
            "productsTitle": "Product Catalogue",
            "menuOrders": "My Orders",
            "menuProducts": "Product Catalogue",
            "menuCart": "Shopping Cart",
            "menuClose": "Close Purchase Requisition",
            "categories": "Categories"
        },
        "helloMessage": {
            "title": "Dear",
            "description": "Welcome to the Hala Digital Assistant.",
            "startWith": "Or start with:",
            "inoutPlaceholder": "Enter your message...",
            "quickMessages": [
                "How to find missed authorization objects?",
                "Get balance for account",
                "Get expenses report by cost center",
                "Create new user",
                "Add new role",
                "Unblock user",
                "Create incident"
            ]
        },
        "inputMessage": {
            "placeholderWait": "Please wait for Hala response...",
            "placeholder": "Type your message..."
        },
        "tableItemDetails": {
            "title": "Detailed information"
        },
        "formComponent": {
            "fiterTitle":"Displaying fields:",
            "fiterAll": "All",
            "fiterRequired": "Required only",
            "fiterOptimal": "Optimal set",
            "save":"Save",
            "cancel":"Cancel"
        }
    },
    "de": {
        "helloMessage": {
            "title": "Sehr geehrter",
            "description": "Willkommen beim Hala Digital Assistant.",
            "startWith": "Oder fange mit an:",
            "inoutPlaceholder": "Gib deine Nachricht ein...",
            "quickMessages": [
                "Sagen Sie mir den liefertermin für den kundenauftrag",
                "Sagen Sie mir die Verfügbarkeit von Material",
                "Was ist der Sicherheitsbestand von Material",
                "Sagen Sie mir den Lagerort für Material",
                "Offene Rechnungen für Kunden",
                "Offene Posten für Kunden",
                "Ist die Rechnung für Verkaufsauftrag bereits bezahlt?",
                "Setze mein Passwort zurück",
                "Benutzer blockieren",
                "Nutzer entblockieren"
            ]
        },
        "inputMessage": {
            "placeholder": "Schreiben Sie ihre Nachricht hier...",
            "placeholderWait": "Bitte warten Sie auf die Antwort von Hala..."
        },
        "tableItemDetails": {
            "title": "Ausführliche Informationen"
        },
        "formComponent": {
            "fiterTitle":"Felder anzeigen:",
            "fiterAll": "Alles",
            "fiterRequired": "Nur erforderlich",
            "fiterOptimal": "Optimal eingestellt",
            "save":"Save",
            "cancel":"Cancel"
        }
    }
};
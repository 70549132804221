import {
    purchaseOpenItem,
    purchaseCloseItem,
    purchaseEditItem,
    purchaseAddItem,
    purchaseDeleteItem,
    purchaseItemQty,
    purchaseClean,
    purchaseEditOrder,
    purchaseCreateOrder
} from '../../constants';
import uuid from 'uuid/v1';

const init = {
    items: [],
    hasChanges: false,
    edit: null
};
export const isEqual = (i, ii) => i.cartId === ii.cartId;

const openItem = (state, payload) => {
    return {
        ...state,
        edit: {
            ...payload
        }
    };
};

const editItem = (state, payload) => {
    return {
        ...state,
        items: state.items.map(i => {
            if (isEqual(i, payload)) {
                return {
                    ...i,
                    ...payload
                };
            }
            return i;
        }),
        hasChanges: true,
        edit: null
    };
};

const addItem = (state, payload) => {
    return {
        ...state,
        items: [
            ...state.items,
            {
                ...payload,
                cartId: uuid()
            }
        ],
        hasChanges: true,
        edit: null
    };
};

const deleteItem = (state, payload) => {
    return {
        ...state,
        items: state.items.filter(i => !isEqual(i, payload)),
        hasChanges: true,
        edit: null
    };
};

const changeQty = (state, payload, qty) => {
    return {
        ...state,
        hasChanges: true,
        items: [
            ...state.items.map(i => {
                if (isEqual(i, payload)) {
                    return {
                        ...i,
                        qty: qty || 1
                    };
                }
                return i;
            })
        ]
    };
};

export default (state = init, {type, payload, qty}) => {
    switch(type) { 
        case purchaseOpenItem:
            return openItem(state, payload);
        case purchaseCloseItem:
            return {
                ...state,
                edit: null
            };
        case purchaseEditItem:
            return editItem(state, payload);
        case purchaseAddItem: 
            return addItem(state, payload);
        case purchaseItemQty: 
            return changeQty(state, payload, qty);
        case purchaseDeleteItem:
            return deleteItem(state, payload);
        case purchaseClean: 
        case purchaseCreateOrder:
            return init;
        case purchaseEditOrder: 
            return {
                ...state,
                hasChanges: false,
                items: payload.items
            };
        default: 
            return state;
    }
};
import React, { useRef, useEffect } from 'react';
import {
  Route,
  Switch,
  BrowserRouter as Router
} from 'react-router-dom';
import { connect as conn } from 'react-redux';
import PropTypes from 'prop-types';
import Head from '../Head';
import {
  connect,
  disconnect
} from '../../actions/socket';
import './index.sass';
import Amplify from '@aws-amplify/core';
import LegalInformation from '../LegalInformation';
import ContactUs from '../ContactUs';
import DynamicView from '../DynamicView';
import LiveAgentStatus from '../LiveAgent/Status';

const log = new Amplify.Logger(':::App:::');
function App({connect, disconnect}) {
    log.debug('render');

    const layoutRef = useRef(null);
    useEffect(() => {
      connect();
      return () => disconnect();
      // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, []);
    
    return (
      <Router>
        <React.Fragment>
          <div ref={layoutRef} className="page-layout">
            <Head />
            <div className="layout-wrap flex-auto">
              <Switch>
                <Route exact path="/contact-us" component={ ContactUs } />
                <Route exact path="/legal-information" component={ LegalInformation } />
                <Route exact path="/" component={() => <DynamicView layoutRef={layoutRef}/> } />
              </Switch> 
            </div>
          </div>
          <LiveAgentStatus/>
        </React.Fragment>
      </Router>
    );
}

App.propTypes = {
  connect: PropTypes.func.isRequired,
  disconnect: PropTypes.func.isRequired
}

export default conn(
  null,
  {
    connect, 
    disconnect
  }
)(App);

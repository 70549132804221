import dotProp from 'dot-prop-immutable';
import { 
    LA_filterOnline,
    LA_filterAssigned,
    LA_filterNotAssigned,
    LA_currentRequests,
    userDisconnected,
    userConnected,
    LA_requestUpdated,
    LA_newRequest,
    SIGNED_OUT,
    DISCONNECTED
} from '../../constants';

const upCounters = state => {
    let online = 0;
    let assigned = 0;
    let notAssigned = 0;

    const { counters, requests: { items } } = state;

    items.forEach(item => {
        if (item.online) online++;
        if (item.id !== 'hala') {
            if (item.agent) assigned++;
            else notAssigned++;
        }
    });

    if (counters[LA_filterOnline] !== online) state = dotProp.set(
        state,
        `counters.${LA_filterOnline}`,
        online
    );
    if (counters[LA_filterAssigned] !== assigned) state = dotProp.set(
        state,
        `counters.${LA_filterAssigned}`,
        assigned
    );
    if (counters[LA_filterNotAssigned] !== notAssigned) state = dotProp.set(
        state,
        `counters.${LA_filterNotAssigned}`,
        notAssigned
    );
    return state;
};

export default (state, { type }) => {
    switch(type) {
        case LA_currentRequests:
        case userDisconnected:
        case userConnected:
        case LA_requestUpdated:
        case LA_newRequest:
        case SIGNED_OUT:
        case DISCONNECTED:
            return upCounters(state);
        default:
            return state;
    }
};
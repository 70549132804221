import React, { memo } from 'react';
import { Logger } from '@aws-amplify/core';
import Notifications from '../components/Notifications';
import Authenticator from './Authenticator';
import OAuth from './OAuth';
import config from '../config';

const log = new Logger(':::App Container:::'); 
const isOAuth = config.aws.Auth.oauth ? true : false;

function AppContainer() {
    log.debug('render');
    return (
        <React.Fragment>
            <Notifications/>
            <p className="white o-40 rotate-270 lh-solid absolute fixed f6 aa"     
                style={{
                    bottom: "45px",
                    right: "-35px",
                    zIndex: "-1"
                }}>Powered by Hala</p>
            {
                isOAuth
                ? <OAuth/>
                : <Authenticator/>
            }
        </React.Fragment>
    );
}

export default memo(AppContainer);
import React, { memo, useMemo } from 'react';
import Amplify from '@aws-amplify/core';
import { connect } from 'react-redux';
import { getRequestById } from '../../../reducers/liveAgent/selectors';
import PropTypes from 'prop-types';
import t from '../../../i18';
import LiveDate from '../../../components/LiveDate';

const log = new Amplify.Logger(':::Online Details:::');

function OnlineDetails({online, lastLogin, lastLogout}) {
    log.debug('render');

    const { status, date } = useMemo(() => {
        return {
            status: t(online ? 'statuses.online' : 'statuses.offline'), 
            date: online ? lastLogin : lastLogout
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [online]);

    return useMemo(() => (
        <div className="online-details">
            { status } {
                date
                ? <LiveDate date={date}/>
                : null
            }
        </div>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [online]);
}

OnlineDetails.propTypes = {
    online: PropTypes.bool.isRequired
}

export default memo(OnlineDetails);

export function connectOnlineDetails(id) {
    return connect( 
        state => {
            const item = getRequestById(state, id);
            return {
                online: item.online,
                lastLogin: item.lastLogin,
                lastLogout: item.lastLogout
            };
        },
        {}
    )(OnlineDetails);
}
import { 
    purchaseInit
} from '../../constants';

const init = {
    departmentName: '',
    departmentCode: ''
};

export default (state = init, { type, payload }) => {
    switch(type) {
        case purchaseInit:
            return {
                ...state,
                ...payload.department
            };
        case 'invalidDepartment': 
            return {
                ...init
            };
        default:
            return state;
    }
};
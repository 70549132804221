import React, { memo, useCallback, useMemo, useRef } from 'react';
import Amplify from '@aws-amplify/core';
import {
    Dropdown,
    Menu,
    Icon
} from 'antd';
import { useOpenView, useClosePurchase } from '../../../actions/purchaseRequisition';
import { 
    purchaseViewOrders, 
    purchaseViewProducts,
    purchaseViewCart 
} from '../../../constants';
import t from '../../../i18';

const log = new Amplify.Logger(':::CartMenu:::');

const items = ['orders', 'products', 'cart']; 

export default memo(({current, products = true}) => {
    log.debug('render');
    const ref = useRef(null);
    const showOrders = useOpenView(purchaseViewOrders);
    const showProducts = useOpenView(purchaseViewProducts);
    const showCart = useOpenView(purchaseViewCart);
    const closePurchase = useClosePurchase();
    const onClick = useCallback(e => {
        if (e.domEvent) {
            e.domEvent.stopPropagation();
            e.domEvent.preventDefault();
            switch(e.key) {
                case 'orders':
                    showOrders();
                    break;
                case 'products':
                    showProducts();
                    break;
                case 'cart':
                    showCart();
                    break;
                case 'close':
                    closePurchase();
                    break;
                default:
                    break;
            }
        } else {
            e.stopPropagation();
            e.preventDefault();
        }
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, []);
    const menu = useMemo(() => (
        <Menu onClick={onClick}>
            {
                items
                .filter(i => {
                    if (i === 'products' && !products) return false;
                    switch(current) {
                        case 'orders':
                            return false;
                        case 'products':
                        case 'cart':
                            return (i !== current);
                        default:
                            return false;
                    }
                })
                .map(i => {
                    switch(i) {
                        case 'orders':
                            return (<Menu.Item key="orders"><Icon type="file-search" />{t('cart.menuOrders')}</Menu.Item>);
                        case 'products':
                            return (<Menu.Item key="products"><Icon type="bars" />{t('cart.menuProducts')}</Menu.Item>);
                        case 'cart':
                            return (<Menu.Item key="cart"><Icon type="shopping" />{t('cart.menuCart')}</Menu.Item>);
                        default:
                            return null;
                    }
                })
            }
            <Menu.Divider />
            <Menu.Item key="close"><Icon type="export" />{t('cart.menuClose')}</Menu.Item>
        </Menu>
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    ),[onClick, current]);

    return (
        <div ref={ref} className="cart-menu">
            <Dropdown 
                onClick={onClick} 
                overlay={menu} 
                placement="bottomRight"
                getPopupContainer={ref.current ? () => ref.current : null}
            >
                <Icon type="align-left" />
            </Dropdown>
        </div>
    );
});
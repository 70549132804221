import Polly from 'aws-sdk/clients/polly';
import config from '../config';
import Auth from '@aws-amplify/auth';
import { getLocale } from '../i18';

let _AudioContext = window.AudioContext || window.webkitAudioContext || false; 
let context = null;
let buf;
let source;

export async function unlockContext() {
    document.body.addEventListener('touchstart', initContext, false);
    document.body.addEventListener('touchend', initContext, false);
    document.body.addEventListener('click', initContext, false);
}

export async function initContext() {
    if (!context && _AudioContext) {
        context = new _AudioContext();
        context.resume().then(() => {
            document.body.removeEventListener('touchstart', initContext);
            document.body.removeEventListener('touchend', initContext);
            document.body.removeEventListener('click', initContext);
        });
    }
}

export async function closeContext() {
    if (context) {
        context.close();
        context = null;
    }
    stop();
}

function play() {
    stop();
    source = context.createBufferSource();
    source.buffer = buf;
    source.connect(context.destination);
    source.start(0);
}

export function stop() {
    if (source) {
        source.stop(0);
        source.disconnect();
        source = null;
    }
}

function strip(html) {
   var tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

export async function textToSpeech(message) {
    if (!context) return;
    let Text = '';
    message.data.forEach(el => {
        if (el.type === 'text') {
            if (typeof el.value === 'string')
                Text += strip(el.value)
            else Text += el.value.map(e => strip(e)).join('<break time="2s"/>');
        }
    });

    if (!Text.length) return;
    Text = `<speak>${Text}</speak>`;

    let _conf = {};
    if (getLocale() === 'de') {
        _conf.LanguageCode = "de-DE";
        _conf.VoiceId = "Vicki";
    }

    const cred = await Auth.currentCredentials();
    const speech = await new Polly({
        region: config.aws.aws_project_region || 'eu-central-1',
        credentials: Auth.essentialCredentials(cred)
    }).synthesizeSpeech({
        OutputFormat: "mp3",
        Text,
        TextType: "ssml",
        VoiceId:  "Salli",
        ..._conf
    }).promise();

    context.decodeAudioData(speech.AudioStream.buffer, buffer => {
        buf = buffer;
        play();
    });
}
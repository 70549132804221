import MediaStreamRecorder from 'msr';

let stream;
let recorder

export async function getMediaStream() {
    return new Promise(async (resolve, reject) => {
        if (stream) return reject('IN_PROGRESS');
        stream = await navigator.mediaDevices.getUserMedia({ 
            audio: true, 
            video: false 
        });
        recorder = new MediaStreamRecorder(stream);
        recorder.mimeType = 'audio/wav';
        recorder.audioChannels = 1;
        recorder.ondataavailable = blob => {
            stopRecording();
            var reader = new FileReader();
            reader.readAsDataURL(blob);     
            reader.onloadend = () => {
                let base64 = reader.result.replace(/^data:audio\/wav;base64,/, "");         
                resolve(base64);
            }
        };
        recorder.start(5000);
    });
}

export async function stopRecording() {
    if (recorder) {
        recorder.stop();
        recorder = null;
    }
    if (stream) {
        let tracks = stream.getAudioTracks();
        for(let i = tracks.length - 1; i >= 0; --i) {
            tracks[i].stop();
        }
        stream = null;
    }
}


    // window.SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition || null;
    // console.log('SpeechRecognition', window.SpeechRecognition);
    // let finalTranscript = '';
    // let recognition = new window.SpeechRecognition();
    // recognition.interimResults = true;
    // recognition.maxAlternatives = 10;
    // recognition.continuous = true;
    // recognition.onresult = (event) => {
    //   let interimTranscript = '';
    //   for (let i = event.resultIndex, len = event.results.length; i < len; i++) {
    //     let transcript = event.results[i][0].transcript;
    //     if (event.results[i].isFinal) {
    //       finalTranscript += transcript;
    //     } else {
    //       interimTranscript += transcript;
    //     }
    //   }
    //   console.log('!!!!', finalTranscript, interimTranscript);
    // }
    // recognition.start();

import React, { memo, useCallback, useState, useMemo, useEffect } from 'react';
import renderEmpty from 'antd/lib/config-provider/renderEmpty';
import { Icon, Select } from 'antd';
import { useAction } from '../../actions/fetcher';
import classnames from 'classnames';
import t from '../../i18';
import './index.sass';

const Option = Select.Option;
const Empty = renderEmpty('Select');
const Loading = <Icon type="loading"/>;

function useActionParams(action, item) {
    return useMemo(() => {
        if (action === '5c8a305dd26cf7183ae2db62') {
            return {
                path: item.path,
                modelGroupId: item.modelGroupId
            };
        }
        return {};
    }, [action, item]);
}

export default memo(({
    action,
    optionKey,
    optionName,
    onChange,
    value,
    placeholder,
    infoContent = null,
    item
}) => {
    const _value = value[optionName];
    const _key = value[optionKey];
    const [init, onInit] = useState(false);
    const [search, setSearch] = useState('');
    const [{error}, _setError] = useState({
        error: null,
        errorId: null
    });
    const setError = useCallback(error => {
        _setError(({errorId}) => {
            if (errorId) clearTimeout(errorId);
            return {
                error,
                errorId: error ? setTimeout(() => _setError({
                    error: null,
                    errorId: null
                }), 3000) : null
            };
        });
    }, []);
    const [fetch, setFetch] = useState(false);

    const actionParams = useActionParams(action, item);
    const { data, loading, fetched } = useAction({
        action,
        fetch,
        params: actionParams
    });
    
    const { 
        data: validateData, 
        loading: validateLoading,
        fetched: validateFetched
    } = useAction({
        action, 
        fetch: !fetched && _value && init ? true : false, 
        params: {
            // search: _value
            search: _key,
            ...actionParams
        }
    });

    const onSearch = useCallback(v => setSearch(v), []);
    const dataSource = useMemo(() => {
        if (loading) return [];
        let _data = [...data];
        if (!_data.length && value[optionKey]) {
            _data.push(value);
        }
        if (search) {
            _data = _data.filter(d => {
                const _search = search.toLowerCase();
                if (d[optionName].toLowerCase().indexOf(_search) !== -1
                || d[optionKey].toLowerCase().indexOf(_search) !== -1) {
                    return true;
                }
                return false;
            });
        }
        _data = _data.slice(0, 100);
        return _data.map(d => {
            let name = d[optionName];
            let key = d[optionKey];
            if (search) {
                name = name.replace(new RegExp(`(${search})`, 'ig'), '<b>$1</b>').split('<b>');
                key = key.replace(new RegExp(`(${search})`, 'ig'), '<b>$1</b>').split('<b>');
                name = name.map((d,k) => {
                    if (d.indexOf('</b>') !== -1) {
                        d = d.split('</b>');
                        return (
                            <span key={`${k}-1`}>
                                <b>{d[0]}</b>{d[1]}
                            </span>
                        )
                    }
                    return d;
                });
                key = key.map((d,k) => {
                    if (d.indexOf('</b>') !== -1) {
                        d = d.split('</b>');
                        return (
                            <span key={`${k}-1`}>
                                <b>{d[0]}</b>{d[1]}
                            </span>
                        )
                    }
                    return d;
                });
            }
            return (
                <Option key={d[optionKey]}>
                    <div>{name}</div>
                    <div className="desc">
                        {key}
                        {infoContent ? infoContent(d) : null}
                    </div>
                </Option>
            )
        })
    }, [search, data, optionKey, optionName, value, loading, infoContent]);
    const _getRes = useCallback(key => 
        data.find(el => el[optionKey] === key)
    , [data, optionKey]);
    const _onChange  = useCallback(val => {
        if (onChange) onChange(val ? _getRes(val) : null)
        onSearch('');
        setError(null);
    }, [onChange, _getRes, onSearch, setError]);
    const _onFocus  = useCallback(() => {
        setFetch(true);
        setError(null);
    }, [setError]);

    useEffect(() => {
        if (_value) {
            let _data = null;
            if (fetched) _data = data;                
            else if (validateFetched) _data = validateData;
            if (_data) {
                if (!_data.length || !_data.find(el => 
                    el[optionKey]?.toLowerCase() === _key?.toLowerCase()
                    // && el[optionName]?.toLowerCase() === _value?.toLowerCase()
                    )
                ) {
                    _onChange();
                    setError(`${t('cart.invalidValue')}: ${_value}`);
                } else {
                    setError(null);
                }
            }
        }
    }, [
        _value, _key, optionKey, optionName, fetched, setError,
        validateData, validateFetched, _onChange, data
    ]);

    useEffect(() => () => setError(null), [setError]);

    useEffect(() => {
        const tId = setTimeout(() => onInit(true), 100);
        return () => clearTimeout(tId);
    }, [])

    return (
        <>
            <Select
                className={classnames("data-selector", {
                    "has-error": error
                })}
                dropdownClassName="data-selector-dropdown"
                showSearch
                loading={validateLoading}
                value={_value}
                placeholder={placeholder}
                notFoundContent={loading ? Loading : Empty}
                filterOption={false}
                onSearch={onSearch}
                onChange={_onChange}
                onFocus={_onFocus}
                allowClear={_value ? true : false}
            >
                { dataSource }
            </Select>
            {
                error ? (
                    <div className="error-message">{error}</div>
                ) : null
            }
        </>
    )
})
import locales from './locales';
import _get from 'lodash/get';
import moment from 'moment';
import 'moment/locale/et';

const defData = locales['en'];
let locale = 'en', 
languageCode = 'en-US',
data; 

const setLocale = l => {
    locale = l;
    data = locales[locale] || defData;
    languageCode = `${l}-${l.toUpperCase()}`;
    switch(l) {
        case 'en':
            languageCode = 'en-US';
            break;
        case 'et':
            break;
        default:
            return;      
    }
    moment.locale(l);
}

let prefix = 'telia';
if (prefix && prefix === 'telia') setLocale('et');
else setLocale('en');

export default (key, paraps) => {
    let val = _get(data, key);
    if (val) return val;
    return _get(defData, key) || '';
}

export function getLocale() {
    return locale;
}

export function getLanguageCode() {
    return languageCode;
}
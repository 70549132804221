import {
    LA_changeFilter,
    LA_setCurrentChat,
    LA_assignRequest,
    LA_assignRequestSuccess,
    LA_assignRequestError,
    LA_requestStatus,
    LA_cancelRequest,
    LA_closeRequest,
    LA_requestTimeout
} from '../constants';
import { error } from './errors';
import API from '@aws-amplify/api';
import { getErrorMessage } from '../reducers/notifications';
import t from '../i18';

export function requestStatus(payload) {
    return {
        type: LA_requestStatus,
        payload
    };
}

async function changeStatus(id, status) {
    try {
        await API.post('sapi', `/live-agent/request/${id}/status`, {
            body: {status}
        });
    } catch (err) {
        return error(err);
    }
    return null;
}

export function requestTimeout() {
    return async (dispatch, getState) => {
        const id = getState().liveAgent.request.channel;
        dispatch({type: LA_requestTimeout});
        const data = await changeStatus(id, 'timeout');
        if (data) dispatch(data);
    };
}

export function cancelRequest() {
    return async (dispatch, getState) => {
        const id = getState().liveAgent.request.channel;
        dispatch({type: LA_cancelRequest});
        const data = await changeStatus(id, 'canceled');
        if (data) dispatch(data);
    };
}

export function closeRequest() {
    return async (dispatch, getState) => {
        const id = getState().liveAgent.request.channel;
        dispatch({type: LA_closeRequest});
        const data = await changeStatus(id, 'closed');
        if (data) dispatch(data);
    };
}

export function changeFilter(payload) {
    return {
        type: LA_changeFilter,
        payload
    };
}

export function setCurrentChat(payload) {
    return {
        type: LA_setCurrentChat,
        payload
    };
}

export function assignRequest(id) {
    if (!id) return null;
    return async dispatch => {
        dispatch({type: LA_assignRequest, payload: id});
        try {
            await API.post('sapi', `/live-agent/request/${id}/assign`);
            dispatch({type: LA_assignRequestSuccess, payload: { id }});
        } catch (err) {
            let str = getErrorMessage(err);
            switch(str) {
                case 'not_exist':
                    str = t('liveAgent.assignRequestNotExist');
                    break;
                default:
                    return;
            }
            dispatch({type: LA_assignRequestError, payload: {
                id, error: str
            }});
            dispatch(error(str));
        } 
    };
}
import {
    purchaseOrdersSearch,
    purchaseFetchOrdersSuccess,
    purchaseFetchOrders,
    purchaseClean,
    purchaseDeleteOrder,
    purchaseOrdersClean
} from '../../constants';

const init = {
    items: [],
    loading: true
};

export default (state = init, {type, payload}) => {
    switch(type) { 
        case purchaseFetchOrders:
            return {
                ...state,
                loading: true
            };
        case purchaseDeleteOrder:
            return {
                ...state,
                items: [
                    ...state.items.filter(i => i.id !== payload.id)
                ]
            };
        case purchaseFetchOrdersSuccess:
            return {
                items: [
                    ...state.items,
                    ...payload
                ],
                loading: false,
            };
        case purchaseOrdersClean:
        case purchaseOrdersSearch: 
        case purchaseClean:
            return init;
        default: 
            return state;
    }
};
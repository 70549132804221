import React, { memo } from 'react';
import Amplify from '@aws-amplify/core';
import Filter from './Filter';
import RequestsList from './RequestsList';
import './index.sass';

const log = new Amplify.Logger(':::LiveAgent:::');

function LiveAgent() {
    log.debug('render');
    return (
        <div className="live-agent">
            <Filter/>
            <RequestsList/>
        </div>
    )
}

export default memo(LiveAgent);
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import FieldDescription from './FieldDescription';
import './TextInputField.sass';

const { TextArea } = Input;

class LongTextInput extends React.Component {

    setFieldValue = (field, s) => {
        this.props.setFields({
            [field]: {
              value: s,
              errors: [],
            },
          });
    }

    render(){
        const { field, getFieldDecorator, errorMessage } = this.props;

        return(
            <div className="mt3">
                <label className="b f6">{field.label}:</label>
                {   field.optimal && JSON.parse(field.optimal) 
                    ? (<span className="fr f6 o-60">Optional</span>) 
                    : null
                }
                {
                    getFieldDecorator(field.key, {...field.validationOptions,
                        validateTrigger: "onChange",
                        onChange: getFieldDecorator.handleInputChange,
                    })(<TextArea className="w-100 mt1 pv2 ph2 f5" autoSize={{ minRows: 2, maxRows: 6 }} />)
                }
                {
                    field.suggestion ? (
                        <div className="mt1 f6 o-60 field-suggestions"><span>{field.suggestion.title}:</span>
                        {
                            field.suggestion.options ? (field.suggestion.options.map( (o,i) => (
                                <span key={`sugg-${i}`}>
                                    {i===0 ? ` ` : ` | `}
                                    <strong onClick={() => this.setFieldValue(field.key, o)}>
                                        {o}
                                    </strong>
                                </span>
                            )))
                            : ''
                        }
                        .</div>)
                    : null
                }
                {
                    field.description ? <FieldDescription description={field.description} />
                    : null
                }
                {
                    errorMessage(field.key)
                }
            </div>
        );
    }
}

export default LongTextInput;

LongTextInput.propTypes = {
    field: PropTypes.object.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    errorMessage: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
    
}

import React from 'react';
import { RequireNewPassword } from 'aws-amplify-react';
import { Form, Input } from 'antd';

class RequireNewPasswordForm extends RequireNewPassword {

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) this.change(e);
        });
    }

    renderError = (field) => {
        let errors = this.props.form.getFieldError(field);
        if(errors && errors.length > 0) {
            return (
                <div className="mt1 ph2 pv1 br1 bg-washed-red red f7 fw6">{errors[0]}</div>
            )
        } else return null;
    }

    showComponent() {
        const { hide, authData } = this.props;
        if (hide && hide.includes(RequireNewPasswordForm)) { return null; }

        const {
            getFieldDecorator
        } = this.props.form;

        return (
            <div>
                <div className="mw6 center pv3 pv4-ns">
                    <div className="center flex items-center justify-center" style={{width:'128px', height:'32px'}}>
                        <img className="" src="assets/img/hala-logo.svg" alt="" />
                    </div>
                </div>
                <div className="mw6 center ph2">
                    <div className="pa3 pa4-ns pa5-l bg-white br1 ba b--black-10">
                        <h1 className="f3 f2-ns mv0">Create new password.</h1>
                        <p className="mt2 mb0 lh-copy">Complete password reset for account associated with <strong>{authData.challengeParam.userAttributes.email}</strong> email.</p>
                        <Form onSubmit={this.handleSubmit} className="mt3">
                            <div className="">
                                <label htmlFor="password" className="b f6">New password:</label>
                                {getFieldDecorator('password', {
                                    rules: [{
                                        required: true,
                                        message: 'Please input your Password!'
                                    }, {
                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,40}$/,
                                        message: 'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!'
                                    }],
                                    validateTrigger: "onSubmit",
                                    onChange: this.handleInputChange
                                })(
                                    <Input autoComplete="current-password" name="password" type="password" className="w-100 mt1 pv2 ph2 f5" size="large" autoFocus/>
                                )}
                                { this.renderError('password') }
                            </div>
                            <div className="mt3">
                                <input type="submit" value="Confirm new password" className="w-100 btn btn-blue pv2 ph3 f5" />
                            </div>
                            <p className="mt3 mb0 lh-copy"><a href="/"
                                onClick={(e) => {e.preventDefault(); this.changeState('signIn')}}
                                                           className="link">Go back to Log in</a>
                            </p>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

const WrappedRequireNewPasswordForm = Form.create()(RequireNewPasswordForm);
export default WrappedRequireNewPasswordForm;

import { 
    LA_currentRequests,
    LA_requestUpdated,
    LA_newRequest,
    SIGNED_OUT,
    DISCONNECTED
} from '../../constants';

export default (state, { type }) => {
    switch(type) {
        case LA_currentRequests:
        case LA_requestUpdated:
        case LA_newRequest:
        case SIGNED_OUT:
        case DISCONNECTED:
            const ids = {};
            state.items.forEach((el,k) => {
                ids[el.id] = k;
            });
            return {
                ...state,
                ids: ids
            };
        default:
            return state;
    }
};
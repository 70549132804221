import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Hub, Logger } from '@aws-amplify/core';
import {
    ConfirmSignIn,
    TOTPSetup,
    withAuthenticator
} from 'aws-amplify-react';
import SignIn from '../../components/Auth/SignIn';
import ForgotPassword from '../../components/Auth/ForgotPassword';
import RequireNewPassword from '../../components/Auth/RequireNewPassword';
import VerifyContact from '../../components/Auth/VerifyContact';
import { authStatusChanged, authError } from '../../actions/auth';
import { SIGNED_OUT } from '../../constants';
import App from '../App';

const log = new Logger(':::Authenticator:::'); 

const _Authenticator = withAuthenticator(App, false, [
    <SignIn/>,
    <ConfirmSignIn/>,
    <VerifyContact/>,
    <RequireNewPassword/>,
    <ForgotPassword/>,
    <TOTPSetup/>
]);

class Authenticator extends _Authenticator {
    componentDidMount() {
        Hub.listen(SIGNED_OUT, () => {
            this.handleAuthStateChange(SIGNED_OUT);
        });
    }

    handleAuthStateChange(state, data) {
        super.handleAuthStateChange(state, data);
        this.props.authStatusChanged(state, data);
    }

    render() {
        log.debug('render');
        return super.render();
    }
}

Authenticator.propTypes = {
    authStatusChanged: PropTypes.func.isRequired,
    errorMessage: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    authStatusChanged: (state, data) => {
        dispatch(authStatusChanged(state, data));
        return null;
    },
    errorMessage: err => {
      dispatch(authError(err));
      return null;
    }
});

export default connect(
    null,
    mapDispatchToProps
)(Authenticator);
import React from 'react';
import PropTypes from 'prop-types';
import FieldDescription from './FieldDescription';

const SelectInputField = ({field, getFieldDecorator}) => (
    <div className="mt3" key={field.key}>
        <label htmlFor="select" className="b f6">{field.label}:</label>
        {field.required ?  null : (<span className="fr f6 o-60">Optional</span>)}
        {getFieldDecorator(field.key, {...field.validationOptions,
            initialValue: field.value,
            validateTrigger: "onChange",
            onChange: getFieldDecorator.handleInputChange
        })(<select id="select" name="" className="w-100 input-reset mt1 pv2 pl2 pr4 f5">
            <option value="" hidden></option>
            {
                field.options.map( (op, i) => {
                    return (<option key={i} value={op} >{op}</option>);
                })
            }
        </select>)
        }
        {
            field.description ? <FieldDescription description={field.description} />
            : null
        }
    </div>
);

export default SelectInputField;

SelectInputField.propTypes = {
    field: PropTypes.object.isRequired,
    getFieldDecorator: PropTypes.func.isRequired
}

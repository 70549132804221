import {
    PR_fetchCategoriesSuccess
} from '../../constants';

const _build = (items, parent) => {
    let root = null;
    let data = items.filter(el => {
        if (parent) return el.parent === parent;
        else if (!el.parent || el.parent === '0') {
            root = el;
            return true;
        }
        return false;
    });
    if (!root) {
        return data.map(el => ({
            label: el.name,
            value: el.id,
            path: el.path,
            children: _build(items, el.id)
        }));
    }
    return _build(items, root.id);
};

export const buildTree = state => {
    state.tree = _build(state.items);
    return state;
};

export default (state, { type }) => {
    switch(type) {
        case PR_fetchCategoriesSuccess:
            return buildTree(state);
        default:
            return state;
    }
};
import React, { 
    memo, useCallback, useMemo, 
    useRef, useLayoutEffect
} from 'react';
import Amplify from '@aws-amplify/core';
import ProductsTopBar from '../ProductsTopBar';
import ProductsList from '../ProductsList';
import { useMappedState } from 'redux-react-hook';
import ProductForm from '../ProductForm';
import OrdersTopBar from '../OrdersTopBar';
import OrdersList from '../OrdersList';
import Cart from '../Cart';
import {
    purchaseViewCart,
    purchaseViewOrders
} from '../../../constants';

const log = new Amplify.Logger(':::ContentWrapper:::');

export default memo(() => {
    log.debug('render');

    const view = useMappedState(useCallback(state => state.cartInfo.view, []));
    const wrap = useRef(null);

    useLayoutEffect(() => {
        const tId = setTimeout(() => {
            wrap.current.classList.add('show');
        }, 1);
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wrap.current.classList.remove('show');
            clearTimeout(tId);
        };
    }, [view]);

    const content = useMemo(() => {
        switch(view) {
            case purchaseViewOrders:
                return (
                    <React.Fragment>
                        <OrdersTopBar/>
                        <OrdersList/>
                    </React.Fragment>
                )
            case purchaseViewCart: 
                return (<Cart/>)
            default:
                return (
                    <React.Fragment>
                        <ProductsTopBar/>
                        <ProductsList/>
                    </React.Fragment>
                )
        }
    }, [view]);

    return (
        <div className='purchase-requisition'> 
            <div ref={wrap} className={`content-section ${view}`}>
                { content }
            </div>
            <ProductForm/>
        </div>
    );
});
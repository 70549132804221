import React, { memo, useMemo } from 'react';
import Amplify from '@aws-amplify/core';
import { useChangeQty, useEditItem, useDeleteItem } from '../../../actions/purchaseRequisition';
import ListItem from '../../../components/ProductsList/Item';
import { Icon, InputNumber, Tag } from 'antd';
import t from '../../../i18';

const log = new Amplify.Logger(':::PurchaseItem:::');

export default memo(({item, isSended}) => {
    log.debug('render');

    const onChangeQty = useChangeQty();
    const onEdit = isSended ? null :useEditItem(item);
    const onDelete = useDeleteItem(item);
    const tag = useMemo(() => {
        const type = item.isValid?"check":"warning";
        return (
            <Tag onClick={onEdit} className={type}>
                <Icon type={type} />
                { item.isValid ? t('cart.statusReady') : t('cart.statusMissedInfo') }
            </Tag>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const actions = useMemo(() => (
        <div className="actions">
            <span onClick={onDelete} className="link-st">{t('delete')}</span>
            <span> | </span>
            <span onClick={onEdit} className="link-st">{t('edit')}</span>
        </div>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ),[item]);

    return (
        <ListItem 
            item={item} 
            actions={[
                <InputNumber 
                    disabled={isSended}
                    min={1} 
                    decimalSeparator=","
                    parser={value => value.replace(/€\s?|(,*)/g, '')}
                    value={item.qty || 1} 
                    onChange={onChangeQty(item)}
                />
            ]}
            descriptionChildren={isSended?null:actions}
        >
            { tag }
        </ListItem>
    )
});